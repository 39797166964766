import React, { useState, useEffect } from 'react';
import '../../css/home.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import ImgStarSvg from '../../assets/svg/iconStar.svg';
import ImgDecatlonSvg from '../../assets/svg/iconLogoDecatlhon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLinkCard } from '../../features/onboardingData/onboardingUserData';
import CustomButton from '../../components/CustomButton';
import adsOfferData from '../../mock/adsOffer.json'
import adsSecondOfferData from '../../mock/adsSecondOffer.json'
import CustomAlert from '../../components/CustomAlert';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Home() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const isLinkCard = useSelector((state) => state.onboardingUserData.isLinkCard);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const [swiperIndex, setSwiperIndex] = useState(0);
    const [swiperIndexTwo, setSwiperIndexTwo] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstanceTwo, setSwiperInstanceTwo] = useState(null);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);


    const geiApiKey = async () => {
        try {
          const response = await fetch('auth/fetchApiKey', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ user: "petis-ts", pass: "BNvKiDaLNL1fTp1l" }),
          });
          const result = await response.json();
          console.log("result: ", result.token)
          sessionStorage.setItem('JWTteste', result.token)
        } catch (error) {
          console.log('Error fetching data:', error);
        }
    }

    const teste = async () => {
        const token = sessionStorage.getItem('JWTteste')
        console.log("token que foi pegado da storage no Serviço Teste: ",token)
        try {
            const response = await fetch('auth/teste', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ user: "tiago", pass: "tiago123" }),
            });
            const result = await response.json();
            console.log('RESULT /teste: ', result)      
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    }

    useEffect(() => {
        geiApiKey(); 
    }, []);

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 
        return () => clearTimeout(delay); 
    }, []);

    const hadleBtnClickAddNow = () => {
        navigate('/linkCardStepOne');
    };

    const handleClickShowConfigModal = () => {
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       setOpenInfoModal(true)
    };

    const hadleBtnClickKnowMore = () => {
        alert("Saber mais");
        teste();
    };

    const hadleBtnBoxEarnBalance = () => {
        navigate('/earnBalance');
    };

    const hadleBtnBoxSpendBalance = () => {
        alert("Gastar saldo");
        //navigate('/partnersDetails');
    };

    const hadleBtnBoxViewMovements = () => {
        navigate('/seeMovements');
    };

    const hadleBtnClickKnowMorePartners = () => {
        navigate('/partnersPage');
    };

    const handleSwiperSlideChange = (swiper) => {
        setSwiperIndex(swiper.activeIndex);
    };

    const handleSwiperSlideChangeTwo = (swiperTwo) => {
        setSwiperIndexTwo(swiperTwo.activeIndex);
    };
    
    const nextImage = () => {
        if (swiperInstance !== null) {
        swiperInstance.slideNext();
        }
    };

    const prevImage = () => {
        if (swiperInstance !== null) {
        swiperInstance.slidePrev();
        }
    };

    const nextImageTwo = () => {
        if (swiperInstanceTwo !== null) {
            swiperInstanceTwo.slideNext();
        }
    };

    const prevImageTwo = () => {
        if (swiperInstanceTwo !== null) {
            swiperInstanceTwo.slidePrev();
        }
    };
    
  return (  
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? "mainViewContainerHomeSteps" : ( isMobile ? 'mainViewContainerHomeStepsTablet' : 'mainViewContainerHomeStepsMobile') }>
        <div className={ isTablet ? 'mainFirstViewContainerHomeSteps' : ( isMobile ? 'mainFirstViewContainerHomeStepsTablet' : 'mainFirstViewContainerHomeStepsMobile') }>
            { !isLinkCard &&
                <div className={ isTablet ? 'doYouHaveLinkingCard' : ( isMobile ? 'doYouHaveLinkingCardTablet' : 'doYouHaveLinkingCardMobile') }>
                    <div className={ isTablet ? 'txtDoYouHaveLinkingCardContainer' : (isMobile ? 'txtDoYouHaveLinkingCardContainer' : 'txtDoYouHaveLinkingCardContainerMobile')}>
                        <div className={ isTablet ? 'txtBoldDoYouHaveLinkingCard' : 'txtBoldDoYouHaveLinkingCardMobile' }>Associe um cartão multibanco</div>
                        <div className='txtRegularDoYouHaveLinkingCard'>Ainda não associou nenhum cartão à sua carteira.</div>
                    </div>
                    <div className={ isTablet ? 'divBtnAddCardHomeSteps' : ( isMobile ? 'divBtnAddCardHomeStepsTablet' : 'divBtnAddCardHomeStepsMobile') }>
                        <CustomButton 
                            text={'Saiba como'} 
                            color={'#fff'}
                            textColor={'#141414'}
                            iconColor={'#141414'}
                            onPress={hadleBtnClickAddNow} 
                            hasArrow={true}
                        />
                    </div>
                </div>
            }
            <div className={ isTablet ? 'titleSaldoContainerHomeSteps' : ( isMobile ? 'titleSaldoContainerHomeStepsTablet' : 'titleSaldoContainerHomeStepsMobile')}>
                <div className={ isTablet ? 'titleContainerHomeSteps' : ( isMobile ? 'titleContainerHomeStepsTablet' : 'titleContainerHomeStepsMobile')}>
                    <div className={ isTablet ? 'firstTitleViewHomeSteps' : ( isMobile ? 'firstTitleViewHomeStepsTablet' : 'firstTitleViewHomeStepsMobile')}>
                        { isTablet ?(
                            <div className='textTitleBoldHomeSteps'>Carteira Virtual Médis</div> ) : (
                            <>
                            <div className='textTitleRegularHomeStepsMobile'>Carteira</div>
                            <div className='textTitleBoldHomeStepsMobile'>Virtual Médis</div>
                        </>
                        )}      
                    </div>
                    <div className='firstTitleViewConfigOnfoHomeSteps'>
                        <button className='infoHeaderIonBtnHomeSteps'  type='button' onClick={handleClickShowConfigModal} >
                            <span className='configIconHomeSteps'><IoSettingsOutline  /></span> <span className='txtConfigTitleHomeSteps'>Configurações</span>
                        </button>
                        <CustomAlert 
                            title='Configurações'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openConfigModal} 
                            onHide={() => setOpenConfigModal(false)} 
                        /> 

                        <button className='infoHeaderIonBtnHomeSteps'  type='button' onClick={handleClickShowInfo} >
                            <span className='infoIconHomeSteps'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitleHomeSteps'>Como funciona</span>
                        </button>
                        <CustomAlert 
                            title='Como Funciona'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openInfoModal} 
                            onHide={() => setOpenInfoModal(false)} 
                        />
                    </div>
                </div>
                <div className={ isTablet ? 'saldoContainerHomeSteps' : ( isMobile ? 'saldoContainerHomeStepsTablet' : 'saldoContainerHomeStepsMobile')}>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                        <>
                        <div>
                            <span className={ isTablet ? 'textSecondTitleViewHomeSteps' : 'thirdTextOneHomeSteps'}>O meu </span>
                            <span className={ isTablet ? 'txtBoldSaldoHomeSteps' : 'txtBoldSaldoHomeStepsMobile'}>Saldo</span>
                        </div>
                        <div className='saldoInfoContainerHomeSteps'>
                            <div className="saldoEuroIconViewHomeSteps">
                                <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconHomeSteps" />
                            </div>
                            <div className='txtRegularSaldoHomeSteps'>22,3€</div>
                        </div>
                        </>
                    )}
                </div>
            </div>
            <div className={ isTablet ? 'infoNavContainerHomeSteps' : ( isMobile ? 'infoNavContainerHomeStepsTablet' : 'infoNavContainerHomeStepsMobile')}>
                <div className={ isTablet ? 'txtInfoNavStepsContainerHomeSteps' : ( isMobile ? 'txtInfoNavStepsContainerHomeStepsTablet' : 'txtInfoNavStepsContainerHomeStepsMobile')}>
                    <div className='firstTxtInfoNavViewHomeSteps'>
                        <div className='textTitleRegularInfoNavHomeSteps'>Faça compras e acumule dinheiro.<br />Receba de volta uma percentagem do valor que gastou.</div>      
                    </div>
                    <div className={ isTablet ? 'btnKnowMoreViewHomeSteps' : ( isMobile ? 'btnKnowMoreViewHomeSteps' : 'btnKnowMoreViewHomeStepsMobile')}>
                        <CustomButton 
                            text={'Saber mais'} 
                            textColor={'#141414'}
                            borderColor={'#141414'}
                            onPress={hadleBtnClickKnowMore} 
                        />
                    </div>
                </div>
                <div className='navBoxMainViewContainerHomeStepsMobile'>
                    <div className={ isTablet ? 'navBoxMainViewContainerHomeSteps' : 'navBoxMainViewContainerHomeStepsMobile' }>
                        <button className={ isTablet ? 'navBoxViewContainerHomeSteps' : 'navBoxViewContainerHomeStepsMobile' } onClick={hadleBtnBoxEarnBalance}>
                            <img src={ImgStarSvg} alt="imagem" className="imagemNavBoxSvgHomeSteps" />
                            <div className='txtNavBoxTitleViewContainerHomeSteps'>
                                <div className='txtNavBoxTitleHomeSteps'>Ganhar saldo</div>
                            </div>
                            <div className='txtNavBoxViewContainerHomeSteps'>
                                <div className='txtNavBoxRegularHomeSteps'>Faça compras <br/>e acumule dinheiro</div>
                            </div>
                            <div className='arrowIconNavBoxViewContainerHomeSteps'>
                                <FaArrowRightLong className='arrowIconNavBoxHomeSteps' /> 
                            </div>
                        </button>

                        <button className={ isTablet ? 'navBoxViewContainerHomeSteps' : 'navBoxViewContainerHomeStepsMobile' } onClick={hadleBtnBoxSpendBalance}>
                            <img src={ImgStarSvg} alt="imagem" className="imagemNavBoxSvgHomeSteps" />
                            <div className='txtNavBoxTitleViewContainerHomeSteps'>
                                <div className='txtNavBoxTitleHomeSteps'>Gastar saldo</div>
                            </div>
                            <div className='txtNavBoxViewContainerHomeSteps'>
                                <div className='txtNavBoxRegularHomeSteps'>Receba o valor ou use <br/>em serviços de saúde</div>
                            </div>
                            <div className='arrowIconNavBoxViewContainerHomeSteps'>
                                <FaArrowRightLong className='arrowIconNavBoxHomeSteps' /> 
                            </div>
                        </button>
                    </div>
                    <div className={ isTablet ? 'navBoxMainViewContainerHomeSteps' : 'navBoxMainViewContainerHomeStepsMobile' }>
                        { isTablet &&
                            <div className={ isTablet ? 'navBoxPCViewContainerHomeSteps' : 'navBoxPCViewContainerHomeStepsMobile'}></div>
                        }
                        <button className={ isTablet ? 'navBoxViewContainerHomeSteps' : 'navBoxViewContainerHomeStepsMobile' } onClick={hadleBtnBoxViewMovements}>
                            <img src={ImgStarSvg} alt="imagem" className="imagemNavBoxSvgHomeSteps" />
                            <div className='txtNavBoxTitleViewContainerHomeSteps'>
                                <div className='txtNavBoxTitleHomeSteps'>Ver movimentos</div>
                            </div>
                            <div className='txtNavBoxViewContainerHomeSteps'>
                                <div className='txtNavBoxRegularHomeSteps'>Acompanhe onde <br/>e quanto acumula</div>
                            </div>
                            <div className='arrowIconNavBoxViewContainerHomeSteps'>
                                <FaArrowRightLong className='arrowIconNavBoxHomeSteps' /> 
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div className={ isTablet ? 'mainSecondViewContainerHomeSteps' : ( isMobile ? 'mainSecondViewContainerHomeStepsTablet' : 'mainSecondViewContainerHomeStepsMobile') }>
            <div className='firstSubMainContainerHomeSteps'>
                <div className='secondTitleViewHomeSteps'>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={75}/> : (
                    <>
                        <span className={ isTablet ? 'textTitleRegular400HomeSteps' : 'textTitleRegular400HomeStepsMobile'}> <b className={ isTablet ? 'textTitleBold700HomeSteps' : 'textTitleBold700HomeStepsMobile'}>Novo parceiro</b> Carteira Virtual</span>      
                    </>
                    )}
                </div>
                <div className='txtSecondTitleViewHomeSteps'>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={75}/> : (
                    <>
                        <span className={ isTablet ? 'textSecondTitleViewHomeSteps' : 'textSecondTitleViewHomeStepsMobile' }>Acompanhe as novidades e descubra os parceiros que estão mais perto de si.</span>
                    </>
                    )}
                </div>
            </div>

            <Swiper
                onSlideChange={(swiper) => handleSwiperSlideChange(swiper)}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // pagination={{
                // clickable: true,
                // }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className={ isTablet ? "mySwiper" : "mySwiperMobile"}
            >
            {adsOfferData.offerAds.map((ad, index) => (
            <SwiperSlide  key={index} >
                { isTablet ? (
                <div className='secondSubMainContainerHomeSteps'>
                { isLoading ? <PlaceholderLoading shape="rect" width={350} height={175}/> : (
                    <>
                    <div className='imgAdsMainViewHomeSteps' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                        {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsHomeSteps' /> */}
                        <div className='adsProductStatusViewHomeSteps'>
                            <div className='txtAdsProductStatusHomeSteps'>{ad.productStatus}</div>
                        </div>
                    </div>
                    <div className='txtAdsMainViewHomeSteps'>
                        <div className='txtAdsMainTitleViewHomeSteps'>
                            <div className='txtAdsDateHomeSteps'>{ad.dateOfOffer}</div>
                        </div>
                        <div className='txtAdsMainTitleViewHomeSteps'>
                            <div className='txtAdsBoldHomeSteps'>{ad.title}</div>
                        </div>
                        <div className='txtAdsMainSubTitleViewHomeSteps'>
                            <div className='txtAdsRegularHomeSteps'>{ad.description}</div>
                        </div>
                        <div className='imgAdsCompanyLogoMainViewHomeSteps'>
                            <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoHomeSteps' />
                            <div className='txtAdsCompanyLogoViewHomeSteps'>
                                <div className='txtAdsCompanyTitleHomeSteps'>{ad.storeName}</div>
                                {/* <div className='txtAdsProductCategoryTitleHomeSteps'>{ad.productCategory}</div> */}
                            </div>
                        </div>
                    </div>
                    </>
                )}
                </div>
                ) : ( isMobile ? (
                    <div className='secondSubMainContainerHomeStepsTablet'>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={175}/> : (
                        <>
                        <div className='imgAdsMainViewHomeStepsTablet'>
                            <div className='imgAdsMainWithImgViewHomeStepsTablet' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsHomeSteps' /> */}
                                <div className='adsProductStatusViewHomeStepsTablet'>
                                    <div className='txtAdsProductStatusHomeSteps'>{ad.productStatus}</div>
                                </div>
                            </div>
            
                            <div className='txtAdsMainViewHomeStepsTablet'>
                                <div className='txtAdsMainTitleViewHomeSteps'>
                                    <div className='txtAdsDateHomeStepsTablet'>{ad.dateOfOffer}</div>
                                </div>
                                <div className='txtAdsMainTitleViewHomeSteps'>
                                    <div className='txtAdsBoldHomeStepsTablet'>{ad.title}</div>
                                </div>
                                <div className='txtAdsMainSubTitleViewHomeSteps'>
                                    <div className='txtAdsRegularHomeStepsTablet'>{ad.description}</div>
                                </div>
                                <div className='imgAdsCompanyLogoMainViewHomeStepsTablet'>
                                    <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoHomeSteps' />
                                    <div className='txtAdsCompanyLogoViewHomeSteps'>
                                        <div className='txtAdsCompanyTitleHomeSteps'>{ad.storeName}</div>
                                        {/* <div className='txtAdsProductCategoryTitleHomeSteps'>{ad.productCategory}</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )}
                    </div>
                ) : (
                    <div className='secondSubMainContainerHomeStepsMobile'>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={175}/> : (
                        <>
                        <div className='imgAdsMainViewHomeStepsMobile' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsHomeSteps' /> */}
                            <div className='adsProductStatusViewHomeStepsMobile'>
                                <div className='txtAdsProductStatusHomeSteps'>{ad.productStatus}</div>
                            </div>
            
                            <div className='txtAdsMainViewHomeStepsMobile'>
                                <div className='txtAdsMainTitleViewHomeSteps'>
                                    <div className='txtAdsDateHomeStepsMobile'>{ad.dateOfOffer}</div>
                                </div>
                                <div className='txtAdsMainTitleViewHomeSteps'>
                                    <div className='txtAdsBoldHomeStepsMobile'>{ad.title}</div>
                                </div>
                                <div className='txtAdsMainSubTitleViewHomeSteps'>
                                    <div className='txtAdsRegularHomeStepsMobile'>{ad.description}</div>
                                </div>
                                <div className='imgAdsCompanyLogoMainViewHomeStepsMobile'>
                                    <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoHomeSteps' />
                                    <div className='txtAdsCompanyLogoViewHomeSteps'>
                                        <div className='txtAdsCompanyTitleHomeSteps'>{ad.storeName}</div>
                                        {/* <div className='txtAdsProductCategoryTitleHomeSteps'>{ad.productCategory}</div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        </>
                    )}
                    </div>
                ))}
            </SwiperSlide>
            ))} 
            </Swiper>
            
            <div className={ isTablet ? 'thirdSubMainContainerCircleNavHomeSteps' : 'thirdSubMainContainerHomeStepsMobile' }>
                { isLoading ? <PlaceholderLoading shape="rect" width={350} height={24}/> : (
                    <>
                    <button className='iconArrowLeftViewContainerCircleNavHomeSteps' onClick={prevImage} disabled={ swiperIndex == 0}>
                        <FaArrowLeftLong className='iconArrowCircleNavHomeSteps' /> 
                    </button>

                    <div className='cirlceSelectedNavHomeSteps'>
                        <div className='circleMainvViewNavHomeSteps'>
                            <div className='circleContainerNavHomeSteps'>
                                <div className={ swiperIndex == 0 ? 'circleSelectedViewNavHomeSteps' : 'circleViewNavHomeSteps'} />
                            </div>
                            <div className='circleContainerNavHomeSteps'>
                                <div className={ swiperIndex == 1 ? 'circleSelectedViewNavHomeSteps' : 'circleViewNavHomeSteps'} />
                            </div>
                            <div className='circleContainerNavHomeSteps'>
                                <div className={ swiperIndex >= 2 ? 'circleSelectedViewNavHomeSteps' : 'circleViewNavHomeSteps'} />
                            </div>
                        </div>
                    </div>

                    <button className='iconArrowRightViewContainerCircleNavHomeSteps' onClick={nextImage} disabled={ swiperIndex >= adsOfferData.offerAds.length - 1 }>
                        <FaArrowRightLong className='iconArrowCircleNavHomeSteps' /> 
                    </button>
                    </>
                )}
            </div>

        </div>

        <div className={ isTablet ? 'mainThirdViewContainerHomeSteps' : ( isMobile ? 'mainThirdViewContainerHomeStepsTablet' : 'mainSecondViewContainerHomeStepsMobile') }>
                    <div className='thirdTitleViewContainerHomeSteps'>
                        <div className='thirdTitleViewHomeSteps'>
                            { isLoading ? <PlaceholderLoading shape="rect" width={350} height={75}/> : (
                            <>
                                <div className={ isTablet ? 'thirdTextTitleRegular400HomeSteps' : ( isMobile ? 'thirdTextTitleRegular400HomeStepsTablet' : 'thirdTextTitleRegular400HomeStepsMobile') }>Parceiros que ajudam nas</div>
                                <div className={ isTablet ? 'thirdTextTitleBold700HomeSteps' : ( isMobile ? 'thirdTextTitleBold700HomeStepsTablet' : 'thirdTextTitleBold700HomeSteps') }> <b>resoluções de Ano Novo</b></div> 
                            </>
                            )}
                        </div>
                        <div className='txtThirdTitleViewHomeSteps'>
                            { isLoading ? <PlaceholderLoading shape="rect" width={350} height={75}/> : (
                            <>
                                <div className='thirdTextOneHomeSteps'>Quer praticar mais desporto em 2024? Alimentar-se melhor?</div>
                            </>
                            )}
                        </div>
                        <div className='txtFourTitleViewHomeSteps'>
                            { isLoading ? <PlaceholderLoading shape="rect" width={350} height={75}/> : (
                            <>
                                <div className='thirdTextTwoHomeSteps'>Conheça os nossos parceiros de Desporto e Nutrição.</div>
                            </>
                            )}
                        </div>
                        <div className={ isTablet ? 'divBtnKnowMorePartnersHomeSteps' : ( isMobile ? 'divBtnKnowMorePartnersHomeSteps' : 'divBtnKnowMorePartnersHomeStepsMobile')}>
                            { isLoading ? <PlaceholderLoading shape="rect" width={150} height={20}/> : (
                                <>
                                <CustomButton 
                                    text={'Descobrir parceiros'} 
                                    textColor={'#141414'}
                                    borderColor={'#141414'}
                                    onPress={hadleBtnClickKnowMorePartners} 
                                />
                                </>
                            )}
                        </div>
                    </div>
                    
                    <div className={ isTablet ? 'secondSliderAdsContainerHomeSteps' : ( isMobile ? 'secondSliderAdsContainerHomeStepsTablet' : 'secondSliderAdsContainerHomeStepsMobile') }>
                        <Swiper
                            onSlideChange={(swiperTwo) => handleSwiperSlideChangeTwo(swiperTwo)}
                            onSwiper={(swiperTwo) => setSwiperInstanceTwo(swiperTwo)}
                            slidesPerView={ isTablet ? 2 : 1 }
                            spaceBetween={32}
                            // pagination={{
                            //     clickable: true,
                            //   }}
                            modules={[Pagination]}
                            className={ isTablet ? "mySwiperTwo" : ( isMobile ? "mySwiperTwoTablet" : "mySwiperTwoMobile")}
                        >
                        {adsSecondOfferData.secondOfferAds.map((ad, index) => (
                        <SwiperSlide  key={index} >
                            { isLoading ? <PlaceholderLoading shape="rect" width={316} height={217}/> : (
                                <>
                                <div className={ isTablet ? 'secondSliderAdsOferta' : ( isMobile ? 'secondSliderAdsOfertaTablet' : 'secondSliderAdsOfertaMobile') } style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                                
                                    {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsHomeStepsTwo' /> */}
                                    <div className='imgTitleAdsOfferTwoHomeStepsTwo'>{ad.title}</div>  
                                                    
                                </div>
                            </>
                            )}      
                        </SwiperSlide>
                        ))} 
                        </Swiper>

                        <div className={ isTablet ? 'thirdSubMainContainerCircleNavHomeSteps' : ( isMobile ? 'thirdSubMainContainerCircleNavHomeStepsTablet' : 'thirdSubMainContainerHomeStepsMobile') }>
                            { isLoading ? <PlaceholderLoading shape="rect" width={350} height={24}/> : (
                                <>
                                <button className='iconArrowLeftViewContainerCircleNavHomeSteps' onClick={prevImageTwo} disabled={ swiperIndexTwo == 0}>
                                    <FaArrowLeftLong className='iconArrowCircleNavHomeSteps' /> 
                                </button>

                                <div className='cirlceSelectedNavHomeSteps'>
                                    <div className='circleMainvViewNavHomeSteps'>
                                        <div className='circleContainerNavHomeSteps'>
                                            <div className={ swiperIndexTwo == 0 ? 'circleSelectedViewNavHomeSteps' : 'circleViewNavHomeSteps'} />
                                        </div>
                                        <div className='circleContainerNavHomeSteps'>
                                            <div className={ swiperIndexTwo == 1 ? 'circleSelectedViewNavHomeSteps' : 'circleViewNavHomeSteps'} />
                                        </div>
                                        <div className='circleContainerNavHomeSteps'>
                                            <div className={ swiperIndexTwo >= 2 ? 'circleSelectedViewNavHomeSteps' : 'circleViewNavHomeSteps'} />
                                        </div>
                                    </div>
                                </div>

                                <button className='iconArrowRightViewContainerCircleNavHomeSteps' onClick={nextImageTwo} disabled={ swiperIndexTwo >= adsSecondOfferData.secondOfferAds.length - 2 }>
                                    <FaArrowRightLong className='iconArrowCircleNavHomeSteps' /> 
                                </button>
                                </>
                            )}
                        </div>

                    </div>

            </div>

    </div>
    </>
  )
}

export default Home;