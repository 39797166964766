
const initialSelectedItems = (portugalLocations) => {
  return portugalLocations.map((location, index) => {
    return {
      id: index + 1,
      name: location.name,
      checked: false
    };
  });
};


export const filterReducer = (state = initialSelectedItems, action) => {
  switch (action.type) {
      case "TOGGLE_SELECT":
          const selectedItemIndex = state.findIndex(item => item.code === action.id);
          if (selectedItemIndex !== -1) {
              const updatedSelectedItems = [
                  ...state.slice(0, selectedItemIndex),
                  { ...state[selectedItemIndex], checked: action.checked },
                  ...state.slice(selectedItemIndex + 1)
              ];
              return updatedSelectedItems;
          } else {
              return [
                  ...state,
                  { code: action.id, name: action.name, checked: action.checked }
              ];
          }
      case "SET_SELECTED_ITEMS":
          return action.selectedItems;
      default:
          return state;
  }
};
