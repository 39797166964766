import React, { useState, useEffect } from 'react';
import '../../css/onboardingReferalSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import imgDataRespData from '../../mock/imgDataResp.json'
import { getDatabase, ref, get, set } from "firebase/database";
import { toast } from "react-toastify";
import { AiOutlineStop } from "react-icons/ai";
import CustomButton from '../../components/CustomButton';

function OnboardingReferalStepOne() {

    const [isLoading, setIsLoading] = useState(true);
    const [referralCode, setReferralCode] = useState('');
    const [isStartingLoadingDots, setIsStartingLoadingDots] = useState(false)
    const navigate = useNavigate();
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const imgStepOne = imgDataRespData.imgData.onboardingReferalStepOneSvg;
    const [isValidReferral, setIsValidReferral] = useState(true); 

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const fetchData = async () => {
        try {
            setIsStartingLoadingDots(true);
            const database = getDatabase();
            //await set(ref(database, '/confirmOnboardingCode/success'), true);
            const dbRef = ref(database, '/confirmOnboardingCode');
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (data.referralCode == referralCode) {
                    setIsValidReferral(true);
                    toast.success("Código adicionado! O valor fica disponível depois de associar um cartão à Carteira Virtual.");
                    hadleNav();
                    setIsStartingLoadingDots(false);
                } else {
                    setIsValidReferral(false);
                    setIsStartingLoadingDots(false);
                }
            } else {
                console.log('No data available');
                setIsStartingLoadingDots(false);
            }
        } catch (error) {
            console.error(error);
            setIsStartingLoadingDots(false);
        }
    };

    const hadleBtnClickSaltar = () => {
        navigate('/onboardingReferalStepTwo');
    };

    const hadleBtnClickCheck = async () => {
        await fetchData();
    };

    const hadleNav = () => {
        navigate("/onboardingReferalStepTwo", { state: {referralCode} });
    };

    const inputClassName = isTablet ? 
    (isValidReferral ? 'inputTextReferalSteps' : 'inputTextReferalStepsInvalid') : 
    (isValidReferral ? 'inputTextReferalStepsMobile' : 'inputTextReferalStepsMobileInvalid');

  return (  
    <>
    { isTablet &&  
    <Header/> 
    }
    <div className={ isTablet ? 'mainViewContainerReferalSteps' : ( isMobile ? 'mainViewContainerReferalStepsTablet' : 'mainViewContainerReferalStepsMobile')}>
        { !isTablet && 
            <>
            <div className={ isMobile ? 'mainImgViewStepsStackTablet' : 'mainImgViewReferalStepsMobile' }>
                <img src={imgStepOne} alt="imagem" className={ isMobile ?  "mainImgReferalStackTablet" : "mainImgReferalStackMobile" }/>
            </div>
            </>
        }
        <div className={  isTablet ? 'firstTxtViewContainerReferalSteps' : ( isMobile ? 'firstTxtViewContainerReferalStepsTablet' : 'firstTxtViewContainerReferalStepsMobile') }>
            <div className='firstTitleViewReferalSteps'>
                <span className={  isTablet ? 'textTitlePart1ReferalSteps' : ( isMobile ? 'textTitlePart1ReferalStepsTablet' : 'textTitlePart1ReferalStepsMobile') }>Tem um  </span>
                <span className={  isTablet ? 'textTitlePart2ReferalSteps' : ( isMobile ? 'textTitlePart2ReferalStepsTablet' : 'textTitlePart2ReferalStepsMobile') }>Código?</span>      
            </div>
            <div className={ isTablet ? 'secondTitleViewReferalSteps' : ( isMobile ? 'secondTitleViewReferalStepsTablet' : 'secondTitleViewReferalStepsMobile') }>Insira o código que recebeu e ganhe XX€ em saldo da Carteira Virtual.</div>
            <div className='textFormViewReferalSteps'>
                <div className='textWrapperReferalSteps'>
                    { referralCode.length > 0 &&
                    <label className='label-focus'>Insira aqui o seu código</label>
                    }
                    <input 
                        className={inputClassName}
                        type="text" 
                        placeholder='EX.: MEDIS24'
                        value={referralCode}
                        onChange={(event) => setReferralCode(event.target.value)}
                    />
                </div>
                { !isValidReferral &&
                    <div className='txtViewErrorInfoReferalSteps'>
                        <div className='txtErrorInfoReferalSteps'><AiOutlineStop className='iconErrorInfoReferalSteps'/> Lamentamos, mas neste momento, não conseguimos validar o seu código. Por favor, tente mais tarde.</div>
                    </div>
                } 
            </div>
            <div className={ isTablet ? 'btnsViewReferalSteps' : ( isMobile ? 'btnsViewReferalStepsTablet' : 'btnsViewReferalStepsMobile' )}>
                <div className={ isTablet ? 'divBtnSaltarReferalSteps' : ( isMobile ? 'divBtnSaltarReferalStepsStackTablet' : 'divBtnSaltarReferalStepsMobile' )}>
                    <CustomButton 
                        text={'Saltar'} 
                        textColor={'#00214D'}
                        onPress={hadleBtnClickSaltar} 
                        borderColor={'#00214D'}
                        hasSubLine
                        fontSize={'14px'}
                    />
                </div> 
                <div className={ isTablet ? 'divBtnNextReferalSteps' : ( isMobile ? 'divBtnSaltarReferalStepsStackTablet' : 'divBtnNextReferalStepsMobile' )}>
                    <CustomButton 
                        text={'Validar'} 
                        textColor={'#00214D'}
                        onPress={hadleBtnClickCheck} 
                        color={'#15D4B6'}
                        enabled={referralCode.length > 0}
                        hasArrow
                        iconColor={'#00214D'}
                        iconType={'check'}
                        loading={isStartingLoadingDots}
                    />
                </div>    
            </div>           
        </div>
        { isTablet && 
        <>
        <div className='firstImgViewContainerReferalSteps'>
            <div className='mainImgViewReferalSteps'>
                <img src={imgStepOne} alt="imagem" className="mainImgReferalSteps" />
            </div>
        </div>
        </>
        }
    </div>
    </>
  )
}

export default OnboardingReferalStepOne;