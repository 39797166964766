import React, { useState, useEffect } from 'react';
import '../../css/onboardingFirstSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import CustomButton from '../../components/CustomButton';

function OnboardingStepOne({ onNext, onSkip, mainImgSvg }) {

    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

  return (
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? 'mainViewContainerStepsStack' : ( isMobile ? 'mainViewContainerStepsStackTablet' : 'mainViewContainerStepsStackMobile')}>
        { !isTablet && 
            <>
            <div className={ isMobile ? 'mainImgViewStepsStackTablet' : 'mainImgViewStepsStackMobile' }>
                <img src={mainImgSvg} alt="imagem" className={ isMobile ?  "mainImgOneStepsStackTablet" : "mainImgOneStepsStackMobile" }/>
            </div>
            </>
        }
        <div className={ isTablet ? 'firstTxtViewContainerStepsStack' : ( isMobile ? 'firstTxtViewContainerStepsStackTablet' : 'firstTxtViewContainerStepsStackMobile')}>
            { isTablet ?
                <div className='firstTitleViewStepsStack'>
                    <div className='textTitlePart1StepsStack'>Bem-vindo à sua </div>
                    <div className='textTitlePart2StepsStack'>Carteira Médis.</div>
                </div>
            :
                <div className='firstTitleViewStepsStack'>
                    { isMobile ?
                        <div className='textTitlePart1StepsStackTablet'>Bem-vindo à sua Carteira Médis.</div>
                    :
                    <>
                        <div className='textTitlePart1StepsStackMobile'>Bem-vindo à sua </div>
                        <div className='textTitlePart2StepsStackMobile'>Carteira Médis.</div>
                    </>
                    }
                </div>
            }
            <div className={ isTablet ? 'secondTitleViewStepsStack' : ( isMobile ? 'secondTitleViewStepsStackTablet' : 'secondTitleViewStepsStackMobile') }>Faça compras e acumule dinheiro.</div>
            { isTablet ?
                <>
                    <div className='secondSmallTitleViewStepsStack'>Sempre que fizer compras na nossa rede de parceiros estará a</div>
                    <div className='secondSmallTitleViewStepsStack'>acumular uma percentagem do valor na Carteira Médis</div>
                </>
            :
                <div className='firstTitleViewStepsStack'>
                    { isMobile ?
                        <div className='secondSmallTitleViewStepsStackTablet'>Sempre que fizer compras na nossa rede de parceiros estará a acumular uma percentagem do valor na Carteira Médis</div>
                    :
                    <>
                        <div className='secondSmallTitleViewStepsStackMobile'>Sempre que fizer compras na nossa rede de parceiros estará a acumular uma percentagem do valor na Carteira Médis</div>
                    </>
                    }
                </div>
            }
            <div className={ isTablet ? 'btnsViewStepsStack' : ( isMobile ? 'btnsViewStepsStackTablet' : 'btnsViewStepsStackMobile' )}>
                <div className={ isTablet ? 'divBtnSaltarStepsStack' : ( isMobile ? 'divBtnSaltarStepsStackTablet' : 'divBtnSaltarStepsStackMobile' )}>
                    <CustomButton 
                        text={'Saltar'} 
                        textColor={'#00214D'}
                        onPress={() => onSkip()} 
                        borderColor={'#00214D'}
                        hasSubLine
                        fontSize={'14px'}
                    />
                </div> 
                <div className={ isTablet ? 'divBtnNextStepsStack' : ( isMobile ? 'divBtnSaltarStepsStackTablet' : 'divBtnNextStepsStackMobile' )}>
                    <CustomButton 
                        text={'Próximo'} 
                        textColor={'#00214D'}
                        onPress={() => onNext()} 
                        color={ isTablet ? '#fff' : ( isMobile ? '#fff' : 'transparent' )}
                        borderColor={'#00214D'}
                        hasArrow
                        iconColor={'#00214D'}
                    />
                </div>    
            </div>
            {
                !isTablet && (
                <>
                <div className='cirlceSelectedViewStepsStackMobile'>
                    <div className='cirlceSelectedStepsStackMobile'>
                        <div className='circleMainvViewStepsStack'>
                            <div className='circleContainerStepsStack'>
                                <div className='circleSelectedViewStepsStack' />
                            </div>
                            <div className='circleContainerStepsStack'>
                                <div className='circleViewStepsStack' />
                            </div>
                            <div className='circleContainerStepsStack'>
                                <div className='circleViewStepsStack' />
                            </div>
                            <div className='circleContainerStepsStack'>
                                <div className='circleViewStepsStack' />
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}         
        </div>
        { isTablet && 
        <>
        <div className='firstImgViewContainerStepsStack'>
            <div className='mainImgViewStepsStack'>
                <img src={mainImgSvg} alt="imagem" className="mainImgStepsStack" />
            </div>
            <div className='cirlceSelectedStepsStack'>
                <div className='circleMainvViewStepsStack'>
                    <div className='circleContainerStepsStack'>
                        <div className='circleSelectedViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                </div>
            </div>
        </div>
        </>
        }
    </div>
    </>
  )
}

export default OnboardingStepOne;