import React from 'react';
import { useMediaQuery } from 'react-responsive';

export function isValidPhoneNumber(text) {
    return /^9[1236][0-9]{7}$/.test(text);
}

export function useMobileMediaQuery() {
    return useMediaQuery({ query: '(min-width: 767px)' });
}

export function useTabletMediaQuery() {
    return useMediaQuery({ query: '(min-width: 1250px)' });
}
