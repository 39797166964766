import React, { useState, useEffect } from 'react';
import { IoMdInformationCircleOutline } from "react-icons/io";
import MedisLogoSvg from "../assets/svg/medisLogoSvg.svg"
import { IoSearch } from "react-icons/io5";
import IconUserAccSvg from '../assets/svg/iconUserAccSvg.svg'
import '../css/header.css';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import CustomAlert from './CustomAlert';
import { useMsal } from '@azure/msal-react';

function Header() {

    const handleShowInfo = () => {
        setModalShow(true)
        //alert("Tem dúvidas? Contacte-nos pelo xxxx@medis.pt ou pela Linha Médis: 218 458 888 (dias úteis das 8h00 às 20h00)");
    };

    const [modalShow, setModalShow] = React.useState(false);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const { instance } = useMsal();
    const handleLogout = async () => {
        try {
          const response = await instance.logout();
          console.log(response); 
        } 
        catch (error) {
          console.log(error);
        }
    };

    return (
        <div className='mainHeaderContainer'>
            <div className='firstHeaderContainer'>
                    <div className='txtHeaderLineMedis'>Linha Médis</div>
                    <div className='txtHeaderPhoneNum'>218 458 888</div>
                    <button className='infoHeaderIonBtn'  type='button' onClick={handleShowInfo} >
                        <IoMdInformationCircleOutline className='infoHeaderIcon' />
                    </button>
                    <CustomAlert 
                        title='Tem dúvidas?'
                        text='Contacte-nos pelo xxxx@medis.pt ou pela Linha Médis: 218 458 888 (dias úteis das 8h00 às 20h00)'
                        type='info'
                        show={modalShow} 
                        onHide={() => setModalShow(false)} 
                    /> 
            </div>
            <div className='headerDeviderLine'></div>
            <div className='secondHeaderContainer' >  
                <div className='headeLogoNavSubContainer' >
                    <a href="https://www.medis.pt/" target="_blank" rel="noopener noreferrer">
                        <img className='medisLogoSvgIcon' src={MedisLogoSvg} alt="imagem" />
                    </a>
                    <div className='headerNavTxtView' >
                        <NavDropdown title="Seguros" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/">Action</NavDropdown.Item>
                            <NavDropdown.Item href="/">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/">Something</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className='headerNavTxtView' >
                        <NavDropdown title="Saúde 360" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/">Action</NavDropdown.Item>
                            <NavDropdown.Item href="/">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/">Something</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                    <div className='headerNavTxtView' >
                        <Link to="/" className="linkHeaderStyle">
                            <div className='txtDropDownHeader'>Serviços</div>
                        </Link>
                    </div>
                    <div className='headerNavTxtView' >
                        <Link to="/" className="linkHeaderStyle">
                            <div className='txtDropDownHeader'>Biblioteca de Saúde</div>
                        </Link>
                    </div>
                    <div className='headerNavTxtView' >
                        <NavDropdown title="Apoio ao Cliente" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/">Action</NavDropdown.Item>
                            <NavDropdown.Item href="/">
                                Another action
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/">Something</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
                <div className='headerFindAndUserProfContainer' >
                    <IoSearch className='headerFindIcon' />
                    <img onClick={handleLogout} className='headerUserAccIcon' src={IconUserAccSvg} alt='imagem' />
                </div>
            </div>
        </div>
    )
}

export default Header