import React from "react";
import '../css/customAlert.css'
import Modal from 'react-bootstrap/Modal';
import CustomButton from "./CustomButton";
import { MdOutlineDownloadDone } from "react-icons/md";
import { MdOutlineErrorOutline } from "react-icons/md";
import { IoWarningOutline } from "react-icons/io5";
import { IoIosCloseCircleOutline } from "react-icons/io";
import IconWarning from "../assets/svg/iconWarning.svg";
import IconInfo from "../assets/svg/iconInfo.svg";
import IconSuccess from "../assets/svg/iconSuccess.svg";
import IconError from "../assets/svg/iconError.svg";


const CustomAlert = (props) => {
    const {
        text = '',
        type = '',
        title = ''
    } = props;
    
    let modalIcon;
    let modalIconColor;
    switch (type) {
        case 'success':
            //modalIcon = <MdOutlineDownloadDone />;
            modalIcon = IconSuccess;
            modalIconColor = '#13FF1E';
            break;
        case 'info':
            //modalIcon = <MdOutlineErrorOutline />;
            modalIcon = IconInfo;
            modalIconColor = '#13DFFF';
            break;
        case 'warning':
            //modalIcon = <IoWarningOutline />;
            modalIcon = IconWarning;
            modalIconColor = '#FFF113';
            break;
        case 'error':
            //modalIcon = <IoIosCloseCircleOutline />;
            modalIcon =  IconError;
            modalIconColor = '#FF1313';
            break;
        default:
            //modalIcon = <MdOutlineDownloadDone />;
            modalIcon = IconSuccess;
            modalIconColor = '#13FF1E';
            break;
    }

    const iconStyle = {
        color: modalIconColor,
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modalMainContainer"
        >
            <div className="modalTitle" id="contained-modal-title-vcenter">
                <img className="modalIcon" style={iconStyle} src={modalIcon} alt='imagem'/>
            </div>
            <div className="contentView">
                <div className="titleViewContainer">
                    <div className="titleView">{title}</div>
                </div>
                <div className="textViewContainer">
                    <div className="textView">{text}</div>
                </div>
            </div>
            <div className="btnView">
                <CustomButton 
                    text={'Fechar'} 
                    color={'#00214D'}
                    textColor={'#fff'}
                    onPress={props.onHide}
                />
            </div>
        </Modal>
    );
};

export default CustomAlert;