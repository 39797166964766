import React, { useState, useEffect } from 'react';
import '../../css/seeMovements.css';
import '../../css/customModalMovementsInfo.css'
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/CustomButton';
import ImgIconPersonSvg from '../../assets/svg/iconPerson.svg';
import ImgNoMovementsDataSvg from '../../assets/svg/iconNoMovementsData.svg';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DatePresets from '../../components/DatePresets';
import moment from 'moment';
import 'moment/locale/pt';
import { FaAngleRight } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { IoMdInformationCircleOutline } from "react-icons/io";
import movementsDataMock from '../../mock/movementsData.json';
import useCodes from '../../hooks/useCodes';
import CustomModalMovementsInfo from '../../components/CustomModalMovementsInfo';

function SeeMovements() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const [selectedOption, setSelectedOption] = useState('M');
    const [isAccumulatedBalance, setIsAccumulatedBalance] = useState(false);
    const [isBalanceSpent, setIsBalanceSpent] = useState(false);
    const [isBalanceOfCodesAndCampaigns, setIsBalanceOfCodesAndCampaigns] = useState(false);
    const [isPressedItem, setIsPressedItem] = useState(false)
    const dateFormat = "DD/MM/YYYY";
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showAlertInfo, setShowAlertInfo] = useState(false);
    const [openMovementsInfoModal, setOpenMovementsInfoModal] = useState(false);
    const [openSelectFilteModal, setOpenSelectFilteModal] = useState(false);
    const today = moment();
    const { paymentTypeList, getPaymentType, paymentStatusList, getPaymentStatus } = useCodes();
    const categoryName = movementsDataMock.movementsInfo.moreAccumulatedForProductCategory;
    const categoryAmount = movementsDataMock.movementsInfo.moreAccumulatedForProductCategoryAmount;
    const pendingBalance = movementsDataMock.movementsInfo.pendingBalance;
    const availableBalance = movementsDataMock.movementsInfo.availableBalance;
    const totalBalance = movementsDataMock.movementsInfo.totalBalance;

    const handleCheckboxChangeOne = (event) => {
        setIsAccumulatedBalance(event.target.checked);
    };

    const handleCheckboxChangeTwo = (event) => {
        setIsBalanceSpent(event.target.checked);
    };

    const handleCheckboxChangeThree = (event) => {
        setIsBalanceOfCodesAndCampaigns(event.target.checked);
    };

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const handlegoBackClick = () => {
        navigate(-1);
    };

    const handleClickShowConfigModal = () => {
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       setOpenInfoModal(true)
    };

    const handleClickHowStartAccumulate = () => {
        alert("Veja como começar a a acumular");
    };

    const handleClickShowInfoAlert = (item) => {
        setIsPressedItem(item)
        setShowAlertInfo(true)
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

    const handleClearFilter  = () => {
        setStartDate(null)
        setEndDate(null)
        setIsAccumulatedBalance(false)
        setIsBalanceSpent(false)
        setIsBalanceOfCodesAndCampaigns(false)
    };

    const handlePresets  = (start, end) => {
        setStartDate(start)
        setEndDate(end)
    };

    const handleClickOpenInfoDataModal = (item) => {
        setIsPressedItem(item)
        setOpenMovementsInfoModal(true)
    };

    const handleOpenFilterModal = () => {
        setOpenSelectFilteModal(true)
    };

    const renderMovimentsPaymentStatusByItem = (item) => {
        const paymentStatus =  getPaymentStatus(item.balanceMovements);
        return paymentStatus;
    };

    const renderMovimentsPaymentTypeByItem = (item) => {
        const paymentType =  getPaymentType(item.paymentType);
        return paymentType;
    };

    const onHide = () => {
        setOpenSelectFilteModal(false)
    };

    const presets = [
        {
            text: "Ir para hoje",
            start: today,
            end: today,
        },
        {
        text: "Últimos 7 dias",
        start: moment().subtract(1, "week"),
        end: today,
        },
        {
        text: "Últimos 14 dias",
        start: moment().subtract(2, "week"),
        end: today,
        },
        {
        text: "Últimos 30 dias",
        start: moment().subtract(1, "month"),
        end: today,
        },
    ];

    const countNonNullValues = (array, key) => array.filter(item => item[key] !== null).length;

    const countBalanceOfCodesAndCampaigns = countNonNullValues(movementsDataMock.movements, 'balanceOfCodesAndCampaigns');
    const countAmountAccumulated = countNonNullValues(movementsDataMock.movements, 'amountAccumulated');
    const countAmountSpent = countNonNullValues(movementsDataMock.movements, 'amountSpent');

    return (  
        <>
        { isTablet &&  
        <Header/> 
        }
        {/* Start fist div componet with title / header */}
        <div className={ isTablet ? "mainViewContainerSeeMovements" : ( isMobile ? 'mainViewContainerSeeMovementsTablet' : 'mainViewContainerSeeMovementsMobile') }>
            <div className={ isTablet ? 'firstTxtViewContainerSeeMovements' : 'firstTxtViewContainerSeeMovementsMobile' }>
            
                <div className={ isTablet ? 'titleSaldoContainerSeeMovementsWithSaldo' : ( isMobile ? 'titleSaldoContainerSeeMovementsWithSaldoTablet' : 'titleSaldoContainerSeeMovementsWithSaldoMobile')}>
                    <div className={ isTablet ? 'titleContainerSeeMovementsWithSaldo' : ( isMobile ? 'titleContainerSeeMovementsWithSaldoTablet' : 'titleContainerSeeMovementsWithSaldoMobile')}>
                        <div className={ isTablet ? 'firstTitleViewSeeMovementsWithSaldo' : ( isMobile ? 'firstTitleViewSeeMovementsWithSaldoTablet' : 'firstTitleViewSeeMovementsWithSaldoMobile')}>
                            { isTablet ?(
                                <div className='textTitleBoldSeeMovementsWithSaldo'>Carteira Virtual Médis</div> ) : (
                                <>
                                <div className='textTitleRegularSeeMovementsWithSaldoMobile'>Carteira</div>
                                <div className='textTitleBoldSeeMovementsWithSaldoMobile'>Virtual Médis</div>
                                </>
                            )}      
                        </div>
                        <div className='firstTitleViewConfigOnfoSeeMovementsWithSaldo'>
                            <button className='infoHeaderIonBtnSeeMovementsWithSaldo'  type='button' onClick={handleClickShowConfigModal} >
                                <span className='txtConfigTitleSeeMovementsWithSaldo'><IoSettingsOutline  /></span> <span className='txtConfigTitleSeeMovementsWithSaldo'>Configurações</span>
                            </button>
                            <CustomAlert 
                                title='Configurações'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openConfigModal} 
                                onHide={() => setOpenConfigModal(false)} 
                            /> 
                            <button className='infoHeaderIonBtnSeeMovementsWithSaldo'  type='button' onClick={handleClickShowInfo} >
                                <span className='infoIconSeeMovementsWithSaldo'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitleSeeMovementsWithSaldo'>Como funciona</span>
                            </button>
                            <CustomAlert 
                                title='Como Funciona'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openInfoModal} 
                                onHide={() => setOpenInfoModal(false)} 
                            />     
                        </div>
                    </div>
                    <div className={ isTablet ? 'saldoContainerSeeMovementsWithSaldo' : ( isMobile ? 'saldoContainerSeeMovementsWithSaldoTablet' : 'saldoContainerSeeMovementsWithSaldoMobile')}>
                        { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                            <>
                            <div>
                                <span className={ isTablet ? 'txtSaldoSeeMovementsWithSaldo' : 'txtSaldoSeeMovementsWithSaldoMobile'}>O meu </span>
                                <span className={ isTablet ? 'txtBoldSaldoSeeMovementsWithSaldo' : 'txtBoldSaldoSeeMovementsWithSaldoMobile'}>Saldo</span>
                            </div>
                            <div className='saldoInfoContainerSeeMovementsWithSaldo'>
                                <div className="saldoEuroIconViewSeeMovementsWithSaldo">
                                    <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconSeeMovementsWithSaldo" />
                                </div>
                                <div className='txtRegularSaldoSeeMovementsWithSaldo'>{availableBalance}€</div>
                            </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={ isTablet ? 'thirdMainContainerSeeMovements' : 'thirdMainContainerSeeMovementsMobile' }>
                    <div className={ isTablet ? 'goBackBtnViewMbWaySeeMovements' : 'goBackBtnViewMbWaySeeMovementsMobile' }>
                        <button className='txtGoBackBtnSeeMovements'  type='button' onClick={handlegoBackClick} >
                            <FaArrowLeftLong className='goBackIconSeeMovements' /> Voltar
                        </button>
                    </div>
                </div>
                {/* End fist div componet with title / header */}

                {/* Start second div componet with balance informations 3 blocks */}
                <div className={ isTablet ? 'mainSecondBoxViewContainerSeeMovements' : 'mainSecondBoxViewContainerSeeMovementsMobile'}>
                    <div className={ isTablet ? 'secondBoxMainContainerSeeMovements' : ( isMobile ? 'secondBoxMainContainerSeeMovementsTablet' : 'secondBoxMainContainerSeeMovementsMobile') }>
                        <div className={ isTablet ? 'mainBoxViewContainerSeeMovements' : ( isMobile ? 'mainBoxViewContainerSeeMovementsTablet' : 'mainBoxViewContainerSeeMovementsMobile') }>
                            <div className='rowMainBoxViewContainerSeeMovements'>
                                <div className="personIconViewSeeMovements">
                                    <img src={ImgIconPersonSvg} alt="icon" className="personIconSeeMovements" />
                                </div>
                                <div className="txtPersonIconTitleSeeMovements">Saldo disponível</div>
                            </div>
                            <div className='secondRowMainBoxViewContainerSeeMovements'>
                                <div  className="txtExtraViewContainerSaldoPersonSeeMovements">
                                    <div className="textTitleRegularSeeMovementsWithSaldoMobile">{availableBalance}€</div>
                                </div>
                                <div className='colunmBoxViewContainerSeeMovements'>
                                    <div className="txtExtraSaldoBigerPersonSeeMovements">+ {pendingBalance}€</div>
                                    <div className="txtExtraSaldoPersonSeeMovements">Saldo pendente</div>
                                </div>
                            </div>
                        </div>
                        <div className={ isTablet ? 'mainBoxViewContainerSeeMovements' : ( isMobile ? 'mainBoxViewContainerSeeMovementsTablet' : 'mainBoxViewContainerSeeMovementsMobile') }>
                            <div className='rowMainBoxViewContainerSeeMovements'>
                                    <div className="personIconViewSeeMovements">
                                        <img src={ImgIconPersonSvg} alt="icon" className="personIconSeeMovements" />
                                    </div>
                                    <div className="txtPersonIconTitleSeeMovements">Acumulado desde sempre</div>
                            </div>
                            <div className='secondRowMainBoxViewContainerSeeMovements'>
                                <div className="textTitleRegularSeeMovementsWithSaldoMobile">{totalBalance}€</div>
                            </div>
                        </div>
                        <div className={ isTablet ? 'mainBoxViewContainerSeeMovements' : ( isMobile ? 'mainBoxViewContainerSeeMovementsTablet' : 'mainBoxViewContainerSeeMovementsMobile') }>
                            <div className='rowMainBoxViewContainerSeeMovements'>
                                <div className="personIconViewSeeMovements">
                                    <img src={ImgIconPersonSvg} alt="icon" className="personIconSeeMovements" />
                                </div>
                                <div className="txtPersonIconTitleSeeMovements">Onde mais acumulou</div>
                            </div>
                            <div className='secondRowMainBoxViewContainerSeeMovements'>
                                <div className="textTitleRegularSeeMovementsWithSaldoMobile">{categoryName}</div>
                                <div className='colunmBoxViewContainerSeeMovements'>
                                    <div className="txtExtraSaldoBigerPersonSeeMovements">{categoryAmount}€</div>
                                    <div className="txtExtraSaldoPersonSeeMovements">Total acumulado</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End second div componet with balance informations 3 blocks */}

                {/* Start Mobile div componet with filters */}

                { !isTablet && (
                        <>
                        <div className='mainFilterColumnViewContainerSeeMovementsMobile'>
                            <div className='firstOptionsFormChekedViewSeeMovements'>
                                <div className='txtFirstOptionsTitleSeeMovements'>Pesquise os seus movimentos</div>
                                <div className='txtFirstOptionsSubTitleSeeMovements'>Filtre por tipo ou por data</div>
                            </div>
                            <div className='firstSubMainContainerSeeMovements'>
                                <CustomButton 
                                    text={'Filtrar'} 
                                    textColor={'#00214D'}
                                    onPress={handleOpenFilterModal} 
                                    color={'#fff'}
                                    borderColor={'#00214D'}
                                    hasArrow
                                    iconColor={'#00214D'}
                                    iconType={'filter'}
                                />

                                <Modal
                                    size="lg"
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    className="modalMainContainerMovementsInfo "
                                    show={openSelectFilteModal}
                                >
                                    <div className="contentViewCustomModalSelectFilterMobile">
                                        <div className="titleViewContainerMovementsInfo ">
                                            <div className="titleViewMovementsInfo" >Modal</div>
                                        </div>
                                        <div className='txtFirstOptionsTitleViewSeeMovements'>
                                            <div style={{ marginBottom: '16px'}}>
                                                <div className='txtFirstOptionsTitleSeeMovements'>Tipo de movimento</div>
                                            </div>
                                            <Form>
                                                <div className='firstOptionsFormChekedViewSeeMovements'>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label={`Saldo acumulado (${countAmountAccumulated})`}
                                                        checked={isAccumulatedBalance}
                                                        onChange={handleCheckboxChangeOne}
                                                    />
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label={`Saldo gasto (${countAmountSpent})`}
                                                        checked={isBalanceSpent}
                                                        onChange={handleCheckboxChangeTwo}
                                                    />
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label={`Saldo de códigos e campanhas (${countBalanceOfCodesAndCampaigns})`}
                                                        checked={isBalanceOfCodesAndCampaigns}
                                                        onChange={handleCheckboxChangeThree}
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                        <div className='optionsDeviderLineSeeMovements'></div>
                                        <div className='txtFirstOptionsTitleViewSeeMovements'>
                                            <div style={{ marginBottom: '16px'}}>
                                                <div className='txtFirstOptionsTitleSeeMovements'>Data</div>
                                            </div>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker value={startDate} onChange={(newData) => setStartDate(newData)} label="Data de início" />
                                                        <DatePicker value={endDate} onChange={(newData) => setEndDate(newData)} label="Data de fim" />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                        </div>
                                        <div className='optionsDeviderLineSeeMovements'></div>
                                        <CustomButton 
                                            text={'Remover filtros'} 
                                            textColor={'#00214D'}
                                            onPress={handleClearFilter} 
                                            color={'#fff'}
                                            borderColor={'#00214D'}
                                        />
                                        <div style={{ marginTop: '-16px' }}>
                                        {presets.map(({ text, start, end }) => {
                                            const isChosen =
                                            moment(start).format(dateFormat) ===
                                                moment(startDate).format(dateFormat) &&
                                            moment(end).format(dateFormat) ===
                                                moment(endDate).format(dateFormat);
                                            return (
                                                <CustomButton 
                                                    key={text}
                                                    text={text} 
                                                    textColor={'#00214D'}
                                                    onPress={() => handlePresets(start, end)} 
                                                    hasSubLine={true}
                                                    borderColor={'#00214D'}
                                                    hasArrow={true}
                                                    iconColor={'#00214D'}
                                                    iconType={'calendar'}
                                                />
                                            );
                                        })}
                                        </div>
                                    </div>

                                    <div className="btnViewMovementsInfo ">
                                        <CustomButton 
                                            text={'Fechar'} 
                                            color={'#00214D'}
                                            textColor={'#fff'}
                                            onPress={onHide}
                                        />
                                    </div>
                                </Modal>
        
                            </div>

                        </div>
                        </>
                )}

                {/* End Mobile div componet with filters */}

                {/* Start third div componet with swith selector */}
                <div className={ isTablet ? 'secondMainContainerSeeMovements' : 'secondMainContainerSeeMovementsMobile' }>
                    <div className={ isTablet ? 'swithSelectorContainerSeeMovements' : 'swithSelectorContainerSeeMovementsMobile'}>
                        <CustomButton 
                            text={'Movimentos'} 
                            textColor={'#495680'}
                            onPress={() => handleOptionClick("M")} 
                            hasSubLine={true}
                            borderColor={selectedOption === 'M' && '#00214D'}
                            fontSize={selectedOption === 'M' && '18px'}
                        />
                        <CustomButton 
                            text={'Pendentes'} 
                            textColor={'#495680'}
                            onPress={() => handleOptionClick("P")} 
                            hasSubLine={true}
                            borderColor={selectedOption === 'P' && '#00214D'}
                            fontSize={selectedOption === 'P' && '18px'}
                        />
                    </div>
                    <div className='swithSelectorDeviderLineSeeMovements'></div>

                    {/* End third div componet with swith selector */}

                    <div className={ isTablet ? 'firstSubMainContainerSeeMovements' : 'firstSubMainContainerSeeMovementsMobile' }>
                        <div className='mainOptionsViewContainerSeeMovements'>
                            {/* Start fourth div componet with filters */}
                            { isTablet && (
                                <>
                                <div className='mainOptionsColumnViewContainerSeeMovements'>
                                    <div className='txtFirstOptionsTitleViewSeeMovements'>
                                        <div style={{ marginBottom: '16px'}}>
                                            <div className='txtFirstOptionsTitleSeeMovements'>
                                                { isLoading ? <PlaceholderLoading shape="rect" width={250} height={20}/> : 'Pesquise os seus movimentos' }
                                            </div>
                                        </div>
                                        <div className='txtFirstOptionsSubTitleSeeMovements'>
                                            { isLoading ? <PlaceholderLoading shape="rect" width={250} height={20}/> : 'Filtre por tipo ou por data'}
                                        </div>
                                    </div>
                                    <div className='optionsDeviderLineSeeMovements'></div>
                                    <div className='txtFirstOptionsTitleViewSeeMovements'>
                                        <div style={{ marginBottom: '16px'}}>
                                            <div className='txtFirstOptionsTitleSeeMovements'>
                                                { isLoading ? <PlaceholderLoading shape="rect" width={250} height={20}/> : 'Tipo de movimento'}
                                            </div>
                                        </div>
                                        <Form>
                                            { isLoading ? <PlaceholderLoading shape="rect" width={350} height={75}/> : (
                                            <>
                                            <div className='firstOptionsFormChekedViewSeeMovements'>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Saldo acumulado (${countAmountAccumulated})`}
                                                    checked={isAccumulatedBalance}
                                                    onChange={handleCheckboxChangeOne}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Saldo gasto (${countAmountSpent})`}
                                                    checked={isBalanceSpent}
                                                    onChange={handleCheckboxChangeTwo}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Saldo de códigos e campanhas (${countBalanceOfCodesAndCampaigns})`}
                                                    checked={isBalanceOfCodesAndCampaigns}
                                                    onChange={handleCheckboxChangeThree}
                                                />
                                            </div>
                                            </>
                                            )}
                                        </Form>
                                    </div>
                                    <div className='optionsDeviderLineSeeMovements'></div>
                                    <div className='txtFirstOptionsTitleViewSeeMovements'>
                                        <div style={{ marginBottom: '16px'}}>
                                            <div className='txtFirstOptionsTitleSeeMovements'>
                                                { isLoading ? <PlaceholderLoading shape="rect" width={150} height={20}/> : 'Data'}
                                            </div>
                                        </div>
                                        <div>
                                            { isLoading ? <PlaceholderLoading shape="rect" width={150} height={25}/> : (
                                                <>
                                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                                    <DemoContainer components={['DatePicker']}>
                                                        <DatePicker value={startDate} onChange={(newData) => setStartDate(newData)} label="Data de início" />
                                                        <DatePicker value={endDate} onChange={(newData) => setEndDate(newData)} label="Data de fim" />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className='optionsDeviderLineSeeMovements'></div>
                                    { isLoading ? <PlaceholderLoading shape="rect" width={150} height={50}/> : (
                                    <>
                                        <CustomButton 
                                            text={'Remover filtros'} 
                                            textColor={'#00214D'}
                                            onPress={handleClearFilter} 
                                            color={'#fff'}
                                            borderColor={'#00214D'}
                                        />
                                        <div style={{ marginTop: '-16px' }}>
                                            <DatePresets
                                                startDate={startDate}
                                                endDate={endDate}
                                                dateFormat={dateFormat}
                                                handlePresets={(start, end) => {
                                                setStartDate(start);
                                                setEndDate(end);
                                            }}
                                            />
                                            {/* <CustomButton 
                                                text={'Ir para hoje'} 
                                                textColor={'#00214D'}
                                                onPress={handleSetFilterToDay} 
                                                hasSubLine={true}
                                                borderColor={'#00214D'}
                                                hasArrow={true}
                                                iconColor={'#00214D'}
                                                iconType={'calendar'}
                                            /> */}
                                        </div>
                                    </>
                                    )}
                                </div>
                                </>
                            )}
                            {/* End fourth div componet with filters */}

                            {/* Start fifth div componet with info blocks list */}
                            <div className='mainOptionsSecondColumnViewContainerSeeMovements'>
                                {movementsDataMock.movements.length > 0 ? (
                                    movementsDataMock.movements
                                        .filter(item => renderMovimentsPaymentTypeByItem(item)?.id === selectedOption)
                                        .map((item, index) => (
                                <div style={{ width:'100%', maxHeight:'100%', display:'flex', flexDirection:'column' }} key={index}>
                                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                                    <>
                                    <div className='iconDotainOptionsSecondColumnViewContainerSeeMovements' ><GoDotFill className='iconDotainOptionsSecondColumnSeeMovements' /></div>
                                    <div className={ isTablet ? 'mainDataBigBoxViewContainerSeeMovements' : 'mainDataBigBoxViewContainerSeeMovementsMobile' }>
                                        <div className='txtFirstOptionsTitleSeeMovements'>{moment(item.date).format('LL')}</div>
                                        <div className={ isTablet ? 'mainDataBoxViewContainerSeeMovements' : 'mainDataBoxViewContainerSeeMovementsMobile'}>
                                            <div className='rowDataBoxViewContainerSeeMovements'>
                                                <div className="btnViewOpenInfoDataModalIconSeeMovements">{moment(item.date).format('LL')}</div>
                                                <div className="personIconViewSeeMovements">
                                                    <button className='btnViewOpenInfoDataModalIconSeeMovements'  type='button' onClick={ () => handleClickOpenInfoDataModal(item)}>
                                                        <FaAngleRight className='openInfoDataModalIconSeeMovements' />
                                                    </button>
                                                    <CustomModalMovementsInfo 
                                                        title={renderMovimentsPaymentStatusByItem(item)?.title}
                                                        text={renderMovimentsPaymentStatusByItem(item)?.txtAlert}
                                                        date={moment(item.date).format('LL')}
                                                        amountAcumulated={item.amountAccumulated}
                                                        category={item.productCategory}
                                                        show={ isPressedItem === item ? openMovementsInfoModal : null} 
                                                        amountPaid={item.amountPaid}
                                                        isPending={item.paymentType}
                                                        onHide={() => setOpenMovementsInfoModal(false)} 
                                                    /> 
                                                </div>
                                            </div>
                                            <div className='mainTitleDataBoxViewSeeMovements'>
                                                <div className='txtFirstOptionsTitleSeeMovements'>{item.title}</div>
                                            </div>
                                            <div className='rowDataBoxViewContainerSeeMovements'>
                                                
                                                    <button className='mainModalInfoStatusDataBoxViewSeeMovements' style={{ background:renderMovimentsPaymentStatusByItem(item)?.color}} type='button' onClick={ () => handleClickShowInfoAlert(item)} >
                                                        <div className='iconModalInfoStatusDataBoxSeeMovements' style={{ color:renderMovimentsPaymentStatusByItem(item)?.iconColor}} ><GoDotFill /></div>
                                                        <div className='txtModalInfoStatusDataBoxSeeMovements'>{renderMovimentsPaymentStatusByItem(item)?.title}</div>
                                                        <div className='iconInfoViewModalInfoStatusDataBoxSeeMovements'>
                                                            <IoMdInformationCircleOutline className='iconInfoModalInfoStatusDataBoxSeeMovements' />
                                                        </div>
                                                    </button>
                                                    <Modal 
                                                        show={ isPressedItem === item ? showAlertInfo : null} 
                                                        size="sm"
                                                        aria-labelledby="contained-modal-title-vcenter"
                                                        centered
                                                        backdrop
                                                        onHide={() => setShowAlertInfo(false)}  
                                                    >
                                                        <div className='mainViewAlerSmModalSeeMovements'>
                                                            <div className='txtTitleAlerSmModalSeeMovements'>{renderMovimentsPaymentStatusByItem(item)?.title}</div>
                                                            <div className='txtAlerSmModalSeeMovements'>{renderMovimentsPaymentStatusByItem(item)?.txtAlert}</div>
                                                        </div>
                                                    </Modal>

                                                <div className="textTitleRegularSeeMovementsWithSaldoMobile">{item.amountPaid}€</div>
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                    )}
                                </div>
                                ))
                                ) : (
                                    <div className='noDataViewContainerSeeMovements'>
                                        { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                                        <>
                                            <div className="noMovementDataIconViewSeeMovements">
                                                <img src={ImgNoMovementsDataSvg} alt="icon" className="noMovementDataIconSeeMovements" />
                                            </div>
                                            <div className='txtFirstOptionsTitleSeeMovements'>Não há movimentos para mostrar</div>
                                            <div className='txtNoMovementColumnViewContainerSeeMovements'>
                                                <div className='txtNoMovementViewContainerSeeMovements'>
                                                    <span className='txtNoMovementSmallSeeMovements'>Tem algum filtro aplicado?</span>
                                                    <button className='txtNoMovementSubLineSeeMovements' onClick={handleClearFilter} >Remova todos os filtros</button>
                                                    <span className='txtNoMovementSmallSeeMovements'>de pesquisa.</span>
                                                </div>
                                                <div className='txtNoMovementViewContainerSeeMovements'>
                                                    <span className='txtNoMovementSmallSeeMovements'>Ainda não acumulou saldo?</span>
                                                    <button className='txtNoMovementSubLineSeeMovements' onClick={handleClickHowStartAccumulate} >Veja como começar a a acumular</button>
                                                    <span className='txtNoMovementSmallSeeMovements'>.</span>
                                                </div>
                                            </div>
                                        </>
                                        )}
                                    </div>
                                )}
                            </div>   
                            {/* End fifth div componet with info blocks list */}
                        </div>                    
                    </div>
                </div>   
            </div>
        </div>
        </>
    )
}

export default SeeMovements;