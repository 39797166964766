import React, { useState, useEffect } from 'react';
import '../../css/onboardingLinkCardSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import ImgNavBoxSvg from '../../assets/svg/iconTwoPersons.svg';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import CustomAlert from '../../components/CustomAlert';

function LinkCardStepOne() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const hadleBtnBoxMbWay = () => {
        navigate('/linkCardStepMbWay');
    };

    const hadleBtnBoxMB = () => {
        navigate('/linkCardStepMb');
    };

    const handleClickShowConfigModal = () => {
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       setOpenInfoModal(true)
    };

    const handlegoBackClick = () => {
        navigate(-1);
    };

  return (  
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? "mainViewContainerLinckCardSteps" : ( isMobile ? "mainViewContainerLinckCardStepsTablet" : "mainViewContainerLinckCardStepsMobile")}>
        <div className={ isTablet ? 'firstTxtViewContainerLinckCardSteps' : ( isMobile ? 'firstTxtViewContainerLinckCardStepsTablet' : 'firstTxtViewContainerLinckCardStepsMobile') }>
            
            <div className={ isTablet ? 'titleSaldoContainerLinckCardStepsWithSaldo' : ( isMobile ? 'titleSaldoContainerLinckCardStepsWithSaldo' : 'titleSaldoContainerLinckCardStepsWithSaldoMobile')}>
                <div className={ isTablet ? 'titleContainerLinckCardStepsWithSaldo' : ( isMobile ? 'titleContainerLinckCardStepsWithSaldo' : 'titleContainerLinckCardStepsWithSaldoMobile')}>
                    <div className={ isTablet ? 'firstTitleViewLinckCardStepsWithSaldo' : ( isMobile ? 'firstTitleViewLinckCardStepsWithSaldo' : 'firstTitleViewLinckCardStepsWithSaldoMobile')}>
                        { isTablet ? (
                            <div className='textTitleBoldLinckCardStepsWithSaldo'>Carteira Virtual Médis</div> ) : (
                            <>
                            <div className='textTitleRegularLinckCardStepsWithSaldoMobile'>Carteira</div>
                            <div className='textTitleBoldLinckCardStepsWithSaldoMobile'>Virtual Médis</div>
                        </>
                        )}      
                    </div>
                    <div className='firstTitleViewConfigOnfoLinckCardStepsWithSaldo'>
                        <button className='infoHeaderIonBtnLinckCardStepsWithSaldo'  type='button' onClick={handleClickShowConfigModal} >
                            <span className='txtConfigTitleLinckCardStepsWithSaldo'><IoSettingsOutline  /></span> <span className='txtConfigTitleLinckCardStepsWithSaldo'>Configurações</span>
                        </button>
                        <CustomAlert 
                            title='Configurações'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openConfigModal} 
                            onHide={() => setOpenConfigModal(false)} 
                        /> 
                        <button className='infoHeaderIonBtnLinckCardStepsWithSaldo'  type='button' onClick={handleClickShowInfo} >
                            <span className='infoIconLinckCardStepsWithSaldo'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitleLinckCardStepsWithSaldo'>Como funciona</span>
                        </button>
                        <CustomAlert 
                            title='Como Funciona'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openInfoModal} 
                            onHide={() => setOpenInfoModal(false)} 
                        />      
                    </div>
                </div>
                <div className={ isTablet ? 'saldoContainerLinckCardStepsWithSaldo' : ( isMobile ? 'saldoContainerLinckCardStepsWithSaldo' : 'saldoContainerLinckCardStepsWithSaldoMobile')}>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                        <>
                        <div>
                            <span className={ isTablet ? 'txtRegSaldoLinckCardStepsWithSaldo' : 'txtRegSaldoLinckCardStepsWithSaldoMobile'}>O meu </span>
                            <span className={ isTablet ? 'txtBoldSaldoLinckCardStepsWithSaldo' : 'txtBoldSaldoLinckCardStepsWithSaldoMobile'}>Saldo</span>
                        </div>
                        <div className='saldoInfoContainerLinckCardStepsWithSaldo'>
                            <div className="saldoEuroIconViewLinckCardStepsWithSaldo">
                                <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconLinckCardStepsWithSaldo" />
                            </div>
                            <div className='txtRegularSaldoLinckCardStepsWithSaldo'>22,3€</div>
                        </div>
                        </>
                    )}
                </div>
            </div>
            
            <div className={ isTablet ? 'goBackBtnViewLinckCardSteps' : 'goBackBtnViewLinckCardStepsMobile' }>
                <button className='txtGoBackBtnLinckCardSteps'  type='button' onClick={handlegoBackClick} >
                    <FaArrowLeftLong className='goBackIconLinckCardSteps' /> Voltar
                </button>
            </div>
            <div className={ isTablet ? 'secondMainContainerLinckCardSteps' : 'secondMainContainerLinckCardStepsMobile' }>
                <div className={ isTablet ? 'firstSubMainContainerLinckCardSteps'  : 'firstSubMainContainerLinckCardStepsMobile' }>
                    <div className='secondTitleViewLinckCardSteps'>
                        <span className={  isTablet ? 'textTitleRegular400LinckCardSteps' : ( isMobile ? 'textTitleRegular400LinckCardStepsTablet' : 'textTitleBold700LinckCardStepsMobile' ) }>Associe um cartão bancário</span>
                    </div>
                    <div className='txtSecondTitleViewLinckCardSteps'>
                        <div className='textSecondTitleViewLinckCardSteps'>Pode adicionar até ao máximo de 5 cartões, através da app MB WAY ou do multibanco.</div>
                    </div>
                    <div className='txtSecondTitleViewLinckCardSteps'>
                        <div className='textSecondTitleViewLinckCardSteps'>Para associar através do multibanco, utilize o seguinte número.</div>
                    </div>
                    <div className='placeholderExampleContainer'>
                        <div className='txtPlaceholderExample'>#########</div>
                    </div>
                    
                    { !isTablet && (
                        <>
                        <button className={ isMobile ? 'navBoxViewContainerLinckCardStepsTablet' : 'navBoxViewContainerLinckCardStepsMobile' } onClick={hadleBtnBoxMbWay}>
                            <img src={ImgNavBoxSvg} alt="imagem" className="imagemNavBoxSvg" />
                            <div className='txtNavBoxTitleViewContainerLinckCardSteps'>
                                <div className='txtNavBoxTitleLinckCardSteps'>Por MB WAY</div>
                            </div>
                            <div className='txtNavBoxViewContainerLinckCardSteps'>
                                <div className='txtNavBoxRegularLinckCardSteps'>Associe um cartão em segundos usando o MB WAY</div>
                            </div>
                            <div className='arrowIconNavBoxViewContainerLinckCardSteps'>
                                <FaArrowRightLong className='arrowIconNavBoxLinckCardSteps' /> 
                            </div>
                        </button>

                        <button className={ isMobile ? 'navBoxViewContainerLinckCardStepsTablet' : 'navBoxViewContainerLinckCardStepsMobile' } onClick={hadleBtnBoxMB}>
                            <img src={ImgNavBoxSvg} alt="imagem" className="imagemNavBoxSvg" />
                            <div className='txtNavBoxTitleViewContainerLinckCardSteps'>
                                <div className='txtNavBoxTitleLinckCardSteps'>Por multibanco</div>
                            </div>
                            <div className='txtNavBoxViewContainerLinckCardSteps'>
                                { isMobile ?
                                    <div className='txtNavBoxRegularLinckCardSteps'>Associe um cartão usando um ATM</div>
                                    :
                                    <div className='txtNavBoxRegularLinckCardSteps'>Associe um cartão <br /> usando um ATM</div>
                                }
                            </div>
                            <div className='arrowIconNavBoxViewContainerLinckCardSteps'>
                                <FaArrowRightLong className='arrowIconNavBoxLinckCardSteps' /> 
                            </div>
                        </button>
                        </>
                    )}

                    <div className={ isTablet ? 'txtContactInfoViewContainerLinckCardSteps' : 'txtContactInfoViewContainerLinckCardStepsMobile' }>
                        <div className='txtContactInfoBoldLinckCardSteps'>Tem dúvidas?</div>
                            <span className='txtContactInfoRegularLinckCardSteps'>Contacte-nos pelo xxxx@medis.pt ou pela Linha Médis: </span>
                            <span className='txtContactInfoRegularUnderLineGreenLinckCardSteps'>218 458 888 </span>
                            <span className='txtContactInfoRegularLinckCardSteps'>(dias úteis das 8h00 às 20h00)</span>
                    </div>
                    <div className={ isTablet ? 'txtSmallContactInfoViewContainerLinckCardSteps' : 'txtSmallContactInfoViewContainerLinckCardStepsMobile' }>
                        <div className='txtSmallContactInfoRegularLinckCardSteps'>Custo de chamada para Rede Fixa Nacional.</div>
                    </div>
                </div>

                { isTablet && (
                    <>
                    <button className='navBoxViewContainerLinckCardSteps' onClick={hadleBtnBoxMbWay}>
                        <img src={ImgNavBoxSvg} alt="imagem" className="imagemNavBoxSvg" />
                        <div className='txtNavBoxTitleViewContainerLinckCardSteps'>
                            <div className='txtNavBoxTitleLinckCardSteps'>Por MB WAY</div>
                        </div>
                        <div className='txtNavBoxViewContainerLinckCardSteps'>
                            <div className='txtNavBoxRegularLinckCardSteps'>Associe um cartão em segundos usando o MB WAY</div>
                        </div>
                        <div className='arrowIconNavBoxViewContainerLinckCardSteps'>
                            <FaArrowRightLong className='arrowIconNavBoxLinckCardSteps' /> 
                        </div>
                    </button>

                    <button className='navBoxViewContainerLinckCardSteps' onClick={hadleBtnBoxMB}>
                        <img src={ImgNavBoxSvg} alt="imagem" className="imagemNavBoxSvg" />
                        <div className='txtNavBoxTitleViewContainerLinckCardSteps'>
                            <div className='txtNavBoxTitleLinckCardSteps'>Por multibanco</div>
                        </div>
                        <div className='txtNavBoxViewContainerLinckCardSteps'>
                            <div className='txtNavBoxRegularLinckCardSteps'>Associe um cartão <br /> usando um ATM</div>
                        </div>
                        <div className='arrowIconNavBoxViewContainerLinckCardSteps'>
                            <FaArrowRightLong className='arrowIconNavBoxLinckCardSteps' /> 
                        </div>
                    </button>
                    </>
                )}
            </div>           
        </div>
    </div>
    </>
  )
}

export default LinkCardStepOne;