import React from 'react';
import '../../css/noMatch.css';
import { Link, useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useTabletMediaQuery } from '../../utils';
import imgDataRespData from '../../mock/imgDataResp.json'


function NoMatch() {

    const navigate = useNavigate();
    const isTablet = useTabletMediaQuery();
    const imgNoMatch = imgDataRespData.imgData.mainImgNoMatch;

    const handlegoBackClick = () => {
        navigate(-1);
    };


  return (  
    <div className={ isTablet ? "mainViewContainerNoMatch" : "mainViewContainerNoMatchMobile"}>
        <div className={ isTablet ? 'firstTxtViewContainerNoMatch' : 'firstTxtViewContainerNoMatchMobile' }>
            <div className={ isTablet ? 'goBackBtnViewNoMatch' : 'goBackBtnViewNoMatchMobile' }>
                <button className='txtGoBackBtnNoMatch'  type='button' onClick={handlegoBackClick} >
                    <FaArrowLeftLong className='goBackIconNoMatch' /> Voltar
                </button>
            </div>
            <div className={ isTablet ? 'secondMainContainerNoMatch' : 'secondMainContainerNoMatchMobile' } >
                <div className='mainTxtViewContainerNoMatch'>
                    <div className='txtMainInfoTitleNoMatch'>ERRO 404</div>
                    <div className='txtViewContainerNoMatch'>
                        <div className={ isTablet ? 'txtMainTitleNoMatch' : 'txtMainTitleNoMatchMobile' }>Esta página está a precisar de um check-up!</div>
                        <div className='mainSubTxtViewContainerNoMatch'>
                            <span className='mainTxtNoMatch'>Não conseguimos encontrar aquilo que procura. Por favor, verifique se o endereço está escrito corretamente. Pode também </span>
                            <Link to="/" className="linkNoMatch">
                                <span className='mainTxtNavNoMatch'>voltar à Homepage</span>
                            </Link>
                            <span className='mainTxtNoMatch'>.</span>
                        </div>
                    </div>

                </div>
                <div className={ isTablet ? 'mainImgViewNoMatch' : 'mainImgViewNoMatchMobile'}>
                    <img src={imgNoMatch} alt="imagem" className={ isTablet ? 'mainImgNoMatch' : 'mainImgNoMatchMobile' } />
                </div>
            </div>
        </div>
    </div>
  )
}

export default NoMatch;