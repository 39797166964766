import React, { useState, useEffect } from 'react';
import '../../css/partnersDetails.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import CustomAlert from '../../components/CustomAlert';
import movementsDataMock from '../../mock/movementsData.json';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { GoDotFill } from "react-icons/go";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { IoGlobeOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import '../../firebaseConfig'; 
import { getDatabase, ref, get } from "firebase/database";
import GoogleMap from '../../components/GoogleMap';
import googleMapInfoSliderData from '../../mock/googleMapInfoSliderResp.json'
import ImgSportSvg from '../../assets/svg/iconSport.svg'
import ImgavatarDecatlhon from '../../assets/svg/avatarDecatlhon.svg';


function  PartnersDetails() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const availableBalance = movementsDataMock.movementsInfo.availableBalance;
    const [modalShow, setModalShow] = useState(false);
    const [swiperIndexTwo, setSwiperIndexTwo] = useState(0);
    const [swiperInstanceTwo, setSwiperInstanceTwo] = useState(null);
    const [swiperIndexOne, setSwiperIndexOne] = useState(0);
    const [swiperInstanceOne, setSwiperInstanceOne] = useState(null);
    const [adsSecondOfferResp, setAdsSecondOfferResp] = useState([]);
    const location = useLocation();
    const category = location.state?.category || ''; 
    const categoryItem = location.state?.item || ''; 
    console.log('category: ', category, 'categoryItem: ', categoryItem, 'categoryItem category: ', categoryItem.category)

    function handleClickNavList(event) {
        event.preventDefault();
    }

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const handlegoBackClick = () => {
        navigate(-1);
    };

    const handleClickShowConfigModal = () => {
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       setOpenInfoModal(true)
    };

    const handleShowInfo = () => {
        setModalShow(true)
    };

    const fetchData = async () => {
        try {
            const database = getDatabase();
            const dbRef = ref(database, '/secondOfferAds');

            const snapshot = await get(dbRef);

            if (snapshot.exists()) {
            const data = snapshot.val();
            setAdsSecondOfferResp(data);
            } else {
            console.log('No data available');
            }
        } catch (error) {
            console.error(error);
        }
    };

    const nextImageTwo = () => {
        if (swiperInstanceTwo !== null) {
            swiperInstanceTwo.slideNext();
        }
    };

    const prevImageTwo = () => {
        if (swiperInstanceTwo !== null) {
            swiperInstanceTwo.slidePrev();
        }
    };

    const handleSwiperSlideChangeTwo = (swiperTwo) => {
        setSwiperIndexTwo(swiperTwo.activeIndex);
    };

    const nextImageOne = () => {
        if (swiperInstanceOne !== null) {
            swiperInstanceOne.slideNext();
        }
    };

    const prevImageOne = () => {
        if (swiperInstanceOne !== null) {
            swiperInstanceOne.slidePrev();
        }
    };

    const handleSwiperSlideChangeOne = (swiperOne) => {
        setSwiperIndexOne(swiperOne.activeIndex);
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (  
        <>
        { isTablet &&  
        <Header/> 
        }
        {/* Start fist div componet with title / header */}
        <div className={ isTablet ? "mainViewContainerPartnersDetails" : ( isMobile ? 'mainViewContainerPartnersDetailsTablet' : 'mainViewContainerPartnersDetailsMobile')}>
            <div className={ isTablet ? 'firstTxtViewContainerPartnersDetails' : ( isMobile ? 'firstTxtViewContainerPartnersDetailsTablet' : 'firstTxtViewContainerPartnersDetailsMobile') }>
            
                <div className={ isTablet ? 'titleSaldoContainerPartnersDetailsWithSaldo' : ( isMobile ? 'titleSaldoContainerPartnersDetailsWithSaldoTablet' : 'titleSaldoContainerPartnersDetailsWithSaldoMobile')}>
                    <div className={ isTablet ? 'titleContainerPartnersDetailsWithSaldo' : ( isMobile ? 'titleContainerPartnersDetailsWithSaldoTablet' : 'titleContainerPartnersDetailsWithSaldoMobile')}>
                        <div className={ isTablet ? 'firstTitleViewPartnersDetailsWithSaldo' : ( isMobile ? 'firstTitleViewPartnersDetailsWithSaldoTablet' : 'firstTitleViewPartnersDetailsWithSaldoMobile')}>
                            { isTablet ?(
                                <div className='textTitleBoldPartnersDetailsWithSaldo'>Carteira Virtual Médis</div> ) : (
                                <>
                                <div className='textTitleRegularPartnersDetailsWithSaldoMobile'>Carteira</div>
                                <div className='textTitleBoldPartnersDetailsWithSaldoMobile'>Virtual Médis</div>
                                </>
                            )}      
                        </div>
                        <div className='firstTitleViewConfigOnfoPartnersDetailsWithSaldo'>
                            <button className='infoHeaderIonBtnPartnersDetailsWithSaldo'  type='button' onClick={handleClickShowConfigModal} >
                                <span className='txtConfigTitlePartnersDetailsWithSaldo'><IoSettingsOutline  /></span> <span className='txtConfigTitlePartnersDetailsWithSaldo'>Configurações</span>
                            </button>
                            <CustomAlert 
                                title='Configurações'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openConfigModal} 
                                onHide={() => setOpenConfigModal(false)} 
                            /> 
                            <button className='infoHeaderIonBtnPartnersDetailsWithSaldo'  type='button' onClick={handleClickShowInfo} >
                                <span className='infoIconPartnersDetailsWithSaldo'><IoIosHelpCircleOutline  /></span> <span className='txtConfigTitlePartnersDetailsWithSaldo'>Como funciona</span>
                            </button>
                            <CustomAlert 
                                title='Como Funciona'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openInfoModal} 
                                onHide={() => setOpenInfoModal(false)} 
                            />     
                        </div>
                    </div>
                    <div className={ isTablet ? 'saldoContainerPartnersDetailsWithSaldo' : ( isMobile ? 'saldoContainerPartnersDetailsWithSaldoTablet' : 'saldoContainerPartnersDetailsWithSaldoMobile')}>
                        { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                            <>
                            <div>
                                <span className={ isTablet ? 'txtRegSaldoPartnersDetailsWithSaldo' : 'txtRegSaldoPartnersDetailsWithSaldoMobile'}>O meu </span>
                                <span className={ isTablet ? 'txtBoldSaldoPartnersDetailsWithSaldo' : 'txtBoldSaldoPartnersDetailsWithSaldoMobile'}>Saldo</span>
                            </div>
                            <div className='saldoInfoContainerPartnersDetailsWithSaldo'>
                                <div className="saldoEuroIconViewPartnersDetailsWithSaldo">
                                    <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconPartnersDetailsWithSaldo" />
                                </div>
                                <div className='txtRegularSaldoPartnersDetailsWithSaldo'>{availableBalance}€</div>
                            </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={ isTablet ? 'txtNavListContainerPartnersDetails' : ( isMobile ? 'txtNavListContainerPartnersDetailsTablet' : 'txtNavListContainerPartnersDetailsMobile') } role="presentation" onClick={handleClickNavList}>
                    <Breadcrumbs aria-label="breadcrumb" className='txtNavListPartnersDetails'>
                        <Link to="/" className="linkHeaderStylePartnersDetails">
                            <div className='txtNavListPartnersDetails'>Home</div>
                        </Link>
                        <Link to="/earnBalance" className="linkHeaderStylePartnersDetails">
                            <div className='txtNavListPartnersDetails'>Ganhar saldo</div>
                        </Link>
                        <Link to="/partnersPage" className="linkHeaderStylePartnersDetails">
                            <div className='txtNavListPartnersDetails'>Ver parceiros</div>
                        </Link>
                        <Typography className='txtNavListPartnersDetails'>Decathlon</Typography>
                    </Breadcrumbs>
                </div>

                <div className={ isTablet ? 'thirdMainContainerPartnersDetails' : ( isMobile ? 'thirdMainContainerPartnersDetailsTablet' : 'thirdMainContainerPartnersDetailsMobile') }>
                    <div className={ isTablet ? 'goBackBtnViewMbWayPartnersDetails' : 'goBackBtnViewMbWayPartnersDetailsMobile' }>
                        <button className='txtGoBackBtnPartnersDetails'  type='button' onClick={handlegoBackClick} >
                            <FaArrowLeftLong className='goBackIconPartnersDetails' /> Voltar
                        </button>
                    </div>
                </div>
                {/* End fist div componet with title / header */}

                {/* Start second div componet with partner details */}
                <div className={ isTablet ? 'secondMainContainerWithInfoPartnersDetails' : ( isMobile ? 'secondMainContainerWithInfoPartnersDetailsTablet' : 'secondMainContainerWithInfoPartnersDetailsMobile') }>
                    <div className='mainRowViewContainerWithInfoPartnersDetails'>
                        <img src={ImgavatarDecatlhon} alt="imagem" className="mainIconPartnerLogoSvgPartnersDetails" />
                        <div className='columnViewContainerWithInfoPartnersDetails'>
                            <div className='txtPartnerLogoPartnersDetails'>Decathlon</div> 
                            <div className='rowViewContainerWithInfoPartnersDetails'>
                                <img src={ImgSportSvg} alt="imagem" className="iconPartnerCategorySvgPartnersDetails" />
                                <div className='txtPartnerCategoryPartnersDetails'>Desporto</div>
                            </div>
                        </div>
                    </div>
                    <div className={isTablet ? 'secondSliderAdsContainerPartnersDetails' : ( isMobile ? 'secondSliderAdsContainerPartnersDetailsTablet' : 'secondSliderAdsContainerPartnersDetailsMobile')}>
                        <Swiper
                            onSlideChange={(swiperTwo) => handleSwiperSlideChangeTwo(swiperTwo)}
                            onSwiper={(swiperTwo) => setSwiperInstanceTwo(swiperTwo)}
                            slidesPerView={isTablet ? adsSecondOfferResp.length > 1 ? 2 : 1 : 1}
                            spaceBetween={32}
                            // pagination={{
                            //     clickable: true,
                            //   }}
                            modules={[Pagination]}
                            className={isTablet ? "mySwiperPartnersDetails" : ( isMobile ? "mySwiperPartnersDetailsTablet" : "mySwiperPartnersDetailsMobile")}
                        >
                            {adsSecondOfferResp.map((ad, index) => (
                                <SwiperSlide key={index}>
                                    <div className={isTablet ? 'secondSliderAdsOfertaPartnersDetails' : 'secondSliderAdsOfertaPartnersDetailsMobile'}>

                                        <div className='txtTitleBoldAdsOfferPartnersDetails'><b>{ad.title}</b></div>
                                        <div className='txtTitleAdsOfferPartnersDetails'>{ad.storeName}</div>
                                        <div className='txtTitleAdsOfferPartnersDetails'>{ad.description}</div>
                                        <div className='txtTitleRowViewAdsOfferPartnersDetails'>
                                            <div className='txtCashbackAdsOfferPartnersDetails'>
                                                <div className='iconTitleAdsOfferPartnersDetails'><GoDotFill /></div>
                                                <div className='txtTitleAdsOfferPartnersDetails'>{ad.cashback}</div>
                                                <button className='infoHeaderIonBtnAdsOfferPartnersDetails'  type='button' onClick={handleShowInfo} >
                                                    <IoMdInformationCircleOutline className='infoHeaderIcondsOfferPartnersDetails' />
                                                </button>
                                            </div>
                                            <div className='txtCashbackAmountAdsOfferPartnersDetails'>
                                                <div className='txtTitleBoldAdsOfferPartnersDetails'>{ad.cashbackAmount}</div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <CustomAlert 
                            title='Tem dúvidas?'
                            text='Contacte-nos pelo xxxx@medis.pt ou pela Linha Médis: 218 458 888 (dias úteis das 8h00 às 20h00)'
                            type='info'
                            show={modalShow} 
                            onHide={() => setModalShow(false)} 
                        /> 
                        <div className={isTablet ? 'thirdSubMainContainerCircleNavSeePartners' : 'thirdSubMainContainerSeePartnersMobile'}>
                            <button className='iconArrowLeftViewContainerCircleNavSeePartners' onClick={prevImageTwo} disabled={swiperIndexTwo == 0}>
                                <FaArrowLeftLong className='iconArrowCircleNavSeePartners' />
                            </button>

                            <div className='cirlceSelectedNavSeePartners'>
                                <div className='circleMainvViewNavSeePartners'>
                                    <div className='circleContainerNavSeePartners'>
                                        <div className={swiperIndexTwo == 0 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                                    </div>
                                    <div className='circleContainerNavSeePartners'>
                                        <div className={swiperIndexTwo == 1 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                                    </div>
                                    <div className='circleContainerNavSeePartners'>
                                        <div className={swiperIndexTwo >= 2 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                                    </div>
                                </div>
                            </div>

                            <button className='iconArrowRightViewContainerCircleNavSeePartners' onClick={nextImageTwo} disabled={swiperIndexTwo >= adsSecondOfferResp.length - 2}>
                                <FaArrowRightLong className='iconArrowCircleNavSeePartners' />
                            </button>
                        </div>
                    </div>
                </div>
                
                {/* End second div componet with partner details */}

                {/* Start tirhd div componet with partner contact info */}
                { isTablet && (
                    <div className={ isTablet ? 'secondMainContainerWithhPartnerContactDataPartnersDetails' : 'secondMainContainerWithhPartnerContactDataPartnersDetailsMobile' }>
                        <div className='secondSmallContainerWithhPartnerContactDataPartnersDetails'>
                            <div className='txtPartnerContactInfoBoldPartnersDetails' style={{ marginRight: '16px' }}>Contactos</div>
                            <div className='columnViewContainerWithInfoPartnersDetails'>
                                <div className='rowViewContainerWithPartnerContactDataPartnersDetails'>
                                    <div className='iconPartnerContactDataPartnersDetails'><MdOutlinePhoneInTalk /></div>
                                    <div className='txtPartnerContactDataPartnersDetails'>210 025 200</div>
                                </div>
                                <div className='rowViewContainerWithPartnerContactDataPartnersDetails'>
                                    <div className='iconPartnerContactDataPartnersDetails'><MdOutlineAlternateEmail /></div>
                                    <div className='txtPartnerContactDataPartnersDetails'>email@email.com</div>
                                </div>
                            </div>
                            <div className='rowViewContainerWithPartnerContactDataPartnersDetails'>
                                <div className='iconPartnerContactDataPartnersDetails'><IoGlobeOutline /></div>
                                <div className='txtPartnerContactDataPartnersDetails'>www.decathlon.pt</div>
                            </div>
                        </div>

                        <div className='secondSmallContainerWithhPartnerContactDataBorderPartnersDetails'>
                            <div className='txtPartnerContactInfoBoldPartnersDetails' style={{ marginRight: '16px' }}>Compras</div>
                            <div className='txtPartnerContactInfoBlakPartnersDetails'>Loja física e loja online</div>
                        </div>
                    </div>
                )}
                {/* End tirhd div componet with partner contact info */}

                {/* Start firth div componet with partner google maps */}

                <div className={ isTablet ? 'mainGoogleMapViewContainerPartnersDetails' : 'mainGoogleMapViewContainerPartnersDetailsMobile'}>
                    <GoogleMap/>
                </div>

                {/* End firth div componet with partner google maps */}

                {/* Start bottom div componet with info steps */}
                <div className='mainBottomViewContainerPartnersDetails'>
                    <div className='titileViewForBottomViewContainerPartnersDetails'>
                        <div className={ isTablet ? 'textTitleBoldPartnersDetailsWithSaldo' : 'textTitleRegularPartnersDetailsWithSaldoMobile'}>Como funciona?</div>
                        <div className='txtPartnerContactInfoBlakPartnersDetails'>Faça compras e acumule dinheiro. <br/>Receba de volta uma percentagem do valor que gastou.</div>
                    </div>

                    <div className={isTablet ? 'secondSliderAdsContainerPartnersDetails' : ( isMobile ? 'secondSliderAdsContainerPartnersDetailsTablet' : 'secondSliderAdsContainerPartnersDetailsMobile')}>
                        <Swiper
                            onSlideChange={(swiperOne) => handleSwiperSlideChangeOne(swiperOne)}
                            onSwiper={(swiperOne) => setSwiperInstanceOne(swiperOne)}
                            slidesPerView={ isTablet ? 3 : 1}
                            spaceBetween={32}
                            // pagination={{
                            //     clickable: true,
                            //   }}
                            modules={[Pagination]}
                            className={isTablet ? "mySwiperBoxViewContainerPartnersDetails" : ( isMobile ? "mySwiperBoxViewContainerPartnersDetailsTablet" : "mySwiperBoxViewContainerPartnersDetailsMobile")}
                        >
                            {googleMapInfoSliderData.googleMapInfoSlider.map((item, index) => (
                                <SwiperSlide key={index}>
                                    <div className={ isTablet ? 'boxViewContainerBottomViewContainerPartnersDetails' : ( isMobile ? 'boxViewContainerBottomViewContainerPartnersDetailsTablet' : 'boxViewContainerBottomViewContainerPartnersDetailsMobile')}>
                                        <img src={item.iconUrl} alt="imagem" className="iconPartnerLogoSvgPartnersDetails" />
                                        <div className='titleContainerPartnersDetailsWithSaldo'>
                                            <div className='txtTitleBoldAdsOfferPartnersDetails'>{item.title}</div>
                                            <div className='txtPartnerInfoPartnersDetails'>{item.description}</div>
                                            <Link to={item.descriptionHyperlink.hyperlinkUrl} className="linkHeaderStylePartnersDetails">
                                                <div className='txtPartnerContactDataPartnersDetails' style={{ borderBottom: '1px solid #0A806C' }}>{item.descriptionHyperlink.description}</div>
                                            </Link>
                                        </div>
                                        <div className='arrowBtnViewPartnerContactDataPartnersDetails'>
                                            <div className='txtArrowBtnPartnerContactDataPartnersDetails'><FaArrowRightLong/></div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                    <div className={isTablet ? 'thirdSubMainContainerCircleNavSeePartners' : 'thirdSubMainContainerSeePartnersMobile'}>
                        <button className='iconArrowLeftViewContainerCircleNavSeePartners' onClick={prevImageOne} disabled={swiperIndexOne == 0}>
                            <FaArrowLeftLong className='iconArrowCircleNavSeePartners' />
                        </button>

                        <div className='cirlceSelectedNavSeePartners'>
                            <div className='circleMainvViewNavSeePartners'>
                                <div className='circleContainerNavSeePartners'>
                                    <div className={swiperIndexOne == 0 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                                </div>
                                <div className='circleContainerNavSeePartners'>
                                    <div className={swiperIndexOne == 1 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                                </div>
                                <div className='circleContainerNavSeePartners'>
                                    <div className={swiperIndexOne >= 2 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                                </div>
                            </div>
                        </div>

                        <button className='iconArrowRightViewContainerCircleNavSeePartners' onClick={nextImageOne} disabled={swiperIndexOne >= googleMapInfoSliderData.googleMapInfoSlider.length - 3}>
                            <FaArrowRightLong className='iconArrowCircleNavSeePartners' />
                        </button>
                    </div>
                </div>
                {/* End bottom div componet with info steps */}

            </div>
        </div>
        </>
    )
}

export default  PartnersDetails;