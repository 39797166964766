import React, { useState, useEffect } from 'react';
import '../../css/onboardingReferalSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import imgDataRespData from '../../mock/imgDataResp.json'
import CustomButton from '../../components/CustomButton';

function OnboardingReferalStepTwo() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const imgStepTwo = imgDataRespData.imgData.onboardingReferalStepTwoSvg;

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const hadleBtnClickAddLater = () => {
        navigate('/');
    };

    const hadleBtnClickAddNow = () => {
        navigate('/onboardingLinkCardStepOne');
    };

  return ( 
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? 'mainViewContainerReferalSteps' : ( isMobile ? 'mainViewContainerReferalStepsTablet' : 'mainViewContainerReferalStepsMobile')}>
        { !isTablet && 
            <>
            <div className={ isMobile ? 'mainImgViewStepsStackTablet' : 'mainImgViewReferalStepsMobile' }>
                <img src={imgStepTwo} alt="imagem" className={ isMobile ?  "mainImgTwoReferalStackTablet" : "mainImgTwoReferalStackMobile" }/>
            </div>
            </>
        }
        <div className={  isTablet ? 'firstTxtViewContainerReferalSteps' : ( isMobile ? 'firstTxtViewContainerReferalStepsTablet' : 'firstTxtViewContainerReferalStepsMobile') }>
            <div className='firstTitleViewReferalSteps'>
                <div className={  isTablet ? 'textTitlePart1ReferalSteps' : ( isMobile ? 'textTitlePart1ReferalStepsTablet' : 'textTitlePart1ReferalStepsMobile') }>Sucesso!</div>
                <span className={  isTablet ? 'textTitlePart1ReferalSteps' : ( isMobile ? 'textTitlePart1ReferalStepsTablet' : 'textTitlePart1ReferalStepsMobile') }>A sua </span>
                <span className={  isTablet ? 'textTitlePart2ReferalSteps' : ( isMobile ? 'textTitlePart2ReferalStepsTablet' : 'textTitlePart2ReferalStepsMobile') }>Carteira Virtual já está ativa.</span>   
            </div>
            <div className={ isTablet ? 'secondTitleViewReferalSteps' : ( isMobile ? 'secondTitleViewReferalStepsTablet' : 'secondTitleViewReferalStepsMobile') }>Associe agora o seu cartão bancário e comece já a acumular saldo.</div>
            <div className={ isTablet ? 'btnsViewReferalSteps' : ( isMobile ? 'btnsViewReferalStepsTablet' : 'btnsViewReferalStepsMobile' )}>
                <div className={ isTablet ? 'divBtnSaltarReferalSteps' : ( isMobile ? 'divBtnSaltarReferalStepsStackTablet' : 'divBtnSaltarReferalStepsMobile' )}>
                    <CustomButton 
                        text={'Associar mais tarde'} 
                        textColor={'#00214D'}
                        onPress={hadleBtnClickAddLater} 
                        fontSize={'14px'}
                        hasSubLine
                        hasSubLineSmall
                        hasSubLineSmallColor={'#00214D'}
                    />
                </div> 
                <div className={ isTablet ? 'divBtnNextReferalSteps' : ( isMobile ? 'divBtnSaltarReferalStepsStackTablet' : 'divBtnNextReferalStepsMobile' )}>
                    <CustomButton 
                        text={'Associar cartão'} 
                        textColor={'#00214D'}
                        onPress={hadleBtnClickAddNow} 
                        color={'#15D4B6'}
                        hasArrow
                        iconColor={'#00214D'}
                    />
                </div>    
            </div>   
        </div>
        { isTablet && 
        <>
        <div className='firstImgViewContainerReferalSteps'>
            <div className='mainImgViewReferalSteps'>
                <img src={imgStepTwo} alt="imagem" className="mainImgReferalSteps" />
            </div>
        </div>
        </>
        }
    </div>
    </>
  )
}

export default OnboardingReferalStepTwo;