import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    hasAccess: true,
    isAcceptUserTerms: true,
    isLinkCard: false,
    isRegistered: false,
  };

const onboardingUserDataSlice = createSlice({
  name: 'onboardingUserData',
  initialState,
  reducers: {
    acceptUserTerms(state) {
      state.isAcceptUserTerms = true;
    },
    setLinkCard(state, action) {
      state.isLinkCard = action.payload;
    },
    setRegistered(state, action) {
      state.isRegistered = action.payload;
    },
    setHasAccess(state, action) {
      state.hasAccess = action.payload;
    },
  },
});

export const { acceptUserTerms, setLinkCard, setRegistered, setHasAccess } = onboardingUserDataSlice.actions;
export default onboardingUserDataSlice.reducer;
