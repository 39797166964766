import React, { useState, useEffect } from 'react';
import '../../css/activateOffers.css';
import { FaCheck } from "react-icons/fa6";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import DotsLoading from '../../components/DotsLoading';
import imgDataRespData from '../../mock/imgDataResp.json'
import { getDatabase, ref, get, set } from "firebase/database";
import { toast } from "react-toastify";
import { AiOutlineStop } from "react-icons/ai";

function ActivateOffers() {

    const [isLoading, setIsLoading] = useState(true);
    const [offerCode, setOfferCode] = useState('');
    const [isStartingLoadingDots, setIsStartingLoadingDots] = useState(false)
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const imgActivateOffersSvg = imgDataRespData.imgData.onboardingReferalStepOneSvg;
    const [isValidReferral, setIsValidReferral] = useState(true); 

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const fetchData = async () => {
        try {
            setIsStartingLoadingDots(true);
            const database = getDatabase();
            //await set(ref(database, '/confirmOnboardingCode/success'), true);
            const dbRef = ref(database, '/confirmOnboardingCode');
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const data = snapshot.val();
                if (data.referralCode === offerCode) {
                    setIsValidReferral(true);
                    toast.success("Código adicionado! O valor fica disponível depois de associar um cartão à Carteira Virtual.");
                    setIsStartingLoadingDots(false);
                    setOfferCode('')
                } else {
                    setIsValidReferral(false);
                    //toast.error("Lamentamos, mas neste momento, não conseguimos validar o seu código. Por favor, tente mais tarde.");
                    setIsStartingLoadingDots(false);
                }
            } else {
                console.log('No data available');
                setIsStartingLoadingDots(false);
            }
        } catch (error) {
            console.error(error);
            setIsStartingLoadingDots(false);
        }
    };

    const hadleBtnClickCheck = async () => {
        await fetchData();
    };

    const inputClassName = isTablet ? 
    (isValidReferral ? 'inputTextActivateOffers' : 'inputTextActivateOffersInvalid') : 
    (isValidReferral ? 'inputTextActivateOffersMobile' : 'inputTextActivateOffersInvalidMobile');

    return (
        <>
        { !isTablet && (
            <>
            <div className={ isMobile ? 'mainImgViewActivateOffersTablet' : 'mainImgViewActivateOffersMobile'}>
                <img src={imgActivateOffersSvg} alt="imagem" className={ isMobile ? "mainImgActivateOffersTablet" : "mainImgActivateOffersMobile"} />
            </div>
            </>
        )}
        <div className={ isTablet ? 'secondMainContainerActivateOffers' : ( isMobile ? 'secondMainContainerActivateOffersTablet' : 'secondMainContainerActivateOffersMobile') }>
            <div className={ isTablet ? 'firstSubMainContainerActivateOffers'  : ( isMobile ? 'firstSubMainContainerActivateOffersTablet' : 'firstSubMainContainerActivateOffersMobile') }>
                <div className='secondTitleViewActivateOffers'>
                    <span><b className={ isTablet ? 'textTitleBold700ActivateOffers' : 'textTitleBold700ActivateOffersMobile' }>Transforme o código </b></span>
                    <span className={ isTablet ? 'textTitleRegular400ActivateOffers' : 'textTitleRegular400ActivateOffersMobile' }>em saldo</span>      
                </div>
                <div className='txtSecondTitleViewActivateOffers'>
                    <div className='textSecondTitleViewActivateOffers'>Insira aqui o código da campanha ou promoção que recebeu e acumule saldo na Carteira Virtual.</div>
                </div>
                <div className={ isTablet ? 'textFormViewActivateOffers' : 'textFormViewActivateOffersMobile' }>
                    <div className={ isTablet ? 'textWrapperActivateOffers' : 'textWrapperActivateOffersMobile' }>
                        { offerCode.length > 0 &&
                        <label className='label-focus'>Insira aqui o seu código</label>
                        }
                        <input 
                            className={inputClassName}
                            type="text" 
                            placeholder='Ex.: MEDIS24'
                            value={offerCode}
                            onChange={(event) => setOfferCode(event.target.value)}
                            maxLength={30}
                                                        
                        />
                    </div>
                    { !isValidReferral &&
                        <div className='txtViewErrorInfoActivateOffers'>
                            <div className='txtErrorInfoActivateOffers'><AiOutlineStop className='iconErrorInfoActivateOffers'/> Lamentamos, mas neste momento, não conseguimos validar o seu código. Por favor, tente mais tarde.</div>
                        </div>
                    } 
                </div>
                <div className={ isTablet ? 'btnViewActivateOffers' : 'btnViewActivateOffersMobile' }>
                    <button 
                        className='btnNextActivateOffers'
                        type='button' 
                        onClick={hadleBtnClickCheck}
                        disabled={offerCode.length <= 0}
                    >
                    { isStartingLoadingDots ? <DotsLoading /> : (
                        <>
                        Validar <FaCheck className='checkedIconActivateOffers'/>
                        </>
                    )}                          
                    </button>
                </div> 
            </div>
            { isTablet && (
                <>
                <div className='mainImgViewActivateOffers'>
                    <img src={imgActivateOffersSvg} alt="imagem" className="mainImgActivateOffers" />
                </div>
                </>
            )}
        </div>   
        </>       
    )
}

export default ActivateOffers