import React, { useEffect, useState } from "react";
import '../css/customModalMovementsInfo.css'
import Modal from 'react-bootstrap/Modal';
import CustomButton from "./CustomButton";
import { IoSearch } from "react-icons/io5";
import Form from 'react-bootstrap/Form';
import { IoMdClose } from "react-icons/io";
import { RiMapPinLine } from "react-icons/ri";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import portugalLocationsData  from "../data/portugal.json";


const CustomFilterModal = (props) => {
    const {
        title = '',
        seacrhForNamePartner = '',
        setSeacrhForNamePartner = '',
        partnersListMock,
        seacrhForPlacePartner = '',
        setSeacrhForPlacePartner = '',
        locationDistance,
        marks,
        min,
        max,
        setLocationDistance,
        theme,
        countStore,
        isStore,
        countSport,
        isSport,
        countBeautyStore,
        isBeautyStore,
        countEducation,
        isEducation,
        countCultureLeisure,
        isCultureLeisure,
        countTech,
        isTech,
        countModaHome,
        isModaHome,
        countAnimals,
        isAnimals,
        countCarsTransp,
        isCarsTransp,
        countRestaurant,
        isRestaurant,
        countOther,
        isOther,
        countStoreOnline,
        isStoreOnline,
        countStorePhysical,
        isStorePhysical,
        countNew,
        isNew,

        selectedItem,
        setSelectedItem,
        selectedArrayItems,
        filters,
    } = props;

    const [openSelectCityModal, setOpenSelectCityModal] = useState(false);
    const [openSelectSecCityModal, setOpenSelectSecCityModal] = useState(false);
    const [selectedSeacrhForPlacePartner, setSelectedSeacrhForPlacePartner] = useState(false);

    
    
    const handleClose = () => setOpenSelectCityModal(false);
    const handleShow = () => setOpenSelectCityModal(true);

    const handleCloseThird = () => setOpenSelectSecCityModal(false);
    const handleShowThird = (item) => {
        setOpenSelectSecCityModal(true);
        setSelectedItem(item);
    }

    const handleMainClose = () => {
        handleClose();
        props.onHide();
        handleCloseThird();
    };

    const handleSeacrhForPlacePartner = (item) => {
        setSeacrhForPlacePartner(item.name)
        setSelectedSeacrhForPlacePartner(true);
    }
    

    const handleInputSeacrhForPlaceChange = (event) => {
        const newValue = event.target.value;
        setSeacrhForPlacePartner(newValue);
        if (newValue.length === 0) {
            setSelectedSeacrhForPlacePartner(false);
        }
    };

    const handleSelectOneOFTheLastThree = (item) => {
        alert(item.name)
    }

    return (
        <Modal
            {...props}
            size="lg-down"
            fullscreen
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modalMainContainerPartnersPageMobile"
        >
            <div className='mainOptionsColumnViewContainerModalPartnersPageMobile'>
                <div className='txtFirstOptionsTitleViewRowPartnersPageMobile'>
                    <div className='txtFirstOptionsTitlePartnersPage'>Filtrar</div>
                    <button className='btnFirstOptionsTitlePartnersPage' onClick={props.onHide}><IoMdClose className="closeIconPartnersPage"/></button>
                </div>
                <div className='optionsDeviderLinePartnersPage'></div>
                <div className='txtFirstOptionsTitleViewPartnersPage'>
                    <div style={{ marginBottom: '24px'}}>
                        <div className='txtFirstOptionsTitlePartnersPage'>Por localização</div>
                    </div>
                    <button className='btnSecModalWothLocationPartnersPageMobile' onClick={handleShow}>
                        <div  className='txtLocBtnPartnersPage'><RiMapPinLine className='locIconPartnersPage' /> Localização</div>
                        <FaArrowRightLong className='navIconPartnersPage'/>
                    </button>
                    <Modal 
                        show={openSelectCityModal} 
                        size="lg-down"
                        fullscreen
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        className="modalMainContainerPartnersPageMobile"
                        >
                            <div className='mainOptionsColumnViewContainerModalPartnersPageMobile'>
                                <div className='txtFirstOptionsTitleViewRowPartnersPageMobile'>
                                    <div className='titleSecondModalViewRowPartnersPageMobile'>
                                        <button className="btnFirstOptionsTitlePartnersPage" onClick={handleClose}><FaArrowLeftLong className='navIconPartnersPage'/></button>
                                        <div className='txtFirstOptionsTitlePartnersPage'>Localização</div>
                                    </div>
                                    <button className='btnFirstOptionsTitlePartnersPage' onClick={handleMainClose}><IoMdClose className="navIconPartnersPage"/></button>
                                </div>
                                <div className='optionsDeviderLinePartnersPage'></div> 
                               
                                <div className='textWrapperPartnersPage'>
                                    <input 
                                        className='inputTextPartnersPage'
                                        type="text" 
                                        placeholder='Código postal ou localidade'
                                        value={seacrhForPlacePartner}
                                        onChange={handleInputSeacrhForPlaceChange}
                                        //maxLength={15}
                                    />
                                    <div className='findIconViewPartnersPage' onClick={props.handleClickShowInfo}><IoSearch className='findIconPartnersPage' /></div>
                                </div>
                                { (seacrhForPlacePartner.length > 0 && !selectedSeacrhForPlacePartner )  && (
                                <>
                                    <div className='textWrapperCollumnPartnersPage' style={{marginTop: '-16px'}}>
                                        {portugalLocationsData.portugalLocations.map((item, index)=>(
                                            <div key={index} className="searchPosCodListViewPartnersPage">
                                                <button  className='txtSelectPlaceBtnPartnersPage' onClick={() => handleSeacrhForPlacePartner(item)}>{item.name}</button>
                                            </div>
                                        ))}
                                    </div>
                                </>
                                )}
                                <div className='optionsDeviderLinePartnersPage'></div>
                                <div className='txtFirstOptionsTitleViewPartnersPage'>
                                    <div style={{ marginBottom: '24px'}}>
                                        <div className='txtFirstOptionsTitlePartnersPage'>Distritos</div>
                                    </div>
                                    {portugalLocationsData.portugalLocations.slice(0, 3).map((item, index)=>(
                                    <div key={index} style={{ marginTop: '16px' }}>
                                        <button className='btnThreeLastSelectedPartnersPageMobile' onClick={() => handleSelectOneOFTheLastThree(item)}>
                                            <div  className='txtThreeLastSelectedPartnersPageMobile'>{item.name}</div>
                                        </button>
                                    </div>
                                    ))}
                                </div>
                                
                                <div className='optionsDeviderLinePartnersPage'></div>
                                <div className='txtFirstOptionsTitleViewPartnersPage'>
                                    <div style={{ marginBottom: '24px'}}>
                                        <div className='txtFirstOptionsTitlePartnersPage'>Distritos</div>
                                    </div>
                                    {portugalLocationsData.portugalLocations.map((item, index)=>(
                                    <div key={index} style={{ marginBottom: '32px' }}>
                                        <button className='btnSecModalWothLocationPartnersPageMobile' onClick={() => handleShowThird(item)}>
                                            <div  className='txtDistBtnPartnersPage'>{item.name}</div>
                                            <FaArrowRightLong className='navIconPartnersPage'/>
                                        </button>
                                    </div>
                                    ))}
                                    <div className='optionsDeviderLinePartnersPage'></div>
                                    <div className='fotterMobalBtnViewPartnersPage' style={{height: '150px', paddingTop: '32px'}}>
                                        <CustomButton 
                                            text={'Limpar tudo'} 
                                            textColor={'#00214D'}
                                            onPress={props.handleClearFilter} 
                                            color={'#fff'}
                                            borderColor={'#00214D'}
                                        />
                                        <CustomButton 
                                            text={'Aplicar filtros'} 
                                            color={'#15D4B6'}
                                            textColor={'#00214D'}
                                            onPress={props.onHide}
                                        />
                                    </div>
                                    <Modal 
                                        show={openSelectSecCityModal} 
                                        size="lg-down"
                                        fullscreen
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                        className="modalMainContainerPartnersPageMobile"
                                        >
                                            <div className='mainOptionsColumnViewContainerModalPartnersPageMobile'>
                                                <div className='txtFirstOptionsTitleViewRowPartnersPageMobile'>
                                                    <div className='titleSecondModalViewRowPartnersPageMobile'>
                                                        <button className="btnFirstOptionsTitlePartnersPage" onClick={handleCloseThird}><FaArrowLeftLong className='navIconPartnersPage'/></button>
                                                        <div className='txtFirstOptionsTitlePartnersPage'>Localização</div>
                                                    </div>
                                                    <button className='btnFirstOptionsTitlePartnersPage' onClick={handleMainClose}><IoMdClose className="navIconPartnersPage"/></button>
                                                </div>
                                                <div className='optionsDeviderLinePartnersPage'></div> 
                                                <div className='txtFirstOptionsTitleViewPartnersPage'>
                                                    <div style={{ marginBottom: '24px'}}>
                                                        <div className='txtFirstOptionsTitlePartnersPage'>Concelho</div>
                                                    </div>
                                                    {selectedItem?.conselhos.map((item, index)=>(
                                                        <Form key={index}>
                                                            <div key={item.id} style={{ marginBottom: '32px' }} className='firstOptionsFormChekedViewPartnersPage'>
                                                                <Form.Check 
                                                                    type="checkbox" 
                                                                    label={`${item.name} (${countStorePhysical})`}
                                                                    checked={props.isCheckedConselhos(item)}
                                                                    onChange={(event) => props.handleCheckboxChange(event, item)}
                                                                />
                                                            </div>
                                                        </Form>
                                                    ))}
                                                    <div className='optionsDeviderLinePartnersPage'></div>
                                                    <div className='fotterMobalBtnViewPartnersPage' style={{height: '150px', paddingTop: '32px'}}>
                                                        <CustomButton 
                                                            text={'Limpar tudo'} 
                                                            textColor={'#00214D'}
                                                            onPress={props.handleClearFilter} 
                                                            color={'#fff'}
                                                            borderColor={'#00214D'}
                                                        />
                                                        <CustomButton 
                                                            text={'Aplicar filtros'} 
                                                            color={'#15D4B6'}
                                                            textColor={'#00214D'}
                                                            onPress={props.onHide}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                    </Modal>  
                                </div>
                            </div>
                    </Modal>
                </div>                        
                <div className='optionsDeviderLinePartnersPage'></div>
                <div className='txtFirstOptionsTitleViewPartnersPage'>
                    <div style={{ marginBottom: '24px'}}>
                        <div className='txtFirstOptionsTitlePartnersPage'>Por categoria</div>
                    </div>
                    <Form>
                        <div className='firstOptionsFormChekedViewPartnersPage'>
                            <Form.Check 
                                type="checkbox" 
                                label={`Supermercados (${countStore})`}
                                checked={isStore}
                                onChange={props.handleCheckboxChangeOne}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Desporto (${countSport})`}
                                checked={isSport}
                                onChange={props.handleCheckboxChangeTwo}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Saúde e Beleza (${countBeautyStore})`}
                                checked={isBeautyStore}
                                onChange={props.handleCheckboxChangeThree}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Educação (${countEducation})`}
                                checked={isEducation}
                                onChange={props.handleCheckboxChangeFour}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Cultura e Lazer (${countCultureLeisure})`}
                                checked={isCultureLeisure}
                                onChange={props.handleCheckboxChangeFive}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Tecnologia (${countTech})`}
                                checked={isTech}
                                onChange={props.handleCheckboxChangeSix}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Moda e Casa (${countModaHome})`}
                                checked={isModaHome}
                                onChange={props.handleCheckboxChangeSeven}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Animais (${countAnimals})`}
                                checked={isAnimals}
                                onChange={props.handleCheckboxChangeEight}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Veículos e Transportes (${countCarsTransp})`}
                                checked={isCarsTransp}
                                onChange={props.handleCheckboxChangeNine}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Restauração (${countRestaurant})`}
                                checked={isRestaurant}
                                onChange={props.handleCheckboxChangeEleven}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Outros (${countOther})`}
                                checked={isOther}
                                onChange={props.handleCheckboxChangeTen}
                            />
                        </div>
                    </Form>
                </div>
                <div className='optionsDeviderLinePartnersPage'></div>
                <div className='txtFirstOptionsTitleViewPartnersPage'>
                    <div style={{ marginBottom: '24px'}}>
                        <div className='txtFirstOptionsTitlePartnersPage'>Opção de compra</div>
                    </div>
                    <Form>
                        <div className='firstOptionsFormChekedViewPartnersPage'>
                            <Form.Check 
                                type="checkbox" 
                                label={`Loja física (${countStoreOnline})`}
                                checked={isStoreOnline}
                                onChange={props.handleCheckboxChangeStoreOnline}
                            />
                            <Form.Check 
                                type="checkbox" 
                                label={`Loja online (${countStorePhysical})`}
                                checked={isStorePhysical}
                                onChange={props.handleCheckboxChangeStorePhysical}
                            />
                        </div>
                    </Form>
                </div>
                <div className='optionsDeviderLinePartnersPage'></div>
                <div className='txtFirstOptionsTitleViewPartnersPage'>
                    <div style={{ marginBottom: '24px'}}>
                        <div className='txtFirstOptionsTitlePartnersPage'>Novas parcerias</div>
                    </div>
                    <Form>
                        <div className='firstOptionsFormChekedViewPartnersPage'>
                            <Form.Check 
                                type="checkbox" 
                                label={`Mostrar apenas novidades (${countNew})`}
                                checked={isNew}
                                onChange={props.handleCheckboxChangeNew}
                            />
                        </div>
                    </Form>
                </div>
                <div className='optionsDeviderLinePartnersPage'></div>
                <div className='fotterMobalBtnViewPartnersPage'>
                    <CustomButton 
                        text={'Limpar tudo'} 
                        textColor={'#00214D'}
                        onPress={props.handleClearFilter} 
                        color={'#fff'}
                        borderColor={'#00214D'}
                    />
                    <CustomButton 
                        text={'Aplicar filtros'} 
                        color={'#15D4B6'}
                        textColor={'#00214D'}
                        onPress={props.onHide}
                    />
                </div>
            </div>            
        </Modal>
    );
};

export default CustomFilterModal;