// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modalMainContainer {
    background-color: transparent;
}

.modal-content {
    background: #fff;
    border-radius: 40px;
    height: 100%;
    width: 100%;
    padding: 16px;
}

.modalTitle {
    align-self: center;
    margin-bottom: 48px;
}

.modalIcon {
    font-size: 80px;
    width: 80px;
    height: 80px;
    font-family: 'Ubuntu', sans-serif;
    word-wrap: break-word;
}

.contentView {
    align-self: center;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.titleView {
    color: #00214D;
    font-size: 32px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 700;
    line-height: 48px;
    word-wrap: break-word;
}

.textView {
    color: #00214D;
    font-size: 24px;
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    line-height: 36px;
    word-wrap: break-word;
}

.titleViewContainer {
    text-align: center;
    margin-bottom: 16px;
}

.textViewContainer {
    text-align: center;
    margin-bottom: 30px;
}

.btnView {
    display: flex;
    text-align: right;
    align-self: flex-end;
    padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/css/customAlert.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,YAAY;IACZ,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,iCAAiC;IACjC,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iCAAiC;IACjC,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,iCAAiC;IACjC,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,oBAAoB;IACpB,aAAa;AACjB","sourcesContent":[".modalMainContainer {\n    background-color: transparent;\n}\n\n.modal-content {\n    background: #fff;\n    border-radius: 40px;\n    height: 100%;\n    width: 100%;\n    padding: 16px;\n}\n\n.modalTitle {\n    align-self: center;\n    margin-bottom: 48px;\n}\n\n.modalIcon {\n    font-size: 80px;\n    width: 80px;\n    height: 80px;\n    font-family: 'Ubuntu', sans-serif;\n    word-wrap: break-word;\n}\n\n.contentView {\n    align-self: center;\n    align-items: center;\n    align-content: center;\n    justify-content: center;\n}\n\n.titleView {\n    color: #00214D;\n    font-size: 32px;\n    font-family: 'Ubuntu', sans-serif;\n    font-weight: 700;\n    line-height: 48px;\n    word-wrap: break-word;\n}\n\n.textView {\n    color: #00214D;\n    font-size: 24px;\n    font-family: 'Ubuntu', sans-serif;\n    font-weight: 500;\n    line-height: 36px;\n    word-wrap: break-word;\n}\n\n.titleViewContainer {\n    text-align: center;\n    margin-bottom: 16px;\n}\n\n.textViewContainer {\n    text-align: center;\n    margin-bottom: 30px;\n}\n\n.btnView {\n    display: flex;\n    text-align: right;\n    align-self: flex-end;\n    padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
