import React, { useState, useEffect, useRef } from 'react';
import '../../css/login.css';
import PlaceholderLoading from 'react-placeholder-loading';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../components/CustomButton';
import CustomAlert from '../../components/CustomAlert';
import { Link, useNavigate } from 'react-router-dom';
import { setRegistered } from '../../features/onboardingData/onboardingUserData';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../../authConfig';
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { callMsGraph } from '../../utils/MsGraphApiCall';
import GifIconLoading from '../../assets/gif/mainLoading.gif';
import { useMediaQuery } from 'react-responsive';

function Login() {

    const isRegistered = useSelector((state) => state.onboardingUserData.isRegistered);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isStartingLoadingDots, setIsStartingLoadingDots] = useState(false)
    const navigate = useNavigate();
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null)
    const { instance } = useMsal();
    const [displayData, setDisplayData] = useState(null);
    const isMobile = useMediaQuery({ query: '(min-width: 767px)' })


    const { result, error } = useMsalAuthentication(InteractionType.Redirect, {
        scopes: ["user.read"],
    });

    useEffect(() => {
        if (!displayData) {
          return;
        }
        if (error) {
          console.log(error);
          return;
        }
        if (result) {
          const accessToken = result.accessToken;
          callMsGraph(accessToken)
            .then((response) => setDisplayData(response))
            .catch((error) => console.log(error));
        }
    }, [displayData, error, result]);

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const hadleBtnClickLogin = () => {
        if ('medis@via.pt' && password === '123333') {
            //instance.loginRedirect({ scopes: ["user.read"] });
            dispatch(setRegistered(true));
          } else {
            setOpenConfigModal(true)
          }
    };

    const handleLogin = async () => {
        if (email === 'medis@via.pt' && password === '123333') {
            try {
                const response = await instance.loginRedirect(loginRequest);
                console.log(response); 
              } 
              catch (error) {
                console.log(error);
              }
        } else {
            setOpenConfigModal(true);
        }
    };

    return (
        <div className='mainViewContainerLogin'>
            <img src={GifIconLoading} alt="gif" className={ isMobile ? 'mainGifContainerLogin' : 'mainGifContainerLoginMobile' } />
            {/* <div className='textTitleBoldLogin'>{ isLoading ? <PlaceholderLoading shape="rect" width={250} height={20}/> : 'Login'}</div>
            <div className='textInputContainerViewLogin'>
                <input 
                    ref={emailRef}
                    className='inputViewLogin' 
                    type="email" 
                    placeholder='Email' 
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    maxLength={12}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            passwordRef.current.focus();
                        }
                    }}
                />
                <input 
                    ref={passwordRef}
                    className='inputViewLogin' 
                    type="password" 
                    placeholder='Password'
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    maxLength={6}
                    onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                            passwordRef.current.blur();
                        }
                    }}
                />
                <div className='btnViewLogin'>
                    <CustomButton
                        color={'#fff'}
                        text={'Confirmar'}
                        textColor={'#00214D'}
                        borderColor={'#00214D'}
                        onPress={() => handleLogin()}
                        enabled={email.length >= 10 && password.length >= 5}
                    />
                    <CustomAlert 
                        title='Ops! Algo deu errado'
                        text='O e-mail ou a password inseridos estão incorretos. Por favor, tente novamente.'            
                        type='error'
                        show={openConfigModal} 
                        onHide={() => setOpenConfigModal(false)} 
                    /> 
                </div>
            </div> */}

        </div>
    )
}

export default Login