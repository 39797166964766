import React, { useState, useEffect } from 'react';
import '../../css/onboardingLinkCardSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import CustomAlert from '../../components/CustomAlert';
import imgDataRespData from '../../mock/imgDataResp.json'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function LinkCardStepMB() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const [swiperIndexFirst, setSwiperIndexFirst] = useState(0);
    const [swiperInstanceFirst, setSwiperInstanceFirst] = useState(null);

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const handlegoBackClick = () => {
        navigate(-1);
    };

    const handleClickShowConfigModal = () => {
        //alert("Configurações");
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       //alert("Como funciona");
       setOpenInfoModal(true)
    };

    const handleSwiperSlideChangeFirst = (swiperFirst) => {
        setSwiperIndexFirst(swiperFirst.activeIndex);
    };

    const nextImageFirst = () => {
        if (swiperInstanceFirst !== null) {
            swiperInstanceFirst.slideNext();
        }
    };

    const prevImageFirst = () => {
        if (swiperInstanceFirst !== null) {
            swiperInstanceFirst.slidePrev();
        }
    };

  return (  
    <>
    { isMobile &&  
    <Header/> 
     }
    <div className={ isTablet ? "mainViewContainerLinckCardStepsMB" : ( isMobile ? 'mainViewContainerLinckCardStepsMBTablet' : 'mainViewContainerLinckCardStepsMBMobile') }>
        <div className={ isTablet ? 'firstTxtViewContainerLinckCardSteps' : ( isMobile ? 'firstTxtViewContainerLinckCardStepsTablet' : 'firstTxtViewContainerLinckCardStepsMobile') }>

            <div className={ isTablet ? 'titleSaldoContainerLinckCardStepsWithSaldo' : ( isMobile ? 'titleSaldoContainerLinckCardStepsWithSaldo' : 'titleSaldoContainerLinckCardStepsWithSaldoMobile')}>
                <div className={ isTablet ? 'titleContainerLinckCardStepsWithSaldo' : ( isMobile ? 'titleContainerLinckCardStepsWithSaldo' : 'titleContainerLinckCardStepsWithSaldoMobile')}>
                    <div className={ isTablet ? 'firstTitleViewLinckCardStepsWithSaldo' : ( isMobile ? 'firstTitleViewLinckCardStepsWithSaldo' : 'firstTitleViewLinckCardStepsWithSaldoMobile')}>
                        { isTablet ? (
                            <div className='textTitleBoldLinckCardStepsWithSaldo'>Carteira Virtual Médis</div> ) : (
                            <>
                            <div className='textTitleRegularLinckCardStepsWithSaldoMobile'>Carteira</div>
                            <div className='textTitleBoldLinckCardStepsWithSaldoMobile'>Virtual Médis</div>
                        </>
                        )}      
                    </div>
                    <div className='firstTitleViewConfigOnfoLinckCardStepsWithSaldo'>
                        <button className='infoHeaderIonBtnLinckCardStepsWithSaldo'  type='button' onClick={handleClickShowConfigModal} >
                            <span className='txtConfigTitleLinckCardStepsWithSaldo'><IoSettingsOutline  /></span> <span className='txtConfigTitleLinckCardStepsWithSaldo'>Configurações</span>
                        </button>
                        <CustomAlert 
                            title='Configurações'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openConfigModal} 
                            onHide={() => setOpenConfigModal(false)} 
                        /> 
                        <button className='infoHeaderIonBtnLinckCardStepsWithSaldo'  type='button' onClick={handleClickShowInfo} >
                            <span className='infoIconLinckCardStepsWithSaldo'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitleLinckCardStepsWithSaldo'>Como funciona</span>
                        </button>
                        <CustomAlert 
                            title='Como Funciona'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openInfoModal} 
                            onHide={() => setOpenInfoModal(false)} 
                        />      
                    </div>
                </div>
                <div className={ isTablet ? 'saldoContainerLinckCardStepsWithSaldo' : ( isMobile ? 'saldoContainerLinckCardStepsWithSaldo' : 'saldoContainerLinckCardStepsWithSaldoMobile')}>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                        <>
                        <div>
                            <span className={ isTablet ? 'txtRegSaldoLinckCardStepsWithSaldo' : 'txtRegSaldoLinckCardStepsWithSaldoMobile'}>O meu </span>
                            <span className={ isTablet ? 'txtBoldSaldoLinckCardStepsWithSaldo' : 'txtBoldSaldoLinckCardStepsWithSaldoMobile'}>Saldo</span>
                        </div>
                        <div className='saldoInfoContainerLinckCardStepsWithSaldo'>
                            <div className="saldoEuroIconViewLinckCardStepsWithSaldo">
                                <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconLinckCardStepsWithSaldo" />
                            </div>
                            <div className='txtRegularSaldoLinckCardStepsWithSaldo'>22,3€</div>
                        </div>
                        </>
                    )}
                </div>
            </div>

            <div className='goBackBtnViewMbWayLinckCardSteps'>
                <button className='txtGoBackBtnLinckCardSteps'  type='button' onClick={handlegoBackClick} >
                    <FaArrowLeftLong className='goBackIconLinckCardSteps' /> Voltar
                </button>
            </div>

            <div className={ isTablet ? 'secondMainContainerLinckCardStepsMB' : 'secondMainContainerLinckCardStepsMBMobile' }>
                <div className={ isTablet ? 'firstSubMainContainerLinckCardStepsMB' : 'firstSubMainContainerLinckCardStepsMBMobile' }>
                    <div className='secondTitleViewLinckCardSteps'>
                        <span className={ isTablet ? 'textTitleRegular400LinckCardSteps' : ( isMobile ? 'textTitleRegular400LinckCardSteps' : 'textTitleRegular400LinckCardStepsMobile') }>Associar cartão através do </span>
                        <span className={ isTablet ? 'textTitleBold700LinckCardSteps' : ( isMobile ? 'textTitleRegular400LinckCardSteps' : 'textTitleBold700LinckCardStepsMobile') }>multibanco</span>
                    </div>
                    <div className='txtSecondTitleViewLinckCardSteps'>
                        <span className='textSecondTitleViewLinckCardSteps'>O número da sua Carteira Virtual é: </span>
                        <span className='textSecondTitleBoldViewLinckCardSteps'>XXXXXXX</span>
                    </div>
                </div>

                {/* Start first slider */}
                <div className={isTablet ? 'mainSecondViewContainerLinckCardStepsMB' : 'mainSecondViewContainerLinckCardStepsMBMobile'}>

                    <Swiper
                        onSlideChange={(swiperFirst) => handleSwiperSlideChangeFirst(swiperFirst)}
                        onSwiper={(swiperFirst) => setSwiperInstanceFirst(swiperFirst)}
                        slidesPerView={1}
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        // pagination={{
                        // clickable: true,
                        // }}
                        navigation={false}
                        modules={[Autoplay, Pagination, Navigation]}
                        className={isTablet ? "mySwiperLinckCardStepsMB" : "mySwiperLinckCardStepsMBMobile"}
                    >
                        {imgDataRespData.imgData.imgLinkCardByMB.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div className={ isTablet ? 'secondSubMainContainerLinckCardStepsMB' : 'secondSubMainContainerLinckCardStepsMBMobile' }>
                                    { isTablet ? (
                                        <>
                                        <div className='imgAddCardMainViewLinckCardStepsMB' style={{ backgroundImage: `url(${item.imgLinkCard})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                            {/* <img src={item.imgLinkCard} alt="imagem"  className={ isTablet ? 'imgAddCardLinckCardStepsMB' : 'imgAddCardLinckCardStepsMBMobile' } /> */}
                                        </div>
                                        <div className='txtAddCardMainViewLinckCardStepsMB'>
                                            <div className='txtAddCardMainTitleViewLinckCardStepsMB'>
                                                <div className='txtAddCardBoldLinckCardStepsMB'>{item.titleLinkCard}</div>
                                            </div>
                                            <div className='txtAddCardMainSubTitleViewLinckCardStepsMB'>
                                                <div className='txtAddCardRegularLinckCardStepsMB'>{item.txtLinkCard}</div>
                                            </div>
                                        </div>
                                        </>
                                    ) : (
                                        <>
                                        <div className='imgAddCardMainViewLinckCardStepsMBMobile'>
                                            <img src={item.imgLinkCard} alt="imagem"  className='imgAddCardLinckCardStepsMBMobile'/>
                                        </div>
                                        <div className='txtAddCardMainViewLinckCardStepsMBMobile'>
                                            <div className='txtAddCardMainSubTitleViewLinckCardStepsMB'>
                                                <span className='txtAddCardBoldLinckCardStepsMBMobile'>{item.titleLinkCard}</span>
                                                <span className='txtAddCardRegularLinckCardStepsMBMobile'> {item.txtLinkCard}</span>
                                            </div>
                                        </div>
                                        </>
                                    )}
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    <div className={isTablet ? 'thirdSubMainContainerCircleNavLinckCardStepsMB' : 'thirdSubMainContainerLinckCardStepsMBMobile'}>
                        <button className='iconArrowLeftViewContainerLinckCardStepsMB' onClick={prevImageFirst} disabled={swiperIndexFirst == 0}>
                            <FaArrowLeftLong className='iconArrowLinckCardStepsMB' />
                        </button>

                        <div className='cirlceSelectedLinckCardStepsMB'>
                            <div className='circleMainvViewLinckCardStepsMB'>
                                <div className='circleContainerLinckCardStepsMB'>
                                    <div className={swiperIndexFirst == 0 ? 'circleSelectedViewLinckCardStepsMB' : 'circleViewLinckCardStepsMB'} />
                                </div>
                                <div className='circleContainerLinckCardStepsMB'>
                                    <div className={swiperIndexFirst == 1 ? 'circleSelectedViewLinckCardStepsMB' : 'circleViewLinckCardStepsMB'} />
                                </div>
                                <div className='circleContainerLinckCardStepsMB'>
                                    <div className={swiperIndexFirst >= 2 ? 'circleSelectedViewLinckCardStepsMB' : 'circleViewLinckCardStepsMB'} />
                                </div>
                            </div>
                        </div>

                        <button className='iconArrowRightViewContainerLinckCardStepsMB' onClick={nextImageFirst} disabled={swiperIndexFirst >= imgDataRespData.imgData.imgLinkCardByMB.length - 1}>
                            <FaArrowRightLong className='iconArrowLinckCardStepsMB' />
                        </button>
                    </div>

                </div>
                {/* End slider */}

            </div>          
        </div>
    </div>
    </>
  )
}

export default LinkCardStepMB;