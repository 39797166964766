import React from 'react';


const useCodes = () => {

    // -----------------------------------------------
    // PAYMENT TYPES
    // -----------------------------------------------

    // Codes 
    const paymentType = {
        movements: "M",
        pending: "P",
    }

    // List and configuration
    const paymentTypeList = [
        {
            id: paymentType.movements,
            title: "Movimentos",
        },
        {
            id: paymentType.pending,
            title: "Pendentes",
        },
    ]

    // Get Payment by id
    const getPaymentType = id => {
        return paymentTypeList.find(method => method.id === id);
    }


    // -----------------------------------------------
    // PAYMENT STATUS
    // -----------------------------------------------

    // Codes
    const paymentStatus = {
        balanceMovements: 'BM',
        balancePending: 'BP',
        balanceSpentMovements: 'BSM',
        balanceSpentPending: 'BSP',
    }

    // List and labels
    const paymentStatusList = [
        {
            id: paymentStatus.balanceMovements,
            title: "Acumulação",
            color: '#E6FAE4',
            iconColor: '#15D4B6',
            txtAlert: 'Valor acrescentado ao saldo da sua Carteira Virtual.'
        },
        {
            id: paymentStatus.balancePending,
            title: "Acumulação pendente",
            color: '#FFEDC2',
            iconColor: '#FFB300',
            txtAlert: 'Este valor estará disponível no seu saldo nas próximas 48 horas.'
        },
        {
            id: paymentStatus.balanceSpentMovements,
            title: "Dedução",
            color: '#D1F4F8',
            iconColor: '#40D0E2',
            txtAlert: 'Valor retirado do saldo da sua Carteira Virtual.'
        },
        {
            id: paymentStatus.balanceSpentPending,
            title: "Dedução pendente",
            color: '#FFEDC2',
            iconColor: '#FFB300',
            txtAlert: 'Este valor será retirado do seu saldo nas próximas 48 horas.'
        },
    ]

    // Get status object by id
    const getPaymentStatus = id => {
        return paymentStatusList.find(method => method.id === id);
    }

    return {
        paymentType,
        paymentStatus,
        paymentTypeList,
        paymentStatusList,
        getPaymentType,
        getPaymentStatus,
    };
};


export default useCodes;