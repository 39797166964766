import React, { useState, useEffect } from 'react';
import '../../css/seePartners.css';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { GoDotFill } from "react-icons/go";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoSearch } from "react-icons/io5";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgDecatlonSvg from '../../assets/svg/iconLogoDecatlhon.svg';
import adsOfferData from '../../mock/adsOffer.json'
import adsSecondOfferData from '../../mock/adsSecondOffer.json'
import imgDataRespData from '../../mock/imgDataResp.json'
import CustomAlert from '../CustomAlert';
import CustomButton from '../CustomButton';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function SeePartners() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const [swiperIndex, setSwiperIndex] = useState(0);
    const [swiperIndexTwo, setSwiperIndexTwo] = useState(0);
    const [swiperIndexFirst, setSwiperIndexFirst] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [swiperInstanceTwo, setSwiperInstanceTwo] = useState(null);
    const [swiperInstanceFirst, setSwiperInstanceFirst] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [seacrhCategory, setSeacrhCategory]  = useState('');

    const handleShowInfo = () => {
        setModalShow(true)
    };

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    


    const hadleBtnClickKnowMorePartners = () => {
        navigate('/partnersPage');
    };

    const hadleBtnClickGoToPartners = (category) => {
        navigate("/partnersPage", { state: {category} });
    };

    const hadleBtnClickSearchPartners = () => {
        navigate("/partnersPage", { state: {seacrhCategory} });
    };

    const handleSwiperSlideChange = (swiper) => {
        setSwiperIndex(swiper.activeIndex);
    };

    const handleSwiperSlideChangeTwo = (swiperTwo) => {
        setSwiperIndexTwo(swiperTwo.activeIndex);
    };

    const handleSwiperSlideChangeFirst = (swiperFirst) => {
        setSwiperIndexFirst(swiperFirst.activeIndex);
    };
    
    const nextImage = () => {
        if (swiperInstance !== null) {
        swiperInstance.slideNext();
        }
    };

    const prevImage = () => {
        if (swiperInstance !== null) {
        swiperInstance.slidePrev();
        }
    };

    const nextImageTwo = () => {
        if (swiperInstanceTwo !== null) {
            swiperInstanceTwo.slideNext();
        }
    };

    const prevImageTwo = () => {
        if (swiperInstanceTwo !== null) {
            swiperInstanceTwo.slidePrev();
        }
    };

    const nextImageFirst = () => {
        if (swiperInstanceFirst !== null) {
            swiperInstanceFirst.slideNext();
        }
    };

    const prevImageFirst = () => {
        if (swiperInstanceFirst !== null) {
            swiperInstanceFirst.slidePrev();
        }
    };

    return (
        <>
        {/* Start search input */}
        <div className={isTablet ? 'searchViewContainerSeePartners' : ( isMobile ? 'searchViewContainerSeePartnersTablet' : 'searchViewContainerSeePartnersMobile')}>
                <div className='textWrapperSeePartners'>
                    <input 
                        className='inputTextSeePartners'
                        type="text" 
                        placeholder='Pesquise por parceiro ou localidade'
                        value={seacrhCategory}
                        onChange={(event) => setSeacrhCategory(event.target.value)}
                        maxLength={50}
                    />
                    <div className='findIconViewSeePartners' onClick={hadleBtnClickSearchPartners}><IoSearch className='findIconSeePartners' /></div>
                </div>
        </div>
        {/* End search input */}
        {/* Start first slider */}
        <div className={isTablet ? 'mainSecondViewContainerSeePartners' : ( isMobile ? 'mainSecondViewContainerSeePartnersTablet' : 'mainSecondViewContainerSeePartnersMobile')}>

            <Swiper
                onSlideChange={(swiperFirst) => handleSwiperSlideChangeFirst(swiperFirst)}
                onSwiper={(swiperFirst) => setSwiperInstanceFirst(swiperFirst)}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // pagination={{
                // clickable: true,
                // }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className={isTablet ? "mySwiperSeePartners" : "mySwiperSeePartnersMobile"}
            >
                {adsOfferData.offerAds.map((ad, index) => (
                    <SwiperSlide key={index}>
                        {isTablet ? (
                            <div className='secondSubMainContainerSeePartners'>
                                <div className='imgAdsMainViewSeePartners' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    {/* <img src={ad.imageUrl} alt="imagem" className='imgAdsSeePartners' /> */}
                                    <div className='adsProductStatusViewSeePartners'>
                                        <div className='txtAdsProductStatusSeePartners'>{ad.productStatus}</div>
                                    </div>
                                </div>
                                <div className='txtAdsMainViewSeePartners'>
                                    <div className='txtAdsMainTitleViewSeePartners'>
                                        <div className='txtAdsDateSeePartners'>{ad.dateOfOffer}</div>
                                    </div>
                                    <div className='txtAdsMainTitleViewSeePartners'>
                                        <div className='txtAdsBoldSeePartners'>{ad.title}</div>
                                    </div>
                                    <div className='txtAdsMainSubTitleViewSeePartners'>
                                        <div className='txtAdsRegularSeePartners'>{ad.description}</div>
                                    </div>
                                    <div className='imgAdsCompanyLogoMainViewSeePartners'>
                                        <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoSeePartners' />
                                        <div className='txtAdsCompanyLogoViewSeePartners'>
                                            <div className='txtAdsCompanyTitleSeePartners'>{ad.storeName}</div>
                                            <div className='txtAdsProductCategoryTitleSeePartners'>{ad.productCategory}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ( isMobile ? (
                            <div className='secondSubMainContainerSeePartnersTablet'>
                                <div className='imgMainViewSeePartnersTablet'>
                                    <div className='imgAdsMainViewSeePartnersTablet' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsSeePartners' /> */}
                                        <div className='adsProductStatusViewSeePartnersTablet'>
                                            <div className='txtAdsProductStatusSeePartners'>{ad.productStatus}</div>
                                        </div>
                                    </div>

                                    <div className='txtAdsMainViewSeePartnersTablet'>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsDateSeePartnersTablet'>{ad.dateOfOffer}</div>
                                        </div>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsBoldSeePartnersTablet'>{ad.title}</div>
                                        </div>
                                        <div className='txtAdsMainSubTitleViewSeePartners'>
                                            <div className='txtAdsRegularSeePartnersTablet'>{ad.description}</div>
                                        </div>
                                        <div className='imgAdsCompanyLogoMainViewSeePartnersTablet'>
                                            <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoSeePartners' />
                                            <div className='txtAdsCompanyLogoViewSeePartners'>
                                                <div className='txtAdsCompanyTitleSeePartners'>{ad.storeName}</div>
                                                <div className='txtAdsProductCategoryTitleSeePartners'>{ad.productCategory}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='secondSubMainContainerSeePartnersMobile'>
                                <div className='imgAdsMainViewSeePartnersMobile' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsSeePartners' /> */}
                                    <div className='adsProductStatusViewSeePartnersMobile'>
                                        <div className='txtAdsProductStatusSeePartners'>{ad.productStatus}</div>
                                    </div>

                                    <div className='txtAdsMainViewSeePartnersMobile'>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsDateSeePartnersMobile'>{ad.dateOfOffer}</div>
                                        </div>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsBoldSeePartnersMobile'>{ad.title}</div>
                                        </div>
                                        <div className='txtAdsMainSubTitleViewSeePartners'>
                                            <div className='txtAdsRegularSeePartnersMobile'>{ad.description}</div>
                                        </div>
                                        <div className='imgAdsCompanyLogoMainViewSeePartnersMobile'>
                                            <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoSeePartners' />
                                            <div className='txtAdsCompanyLogoViewSeePartners'>
                                                <div className='txtAdsCompanyTitleSeePartners'>{ad.storeName}</div>
                                                <div className='txtAdsProductCategoryTitleSeePartners'>{ad.productCategory}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={isTablet ? 'thirdSubMainContainerCircleNavSeePartners' : 'thirdSubMainContainerSeePartnersMobile'}>
                    <button className='iconArrowLeftViewContainerCircleNavSeePartners' onClick={prevImageFirst} disabled={swiperIndexFirst == 0}>
                        <FaArrowLeftLong className='iconArrowCircleNavSeePartners' />
                    </button>

                    <div className='cirlceSelectedNavSeePartners'>
                        <div className='circleMainvViewNavSeePartners'>
                            <div className='circleContainerNavSeePartners'>
                                <div className={swiperIndexFirst == 0 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                            </div>
                            <div className='circleContainerNavSeePartners'>
                                <div className={swiperIndexFirst == 1 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                            </div>
                            <div className='circleContainerNavSeePartners'>
                                <div className={swiperIndexFirst >= 2 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                            </div>
                        </div>
                    </div>

                    <button className='iconArrowRightViewContainerCircleNavSeePartners' onClick={nextImageFirst} disabled={swiperIndexFirst >= adsOfferData.offerAds.length - 1}>
                        <FaArrowRightLong className='iconArrowCircleNavSeePartners' />
                    </button>
            </div>

        </div>
        {/* End first slider */}

        {/* Start partners list slider */}
        <div className={ isTablet ? 'mainPartnersListViewContainerSeePartners' : ( isMobile ? 'mainPartnersListViewContainerSeePartnersTablet' : 'mainPartnersListViewContainerSeePartnersMobile') }>
                <div className='txtAdsBoldSeePartners'>Parceiros por categoria</div>
            { isTablet ? (
                <div className='gridViewContainderBoldSeePartners'>
                    { imgDataRespData.imgData.navBlockData.map((block, index) => (
                    <button 
                        key={index} 
                        className={ isTablet ? 'navBoxViewContainerSeePartners' : 'navBoxViewContainerSeePartnersMobile' } 
                        onClick={() => hadleBtnClickGoToPartners(block.category)}>
                        <img src={block.iconNavBlock} alt="imagem" className="imagemNavBoxSvgSeePartners" />
                        <div className='txtNavBoxViewContainerSeePartners'>
                            <div className='txtNavBoxBoldSeePartners'>{block.titleNavBlock}</div>
                        </div>
                    </button>
                    ))}
                </div>
            ) : (
                <Swiper
                    slidesPerView={isMobile ? 4 : 2}
                    spaceBetween={16}
                    // pagination={{
                    //     clickable: true,
                    //   }}
                    modules={[Pagination]}
                    className="mySwiperThreeSeePartnersMobile"
                >
                    { imgDataRespData.imgData.navBlockData.map((block, index) => (
                        <SwiperSlide key={index}>
                            <button 
                                key={index} 
                                className={ isTablet ? 'navBoxViewContainerSeePartners' : 'navBoxViewContainerSeePartnersMobile' } 
                                onClick={() => hadleBtnClickGoToPartners(block.category)}>
                                <img src={block.iconNavBlock} alt="imagem" className="imagemNavBoxSvgSeePartners" />
                                <div className='txtNavBoxViewContainerSeePartners'>
                                    <div className='txtNavBoxBoldSeePartners'>{block.titleNavBlock}</div>
                                </div>
                            </button>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
        {/* End partners list slider */}

        {/* Start second slider */}
        <div className={isTablet ? 'mainSecondViewContainerSeePartners' : ( isMobile ? 'mainSecondViewContainerSeePartnersTablet' : 'mainSecondViewContainerSeePartnersMobile')}>
            <div className='firstSubMainContainerSeePartners'>
                <div className='secondTitleViewSeePartners'>
                    <span className={isTablet ? 'textTitleRegular400SeePartners' : 'textTitleRegular400SeePartnersMobile'}> <b className={isTablet ? 'textTitleBold700SeePartners' : 'textTitleBold700SeePartnersMobile'}>Bebé e Gravidez:</b> nova categoria</span>
                </div>
                <div className='txtSecondTitleViewSeePartners'>
                    <span className={isTablet ? 'textSecondTitleViewSeePartners' : 'textSecondTitleViewSeePartnersMobile'}>Conheça as novidades do catálogo de parceiros Carteira Virtual Médis.</span>
                </div>
            </div>

            <Swiper
                onSlideChange={(swiper) => handleSwiperSlideChange(swiper)}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // pagination={{
                // clickable: true,
                // }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className={isTablet ? "mySwiperSeePartners" : "mySwiperSeePartnersMobile"}
            >
                {adsOfferData.offerAds.map((ad, index) => (
                    <SwiperSlide key={index}>
                        {isTablet ? (
                            <div className='secondSubMainContainerSeePartners'>
                                <div className='imgAdsMainViewSeePartners' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    {/* <img src={ad.imageUrl} alt="imagem" className='imgAdsSeePartners' /> */}
                                    <div className='adsProductStatusViewSeePartners'>
                                        <div className='txtAdsProductStatusSeePartners'>{ad.productStatus}</div>
                                    </div>
                                </div>
                                <div className='txtAdsMainViewSeePartners'>
                                    <div className='txtAdsMainTitleViewSeePartners'>
                                        <div className='txtAdsDateSeePartners'>{ad.dateOfOffer}</div>
                                    </div>
                                    <div className='txtAdsMainTitleViewSeePartners'>
                                        <div className='txtAdsBoldSeePartners'>{ad.title}</div>
                                    </div>
                                    <div className='txtAdsMainSubTitleViewSeePartners'>
                                        <div className='txtAdsRegularSeePartners'>{ad.description}</div>
                                    </div>
                                    <div className='imgAdsCompanyLogoMainViewSeePartners'>
                                        <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoSeePartners' />
                                        <div className='txtAdsCompanyLogoViewSeePartners'>
                                            <div className='txtAdsCompanyTitleSeePartners'>{ad.storeName}</div>
                                            <div className='txtAdsProductCategoryTitleSeePartners'>{ad.productCategory}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ( isMobile ? (
                            <div className='secondSubMainContainerSeePartnersTablet'>
                                <div className='imgMainViewSeePartnersTablet'>
                                    <div className='imgAdsMainViewSeePartnersTablet' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsSeePartners' /> */}
                                        <div className='adsProductStatusViewSeePartnersTablet'>
                                            <div className='txtAdsProductStatusSeePartners'>{ad.productStatus}</div>
                                        </div>
                                    </div>

                                    <div className='txtAdsMainViewSeePartnersTablet'>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsDateSeePartnersTablet'>{ad.dateOfOffer}</div>
                                        </div>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsBoldSeePartnersTablet'>{ad.title}</div>
                                        </div>
                                        <div className='txtAdsMainSubTitleViewSeePartners'>
                                            <div className='txtAdsRegularSeePartnersTablet'>{ad.description}</div>
                                        </div>
                                        <div className='imgAdsCompanyLogoMainViewSeePartnersTablet'>
                                            <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoSeePartners' />
                                            <div className='txtAdsCompanyLogoViewSeePartners'>
                                                <div className='txtAdsCompanyTitleSeePartners'>{ad.storeName}</div>
                                                <div className='txtAdsProductCategoryTitleSeePartners'>{ad.productCategory}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='secondSubMainContainerSeePartnersMobile'>
                                <div className='imgAdsMainViewSeePartnersMobile' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsSeePartners' /> */}
                                    <div className='adsProductStatusViewSeePartnersMobile'>
                                        <div className='txtAdsProductStatusSeePartners'>{ad.productStatus}</div>
                                    </div>

                                    <div className='txtAdsMainViewSeePartnersMobile'>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsDateSeePartnersMobile'>{ad.dateOfOffer}</div>
                                        </div>
                                        <div className='txtAdsMainTitleViewSeePartners'>
                                            <div className='txtAdsBoldSeePartnersMobile'>{ad.title}</div>
                                        </div>
                                        <div className='txtAdsMainSubTitleViewSeePartners'>
                                            <div className='txtAdsRegularSeePartnersMobile'>{ad.description}</div>
                                        </div>
                                        <div className='imgAdsCompanyLogoMainViewSeePartnersMobile'>
                                            <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoSeePartners' />
                                            <div className='txtAdsCompanyLogoViewSeePartners'>
                                                <div className='txtAdsCompanyTitleSeePartners'>{ad.storeName}</div>
                                                <div className='txtAdsProductCategoryTitleSeePartners'>{ad.productCategory}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={isTablet ? 'thirdSubMainContainerCircleNavSeePartners' : 'thirdSubMainContainerSeePartnersMobile'}>
                <button className='iconArrowLeftViewContainerCircleNavSeePartners' onClick={prevImage} disabled={swiperIndex == 0}>
                    <FaArrowLeftLong className='iconArrowCircleNavSeePartners' />
                </button>

                <div className='cirlceSelectedNavSeePartners'>
                    <div className='circleMainvViewNavSeePartners'>
                        <div className='circleContainerNavSeePartners'>
                            <div className={swiperIndex == 0 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                        </div>
                        <div className='circleContainerNavSeePartners'>
                            <div className={swiperIndex == 1 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                        </div>
                        <div className='circleContainerNavSeePartners'>
                            <div className={swiperIndex >= 2 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                        </div>
                    </div>
                </div>

                <button className='iconArrowRightViewContainerCircleNavSeePartners' onClick={nextImage} disabled={swiperIndex >= adsOfferData.offerAds.length - 1}>
                    <FaArrowRightLong className='iconArrowCircleNavSeePartners' />
                </button>
            </div>

        </div>
        {/* End second slider */}

        {/* Start thied slider */}
        <div className={isTablet ? 'mainThirdViewContainerSeePartners' : ( isMobile ? 'mainThirdViewContainerSeePartnersTablet' : 'mainThirdViewContainerSeePartnersMobile')}>
            <div className={ isTablet ? 'thirdTitleViewContainerSeePartners' : (isMobile ? 'thirdTitleViewContainerSeePartnersTablet' : 'thirdTitleViewContainerSeePartnersMobile') }>
                <div className='thirdTitleViewSeePartners'>
                    {/* <div className={isTablet ? 'thirdTextTitleRegular400SeePartners' : 'thirdTextTitleRegular400SeePartnersMobile'}>Parceiros que ajudam nas</div> */}
                    <div className={isTablet ? 'thirdTextTitleBold700SeePartners' : 'thirdTextTitleBold700SeePartners'}>Já conhece estes parceiros?</div>
                </div>
                <div className='txtThirdTitleViewSeePartners'>
                    <div className='thirdTextOneSeePartners'>Faça compras nestes locais e receba parte do valor em saldo da Carteira Virtual.</div>
                </div>
                <div className={isTablet ? 'divBtnKnowMorePartnersSeePartners' : 'divBtnKnowMorePartnersSeePartnersMobile'}>
                    <CustomButton 
                        text={'Descobrir parceiros'} 
                        textColor={'#141414'}
                        borderColor={'#141414'}
                        hasArrow
                        onPress={hadleBtnClickKnowMorePartners} 
                    />
                </div>
            </div>

            <div className={isTablet ? 'secondSliderAdsContainerSeePartners' : ( isMobile ? 'secondSliderAdsContainerSeePartnersTablet' : 'secondSliderAdsContainerSeePartnersMobile')}>
                <Swiper
                    onSlideChange={(swiperTwo) => handleSwiperSlideChangeTwo(swiperTwo)}
                    onSwiper={(swiperTwo) => setSwiperInstanceTwo(swiperTwo)}
                    slidesPerView={isTablet ? 2.5 : 1}
                    spaceBetween={32}
                    // pagination={{
                    //     clickable: true,
                    //   }}
                    modules={[Pagination]}
                    className={isTablet ? "mySwiperTwoSeePartners" : "mySwiperTwoSeePartnersMobile"}
                >
                    {adsSecondOfferData.secondOfferAds.map((ad, index) => (
                        <SwiperSlide key={index}>
                            <div className={isTablet ? 'secondSliderAdsOfertaSeePartners' : 'secondSliderAdsOfertaSeePartnersMobile'}>

                                {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsSeePartnersTwo' /> */}
                                <div className='txtTitleBoldAdsOfferTwoSeePartnersTwo'>{ad.title}</div>
                                <div className='txtTitleAdsOfferTwoSeePartnersTwo'>{ad.storeName}</div>
                                <div className='txtTitleAdsOfferTwoSeePartnersTwo'>{ad.description}</div>
                                <div className='txtTitleRowViewAdsOfferTwoSeePartnersTwo'>
                                    <div className='txtCashbackAdsOfferTwoSeePartnersTwo'>
                                        <div className='iconTitleAdsOfferTwoSeePartnersTwo'><GoDotFill /></div>
                                        <div className='txtTitleAdsOfferTwoSeePartnersTwo'>{ad.cashback}</div>
                                        <button className='infoHeaderIonBtnAdsOfferTwoSeePartnersTwo'  type='button' onClick={handleShowInfo} >
                                            <IoMdInformationCircleOutline className='infoHeaderIcondsOfferTwoSeePartnersTwo' />
                                        </button>
                                    </div>
                                    <div className='txtCashbackAmountAdsOfferTwoSeePartnersTwo'>
                                        <div className='txtTitleBoldAdsOfferTwoSeePartnersTwo'>{ad.cashbackAmount}</div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <CustomAlert 
                    title='Tem dúvidas?'
                    text='Contacte-nos pelo xxxx@medis.pt ou pela Linha Médis: 218 458 888 (dias úteis das 8h00 às 20h00)'
                    type='info'
                    show={modalShow} 
                    onHide={() => setModalShow(false)} 
                /> 

                <div className={isTablet ? 'thirdSubMainContainerCircleNavSeePartners' : 'thirdSubMainContainerSeePartnersMobile'}>
                    <button className='iconArrowLeftViewContainerCircleNavSeePartners' onClick={prevImageTwo} disabled={swiperIndexTwo == 0}>
                        <FaArrowLeftLong className='iconArrowCircleNavSeePartners' />
                    </button>

                    <div className='cirlceSelectedNavSeePartners'>
                        <div className='circleMainvViewNavSeePartners'>
                            <div className='circleContainerNavSeePartners'>
                                <div className={swiperIndexTwo == 0 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                            </div>
                            <div className='circleContainerNavSeePartners'>
                                <div className={swiperIndexTwo == 1 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                            </div>
                            <div className='circleContainerNavSeePartners'>
                                <div className={swiperIndexTwo >= 2 ? 'circleSelectedViewNavSeePartners' : 'circleViewNavSeePartners'} />
                            </div>
                        </div>
                    </div>

                    <button className='iconArrowRightViewContainerCircleNavSeePartners' onClick={nextImageTwo} disabled={swiperIndexTwo >= adsSecondOfferData.secondOfferAds.length - 2}>
                        <FaArrowRightLong className='iconArrowCircleNavSeePartners' />
                    </button>
                </div>

            </div>

        </div>
        {/* End thied slider */}
        </>
    )
}

export default SeePartners