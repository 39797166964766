import React, { useState, useEffect, useReducer } from 'react';
import '../../css/partnersPage.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import CustomAlert from '../../components/CustomAlert';
import CustomFilterModal from '../../components/CustomFilterModal';
import CustomButton from '../../components/CustomButton';
import Form from 'react-bootstrap/Form';
import { IoSearch } from "react-icons/io5";
import { CiFilter } from "react-icons/ci";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa6";
import { RiMapPinLine } from "react-icons/ri";
import movementsDataMock from '../../mock/movementsData.json';
import partnersListMock from '../../mock/partnersListResp.json';
import Slider from '@mui/material/Slider';
import { styled, useTheme } from '@mui/material/styles';
import usePagination from '@mui/material/usePagination';
import { filterReducer } from "../../features/checkBoxReducers/filterReducer";

function PartnersPage() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const [isStore, setIsStore] = useState(false);
    const [isSport, setIsSport] = useState(false);
    const [isEducation, setIsEducation] = useState(false);
    const [isCultureLeisure, setIsCultureLeisure] = useState(false);
    const [isTech, setIsTech] = useState(false);
    const [isModaHome, setIsModaHome] = useState(false);
    const [isAnimals, setIsAnimals] = useState(false);
    const [isCarsTransp, setIsCarsTransp] = useState(false);
    const [isOther, setIsOther] = useState(false);
    const [isRestaurant, setIsRestaurant] = useState(false);
    const [isBeautyStore, setIsBeautyStore] = useState(false);
    const [isStoreOnline, setIsStoreOnline] = useState(false);
    const [isStorePhysical, setIsStorePhysical] = useState(false);
    const [openSelectFilteModal, setOpenSelectFilteModal] = useState(false);
    const [isNew, setIsNew] = useState(false);
    const [seacrhForNamePartner, setSeacrhForNamePartner] = useState('');
    const [seacrhForPlacePartner, setSeacrhForPlacePartner] = useState('');
    const MIN = 0;
    const MAX = 20;
    const [locationDistance, setLocationDistance] = useState(MIN);
    const [myLocation, setMyLocation] = useState('');
    const availableBalance = movementsDataMock.movementsInfo.availableBalance;
    const theme = useTheme();
    const location = useLocation();
    const category = location.state?.category || ''; 
    const seacrhCategory = location.state?.seacrhCategory || ''; 
    const [pageNumber, setPageNumber] = useState(1);

    const List = styled('ul')({
        listStyle: 'none',
        padding: 0,
        margin: 0,
        display: 'flex',
    });

    const { items, setPage } = usePagination({
        count: Math.ceil(partnersListMock.partnersList.length / (isTablet ? 8 :3)),
        onChange: (event, page) => {
            setPageNumber(page);
        },
    });

    console.log('pageNumber: ',pageNumber)

    const handleCheckboxChangeOne = (event) => {
        setIsStore(event.target.checked);
    };

    const handleCheckboxChangeTwo = (event) => {
        setIsSport(event.target.checked);
    };

    const handleCheckboxChangeThree = (event) => {
        setIsBeautyStore(event.target.checked);
    };

    const handleCheckboxChangeFour = (event) => {
        setIsEducation(event.target.checked);
    };
    
    const handleCheckboxChangeFive = (event) => {
        setIsCultureLeisure(event.target.checked);
    };
    
    const handleCheckboxChangeSix = (event) => {
        setIsTech(event.target.checked);
    };
    
    const handleCheckboxChangeSeven = (event) => {
        setIsModaHome(event.target.checked);
    };
    
    const handleCheckboxChangeEight = (event) => {
        setIsAnimals(event.target.checked);
    };
    
    const handleCheckboxChangeNine = (event) => {
        setIsCarsTransp(event.target.checked);
    };
    
    const handleCheckboxChangeTen = (event) => {
        setIsOther(event.target.checked);
    };

    const handleCheckboxChangeEleven = (event) => {
        setIsRestaurant(event.target.checked);
    };

    const handleCheckboxChangeStoreOnline = (event) => {
        setIsStoreOnline(event.target.checked);
    };

    const handleCheckboxChangeStorePhysical = (event) => {
        setIsStorePhysical(event.target.checked);
    };
    
    const handleCheckboxChangeNew = (event) => {
        setIsNew(event.target.checked);
    };

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const handlegoBackClick = () => {
        navigate(-1);
    };

    const handleClickShowConfigModal = () => {
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       setOpenInfoModal(true)
    };

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            position => {
              const { latitude, longitude } = position.coords;
              setMyLocation({ latitude, longitude });
            },
            error => {
              console.error('Erro ao obter localização:', error);
            }
          );
        } else {
          console.error('A geolocalização não é suportada pelo seu navegador!');
        }
    }, []);

    const handleClearFilter  = () => {
        setIsStore(false);
        setIsSport(false);
        setIsBeautyStore(false);
        setIsEducation(false);
        setIsEducation(false);
        setIsCultureLeisure(false);
        setIsTech(false);
        setIsModaHome(false);
        setIsAnimals(false);
        setIsCarsTransp(false);
        setIsOther(false);
        setLocationDistance(MIN);
        setSeacrhForNamePartner('');
        setSeacrhForPlacePartner('');
        setIsStoreOnline('');
        setIsStorePhysical('');
        setIsNew(false);
        setIsRestaurant(false);
        handleClearAllFilters();
    };

    const handleOpenFilterModal = () => {
        setOpenSelectFilteModal(true)
    };

    const onHide = () => {
        setOpenSelectFilteModal(false)
    };

    const handleNavBoxClick = (item) => {
        navigate("/partnersDetails", { state: {item} });
    };

    const countStore = partnersListMock.partnersList.filter(partner => partner.category === "store").length;
    const countSport = partnersListMock.partnersList.filter(partner => partner.category === "sport").length;
    const countBeautyStore = partnersListMock.partnersList.filter(partner => partner.category === "beautyStore").length;
    const countEducation = partnersListMock.partnersList.filter(partner => partner.category === "education").length;
    const countCultureLeisure = partnersListMock.partnersList.filter(partner => partner.category === "cultureLeisure").length;
    const countTech = partnersListMock.partnersList.filter(partner => partner.category === "tech").length;
    const countModaHome = partnersListMock.partnersList.filter(partner => partner.category === "modaHome").length;
    const countAnimals = partnersListMock.partnersList.filter(partner => partner.category === "animals").length;
    const countCarsTransp = partnersListMock.partnersList.filter(partner => partner.category === "carsTransp").length;
    const countOther = partnersListMock.partnersList.filter(partner => partner.category === "other").length;
    const countRestaurant = partnersListMock.partnersList.filter(partner => partner.category === "restaurant").length;
    const countStoreOnline = partnersListMock.partnersList.filter(partner => partner.storeOnline === true).length;
    const countStorePhysical = partnersListMock.partnersList.filter(partner => partner.storePhysical === true).length;
    const countNew = partnersListMock.partnersList.filter(partner => partner.new === true).length;

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 5,
            label: '5',
        },
        {
            value: 10,
            label: '10',
        },
        {
            value: 15,
            label: '15',
        },
        {
            value: 20,
            label: '20Km',
        },
    ];

    const filters = [
        { name: "Supermercados", state: isStore, setState: setIsStore },
        { name: "Desporto", state: isSport, setState: setIsSport },
        { name: "Saúde e Beleza", state: isBeautyStore, setState: setIsBeautyStore },
        { name: "Educação", state: isEducation, setState: setIsEducation },
        { name: "Cultura e Lazer", state: isCultureLeisure, setState: setIsCultureLeisure },
        { name: "Tecnologia", state: isTech, setState: setIsTech },
        { name: "Moda e Casa", state: isModaHome, setState: setIsModaHome },
        { name: "Animais", state: isAnimals, setState: setIsAnimals },
        { name: "Veículos e Transportes", state: isCarsTransp, setState: setIsCarsTransp },
        { name: "Restauração", state: isRestaurant, setState: setIsRestaurant },
        { name: "Outros", state: isOther, setState: setIsOther },
        { name: "Mostrar apenas novidades", state: isNew, setState: setIsNew },
        { name: "Loja física", state: isStoreOnline, setState: setIsStoreOnline },
        { name: "Loja online", state: isStorePhysical, setState: setIsStorePhysical },
    ]
      

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedArrayItems, setSelectedArrayItems] = useState([]);

    console.log('selectedItem ', selectedItem)

    const [selectedItems, dispatch] = useReducer(filterReducer, []);

    const handleCheckboxChange = (event, item) => {
        const isChecked = event.target.checked;
        if (isChecked) {
            dispatch({ type: "TOGGLE_SELECT", id: item.code, name: item.name, checked: true });
        } else {
            const updatedSelectedItems = selectedItems.filter(selectedItem => selectedItem.code !== item.code);
            dispatch({ type: "SET_SELECTED_ITEMS", selectedItems: updatedSelectedItems });
        }
    };
       

    useEffect(() => {
        const selectedNames = selectedItems.map(item => item.name);
        setSelectedArrayItems(selectedNames);
      }, [selectedItems])

    console.log("Selected items:", selectedItems);
    console.log("Selected selectedArrayItems:", selectedArrayItems);

    const handleCancelFilter = (itemName) => {
        const updatedSelectedItems = selectedItems.filter(item => item.name !== itemName);
        dispatch({ type: "SET_SELECTED_ITEMS", selectedItems: updatedSelectedItems });
    };
      
    const handleClearAllFilters = () => {
        dispatch({ type: "SET_SELECTED_ITEMS", selectedItems: [] });
    }; 
    
    const isCheckedConselhos  = (item) => {
        return  selectedItems.some(selectedItem => selectedItem.code === item.code && selectedItem.checked);
      };

    return (  
        <>
        { isTablet &&  
        <Header/> 
        }
        {/* Start fist div componet with title / header */}
        <div className={ isTablet ? "mainViewContainerPartnersPage" : ( isMobile ? 'mainViewContainerPartnersPageTablet' : 'mainViewContainerPartnersPageMobile') }>
            <div className={ isTablet ? 'firstTxtViewContainerPartnersPage' : ( isMobile ? 'firstTxtViewContainerPartnersPageTablet' : 'firstTxtViewContainerPartnersPageMobile') }>
            
                <div className={ isTablet ? 'titleSaldoContainerPartnersPageWithSaldo' : ( isMobile ? 'titleSaldoContainerPartnersPageWithSaldoTablet' : 'titleSaldoContainerPartnersPageWithSaldoMobile')}>
                    <div className={ isTablet ? 'titleContainerPartnersPageWithSaldo' : ( isMobile ? 'titleContainerPartnersPageWithSaldoTablet' : 'titleContainerPartnersPageWithSaldoMobile')}>
                        <div className={ isTablet ? 'firstTitleViewPartnersPageWithSaldo' : ( isMobile ? 'firstTitleViewPartnersPageWithSaldoTablet' : 'firstTitleViewPartnersPageWithSaldoMobile')}>
                            { isTablet ?(
                                <div className='textTitleBoldPartnersPageWithSaldo'>Carteira Virtual Médis</div> ) : (
                                <>
                                <div className='textTitleRegularPartnersPageWithSaldoMobile'>Carteira</div>
                                <div className='textTitleBoldPartnersPageWithSaldoMobile'>Virtual Médis</div>
                                </>
                            )}      
                        </div>
                        <div className='firstTitleViewConfigOnfoPartnersPageWithSaldo'>
                            <button className='infoHeaderIonBtnPartnersPageWithSaldo'  type='button' onClick={handleClickShowConfigModal} >
                                <span className='txtConfigTitlePartnersPageWithSaldo'><IoSettingsOutline  /></span> <span className='txtConfigTitlePartnersPageWithSaldo'>Configurações</span>
                            </button>
                            <CustomAlert 
                                title='Configurações'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openConfigModal} 
                                onHide={() => setOpenConfigModal(false)} 
                            /> 
                            <button className='infoHeaderIonBtnPartnersPageWithSaldo'  type='button' onClick={handleClickShowInfo} >
                                <span className='infoIconPartnersPageWithSaldo'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitlePartnersPageWithSaldo'>Como funciona</span>
                            </button>
                            <CustomAlert 
                                title='Como Funciona'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openInfoModal} 
                                onHide={() => setOpenInfoModal(false)} 
                            />     
                        </div>
                    </div>
                    <div className={ isTablet ? 'saldoContainerPartnersPageWithSaldo' : ( isMobile ? 'saldoContainerPartnersPageWithSaldoTablet' : 'saldoContainerPartnersPageWithSaldoMobile')}>
                        { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                            <>
                            <div>
                                <span className={ isTablet ? 'txtSaldoPartnersPageWithSaldo' : 'txtSaldoPartnersPageWithSaldoMobile'}>O meu </span>
                                <span className={ isTablet ? 'txtBoldSaldoPartnersPageWithSaldo' : 'txtBoldSaldoPartnersPageWithSaldoMobile'}>Saldo</span>
                            </div>
                            <div className='saldoInfoContainerPartnersPageWithSaldo'>
                                <div className="saldoEuroIconViewPartnersPageWithSaldo">
                                    <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconPartnersPageWithSaldo" />
                                </div>
                                <div className='txtRegularSaldoPartnersPageWithSaldo'>{availableBalance}€</div>
                            </div>
                            </>
                        )}
                    </div>
                </div>

                <div className={ isTablet ? 'thirdMainContainerPartnersPage' : 'thirdMainContainerPartnersPageMobile' }>
                    <div className={ isTablet ? 'goBackBtnViewMbWayPartnersPage' : 'goBackBtnViewMbWayPartnersPageMobile' }>
                        <button className='txtGoBackBtnPartnersPage'  type='button' onClick={handlegoBackClick} >
                            <FaArrowLeftLong className='goBackIconPartnersPage' /> Voltar
                        </button>
                    </div>
                </div>
                {/* End fist div componet with title / header */}

                <div className={ isTablet ? 'secondMainContainerPartnersPage' : 'secondMainContainerPartnersPageMobile' }>
                    <div className={ isTablet ? 'firstSubMainContainerPartnersPage' : 'firstSubMainContainerPartnersPageMobile' }>
                        <div className={ isTablet ? 'mainOptionsViewContainerPartnersPage' : 'mainOptionsViewContainerPartnersPageMobile' }>
                            {/* Start fourth div componet with filters */}
                            { isTablet ? (
                                <>
                                <div className='mainOptionsColumnViewContainerPartnersPage'>
                                    <div className='txtFirstOptionsTitleViewPartnersPage'>
                                        <div className='txtFirstOptionsTitlePartnersPage'>Pesquise parceiros</div>
                                    </div>
                                    <div className='optionsDeviderLinePartnersPage'></div>
                                    <div className='textWrapperPartnersPage'>
                                        <input 
                                            className='inputTextPartnersPage'
                                            type="text" 
                                            placeholder='Nome do parceiro'
                                            value={seacrhForNamePartner}
                                            onChange={(event) => {
                                            setSeacrhForNamePartner(event.target.value);
                                            }}
                                            maxLength={15}
                                        />
                                        <div className='findIconViewPartnersPage' onClick={handleClickShowInfo}><IoSearch className='findIconPartnersPage' /></div>
                                    </div>
                                    <div>
                                        <span className='txtInfoGrennOptionsBoldTitlePartnersPage'>{partnersListMock.partnersList.length} </span>
                                        <span className='txtInfoGrennOptionsRegularTitlePartnersPage'>resultados encontrados. Use os filtros para refinar melhor a sua pesquisa</span>
                                    </div>
                                    <div className='optionsDeviderLinePartnersPage'></div>
                                    <div className='txtFirstOptionsTitleViewPartnersPage'>
                                        <div style={{ marginBottom: '24px'}}>
                                            <div className='txtFirstOptionsTitlePartnersPage'>Por localização</div>
                                        </div>
                                        <div className='textWrapperPartnersPage'>
                                            <input 
                                                className='inputTextPartnersPage'
                                                type="text" 
                                                placeholder='Código postal ou localidade'
                                                value={seacrhForPlacePartner}
                                                onChange={(event) => {
                                                setSeacrhForPlacePartner(event.target.value);
                                                }}
                                                maxLength={15}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='rangeInputViewPartnersPage'>
                                        <input
                                            type='range' 
                                            className='rangeInputPartnersPage'
                                            value={locationDistance} 
                                            min={MIN}
                                            max={MAX}                                        
                                            onChange={handleChangeLocationDistance}
                                        />
                                        <div className='rangeInputInfoViewPartnersPage'>
                                            <div className='txtRangeInputInfoViewPartnersPage'>0</div>
                                            <div className='txtRangeInputInfoViewPartnersPage'>20 Km</div>
                                        </div>
                                    </div> */}
                                    <div style={{width: '100%', maxWidth: '350px', height: '100%', paddingLeft: '5px', paddingRight: '15px'}}>
                                        <Slider
                                            size="small"
                                            value={locationDistance}
                                            min={MIN}
                                            step={1}
                                            max={MAX}
                                            marks={marks}
                                            onChange={(_, value) => setLocationDistance(value)}
                                            sx={{
                                                color: theme.palette.mode === 'dark' ? '#141414' : '#141414',
                                                height: 4,
                                                '& .MuiSlider-thumb': {
                                                width: 10,
                                                height: 10,
                                                transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
                                                '&::before': {
                                                    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
                                                },
                                                '&:hover, &.Mui-focusVisible': {
                                                    boxShadow: `0px 0px 0px 8px ${
                                                    theme.palette.mode === 'dark'
                                                        ? '#14141410'
                                                        : '#14141410'
                                                    }`,
                                                },
                                                '&.Mui-active': {
                                                    width: 14,
                                                    height: 14,
                                                },
                                                },
                                                '& .MuiSlider-rail': {
                                                opacity: 0.28,
                                                },
                                            }}
                                        /> 
                                    </div>                                  
                                    <div className='optionsDeviderLinePartnersPage'></div>
                                    <div className='txtFirstOptionsTitleViewPartnersPage'>
                                        <div style={{ marginBottom: '24px'}}>
                                            <div className='txtFirstOptionsTitlePartnersPage'>Por categoria</div>
                                        </div>
                                        <Form>
                                            <div className='firstOptionsFormChekedViewPartnersPage'>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Supermercados (${countStore})`}
                                                    checked={isStore}
                                                    onChange={handleCheckboxChangeOne}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Desporto (${countSport})`}
                                                    checked={isSport}
                                                    onChange={handleCheckboxChangeTwo}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Saúde e Beleza (${countBeautyStore})`}
                                                    checked={isBeautyStore}
                                                    onChange={handleCheckboxChangeThree}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Educação (${countEducation})`}
                                                    checked={isEducation}
                                                    onChange={handleCheckboxChangeFour}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Cultura e Lazer (${countCultureLeisure})`}
                                                    checked={isCultureLeisure}
                                                    onChange={handleCheckboxChangeFive}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Tecnologia (${countTech})`}
                                                    checked={isTech}
                                                    onChange={handleCheckboxChangeSix}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Moda e Casa (${countModaHome})`}
                                                    checked={isModaHome}
                                                    onChange={handleCheckboxChangeSeven}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Animais (${countAnimals})`}
                                                    checked={isAnimals}
                                                    onChange={handleCheckboxChangeEight}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Veículos e Transportes (${countCarsTransp})`}
                                                    checked={isCarsTransp}
                                                    onChange={handleCheckboxChangeNine}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Restauração (${countRestaurant})`}
                                                    checked={isRestaurant}
                                                    onChange={handleCheckboxChangeEleven}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Outros (${countOther})`}
                                                    checked={isOther}
                                                    onChange={handleCheckboxChangeTen}
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='optionsDeviderLinePartnersPage'></div>
                                    <div className='txtFirstOptionsTitleViewPartnersPage'>
                                        <div style={{ marginBottom: '24px'}}>
                                            <div className='txtFirstOptionsTitlePartnersPage'>Opção de compra</div>
                                        </div>
                                        <Form>
                                            <div className='firstOptionsFormChekedViewPartnersPage'>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Loja física (${countStoreOnline})`}
                                                    checked={isStoreOnline}
                                                    onChange={handleCheckboxChangeStoreOnline}
                                                />
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Loja online (${countStorePhysical})`}
                                                    checked={isStorePhysical}
                                                    onChange={handleCheckboxChangeStorePhysical}
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='optionsDeviderLinePartnersPage'></div>
                                    <div className='txtFirstOptionsTitleViewPartnersPage'>
                                        <div style={{ marginBottom: '24px'}}>
                                            <div className='txtFirstOptionsTitlePartnersPage'>Novas parcerias</div>
                                        </div>
                                        <Form>
                                            <div className='firstOptionsFormChekedViewPartnersPage'>
                                                <Form.Check 
                                                    type="checkbox" 
                                                    label={`Mostrar apenas novidades (${countNew})`}
                                                    checked={isNew}
                                                    onChange={handleCheckboxChangeNew}
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                    <div className='optionsDeviderLinePartnersPage'></div>
                                    <CustomButton 
                                        text={'Remover filtros'} 
                                        textColor={'#00214D'}
                                        onPress={handleClearFilter} 
                                        color={'#fff'}
                                        borderColor={'#00214D'}
                                    />
                                </div>
                                </>
                            ) : (
                                <div className='mainOptionsColumnViewContainerPartnersPageMobile'>
                                    <div className='txtFirstOptionsTitleViewPartnersPage'>
                                        <div className='txtFirstOptionsTitlePartnersPage'>Pesquise parceiros</div>
                                    </div>
                                    <div className='mainOptionsRowViewContainerPartnersPageMobile'>
                                        <div className='textWrapperPartnersPage'>
                                            <input 
                                                className='inputTextPartnersPage'
                                                type="text" 
                                                placeholder='Nome do parceiro'
                                                value={seacrhForNamePartner}
                                                onChange={(event) => {
                                                setSeacrhForNamePartner(event.target.value);
                                                }}
                                                maxLength={15}
                                            />
                                            <div className='findIconViewPartnersPage' onClick={handleClickShowInfo}><IoSearch className='findIconPartnersPage' /></div>
                                        </div>
                                        <button className='filterIconViewPartnersPageMobile' onClick={handleOpenFilterModal}><CiFilter className='filterIconPartnersPage'/></button>
                                        <CustomFilterModal 
                                            title='Filter'
                                            show={openSelectFilteModal} 
                                            onHide={onHide} 
                                            fullscreen
                                            seacrhForNamePartner={seacrhForNamePartner}
                                            setSeacrhForNamePartner={setSeacrhForNamePartner}
                                            handleClickShowInfo={handleClickShowInfo}
                                            partnersListMock={partnersListMock.partnersList}
                                            seacrhForPlacePartner={seacrhForPlacePartner}
                                            setSeacrhForPlacePartner={setSeacrhForPlacePartner}
                                            locationDistance={locationDistance}
                                            marks={marks}
                                            min={MIN}
                                            max={MAX}
                                            setLocationDistance={setLocationDistance}
                                            theme={theme}
                                            countStore={countStore}
                                            isStore={isStore}
                                            handleCheckboxChangeOne={handleCheckboxChangeOne}
                                            countSport={countSport}
                                            isSport={isSport}
                                            handleCheckboxChangeTwo={handleCheckboxChangeTwo}
                                            countBeautyStore={countBeautyStore}
                                            isBeautyStore={isBeautyStore}
                                            handleCheckboxChangeThree={handleCheckboxChangeThree}
                                            countEducation={countEducation}
                                            isEducation={isEducation}
                                            handleCheckboxChangeFour={handleCheckboxChangeFour}
                                            countCultureLeisure={countCultureLeisure}
                                            isCultureLeisure={isCultureLeisure}
                                            handleCheckboxChangeFive={handleCheckboxChangeFive}
                                            countTech={countTech}
                                            isTech={isTech}
                                            handleCheckboxChangeSix={handleCheckboxChangeSix}
                                            countModaHome={countModaHome}
                                            isModaHome={isModaHome}
                                            handleCheckboxChangeSeven={handleCheckboxChangeSeven}
                                            countAnimals={countAnimals}
                                            isAnimals={isAnimals}
                                            handleCheckboxChangeEight={handleCheckboxChangeEight}
                                            countCarsTransp={countCarsTransp}
                                            isCarsTransp={isCarsTransp}
                                            handleCheckboxChangeNine={handleCheckboxChangeNine}
                                            countRestaurant={countRestaurant}
                                            isRestaurant={isRestaurant}
                                            handleCheckboxChangeEleven={handleCheckboxChangeEleven}
                                            countOther={countOther}
                                            isOther={isOther}
                                            handleCheckboxChangeTen={handleCheckboxChangeTen}
                                            countStoreOnline={countStoreOnline}
                                            isStoreOnline={isStoreOnline}
                                            handleCheckboxChangeStoreOnline={handleCheckboxChangeStoreOnline}
                                            countStorePhysical={countStorePhysical}
                                            isStorePhysical={isStorePhysical}
                                            handleCheckboxChangeStorePhysical={handleCheckboxChangeStorePhysical}
                                            countNew={countNew}
                                            isNew={isNew}
                                            handleCheckboxChangeNew={handleCheckboxChangeNew}
                                            handleClearFilter={handleClearFilter}

                                            selectedItem={selectedItem}
                                            setSelectedItem={setSelectedItem}
                                            selectedArrayItems={selectedArrayItems}
                                            handleCheckboxChange={handleCheckboxChange}
                                            handleCancelFilter={handleCancelFilter}
                                            filters={filters}
                                            isCheckedConselhos={isCheckedConselhos}
                                        />  
                                    </div>
                                    <div>
                                        <span className='txtInfoGrennOptionsBoldTitlePartnersPage'>{partnersListMock.partnersList.length} </span>
                                        <span className='txtInfoGrennOptionsRegularTitlePartnersPage'>resultados encontrados. Use os filtros para refinar melhor a sua pesquisa</span>
                                    </div>
                                    <div className='selectedFiltersScrollViewContainerPartnersPage'>
                                        {selectedArrayItems.map((itemName, index) => (
                                            <button key={index} className='btnSelectedFilterItemPartnersPage' onClick={() => handleCancelFilter(itemName)}>
                                                <div className="txtBtnSelectedFilterItemPartnersPage">{itemName}</div>
                                                <div style={{ display: 'flex'}}><IoMdClose className="iconBtnSelectedFilterItemPartnersPage"/></div>
                                            </button>
                                        ))}
                                        {filters.map((filter, index) => (
                                            filter.state && (
                                            <button key={index} className='btnSelectedFilterItemPartnersPage' onClick={() => filter.setState(false)}>
                                                <div className="txtBtnSelectedFilterItemPartnersPage">{filter.name}</div>
                                                <div style={{ display: 'flex'}}><IoMdClose className="iconBtnSelectedFilterItemPartnersPage"/></div>
                                            </button>
                                            )
                                        ))}
                                    </div>
                                </div>
                            )}
                            {/* End fourth div componet with filters */}

                            {/* Start fifth div componet with info blocks list */}
                            <div className={ isTablet ? 'mainOptionsSecondColumnViewContainerPartnersPage' : 'mainOptionsSecondColumnViewContainerPartnersPageMobile' }>
                                {partnersListMock.partnersList.map((item, index) => (
                                    <button className='mainOptionsMainColViewContainerPartnersPage' onClick={() => handleNavBoxClick(item)} key={index}>
                                        <div className='txtInfoNavBoxRowOneViewContainerPartnersPage'>
                                            <div className='txtInfoNavBoxRowLeftViewContainerPartnersPage'>
                                                <img src={item.parterCategoryIcon} alt="imagem" className="iconInfoNavBoxRegBlackPartnersPage" />
                                                <div className='txtInfoNavBoxRegBlackPartnersPage'>{item.parterCategoryTitle}</div>
                                            </div>
                                            { item?.new &&
                                            <div className='txtInfoNavBoxBoldSmallTitleViewContainerPartnersPage'>
                                                <div className='txtInfoNavBoxBoldSmallTitlePartnersPage'>NOVIDADE</div>
                                            </div>
                                            }
                                        </div>
                                        <div className='txtInfoNavBoxRowLeftViewContainerPartnersPage'>
                                            <div className='txtInfoNavBoxBoldTitlePartnersPage'>{item.title}</div>
                                        </div>
                                        <div className='txtInfoNavBoxRowViewContainerPartnersPage'>
                                            <img src={item.imgUrl} alt="imagem" className="imgInfoNavBoxPartnersPage" />
                                        </div>
                                        <div className='spacerInfoNavBoxPartnersPage'/>
                                        <div className='txtInfoNavBoxRowLeftViewContainerPartnersPage'>
                                            <div className='txtInfoNavBoxBlueViewPartnersPage'>
                                                <span className='txtInfoNavBoxBoldBluePartnersPage'>{item.cashback}% cashback </span>
                                                <span className='txtInfoNavBoxRegBluePartnersPage'>{item.description}</span>
                                            </div>
                                        </div>
                                        <div className='txtInfoNavBoxRowViewContainerPartnersPage'>
                                            <div className='txtInfoNavBoxRowLeftViewContainerPartnersPage'>
                                                <div className='iconInfoNavBoxViewPartnersPage'><RiMapPinLine className='iconInfoNavBoxRegBlackPartnersPage'/></div>
                                                <div className='txtInfoNavBoxRegBlackPartnersPage'>{item.distance} Km</div>
                                            </div>
                                            <div className='iconInfoNavBoxViewPartnersPage'><FaAngleRight className='iconBtnInfoNavBoxPartnersPage'/></div>
                                        </div>
                                    </button>
                                ))}
                                
                            </div>   
                            {/* End fifth div componet with info blocks list */}
                        </div>                    
                    </div>
                </div>   
                <div className='mainPaginationViewContainerPartnersPage'>
                    <nav>
                        <List className='customPaginationListPartnersPage'>
                            {items.map(({ page, type, selected, ...item }, index) => {
                            let children = null;

                            if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                                children = '…';
                            } else if (type === 'page') {
                                children = (
                                <button
                                    type="button"
                                    className={ selected ? 'customPaginationPartnersPageSelected' : 'customPaginationPartnersPage' }
                                    {...item}
                                >
                                    {page}
                                </button>
                                );
                            } else {
                                children = (
                                <button 
                                    type="button" 
                                    {...item}
                                    className='customPaginationNavIconViewPartnersPage'
                                >
                                    {type === 'next' ?  <FaAngleRight className='customPaginationNavIconPartnersPage'/> : <FaAngleLeft className='customPaginationNavIconPartnersPage'/>}
                                </button>
                                );
                            }

                            return <li key={index}>{children}</li>;
                            })}
                        </List>
                    </nav>
                </div>
            </div>
        </div>
        </>
    )
}

export default PartnersPage;