import React, { useState } from "react";
import '../css/customModalMovementsInfo.css'
import { useTabletMediaQuery } from '../utils';
import Modal from 'react-bootstrap/Modal';
import CustomButton from "./CustomButton";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { GoDotFill } from "react-icons/go";

const CustomModalMovementsInfo = (props) => {
    const {
        text = '',
        date = '',
        title = '',
        category = '',
        amountAcumulated = '',
        amountPaid = '',
        isPending = ''
    } = props;

    const isTablet = useTabletMediaQuery();
    
    const [showAlert, setShowAlert] = useState(false)

    const handleClickShowAlert = () => {
        setShowAlert(true)
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="modalMainContainerMovementsInfo "
        >
            <div className="contentViewMovementsInfo ">
                <div className="titleViewContainerMovementsInfo ">
                    <div className="titleViewMovementsInfo" >{title} de saldo</div>
                </div>
                <div className="textViewContainerMovementsInfo ">
                    <div className="titleSmallMovementsInfo ">{date}</div>
                </div>

                { isTablet ? (
                <div className='txtNoMovementViewContainerMovementsInfo'>
                    <span className='textMovementsInfo'>Fez uma compra na</span>
                    <span className='textBoldMovementsInfo'>{category}</span>
                    <span className='textMovementsInfo'>e</span>
                    <span className='textBoldMovementsInfo'>{title}</span>
                    <span className='textBoldMovementsInfo'>{amountAcumulated}€</span>
                    <span className='textMovementsInfo'>na sua Carteira Virtual.</span>
                </div>
                ) : (
                    <>
                    <div className="txtNoMovementColumnViewContainerMovementsInfoMobile">
                        <div className='txtNoMovementViewContainerMovementsInfoMobile'>
                            <span className='textMovementsInfo'>Fez uma compra na</span>
                            <span className='textBoldMovementsInfo'>{category}</span>
                            <span className='textMovementsInfo'>e</span>
                        </div>
                        <div className='txtNoMovementViewContainerMovementsInfoMobile'>
                            <span className='textBoldMovementsInfo'>{title}</span>
                            <span className='textBoldMovementsInfo'>{amountAcumulated}€</span>
                            <span className='textMovementsInfo'>na sua Carteira Virtual.</span>
                        </div>
                    </div>
                    </>
                )}

                <div className="infoViewContainerMovementsInfo">
                    <div className="txtInfoTitleViewContainerMovementsInfo ">
                        <div className={ isTablet ? "titleViewMovementsInfo" : "titleViewMovementsInfoMobile"} >{title} de saldo - </div>
                        <span className={ isTablet ? 'titleRegularMovementsInfo' : 'titleRegularMovementsInfoMobile' }> {category}</span>
                    </div>
                    <div className="txtNoMovementRowViewContainerMovementsInfo">
                        <div className="txtNoMovementColunmViewContainerMovementsInfo">
                            <div className="textMovementsInfo" onClick={handleClickShowAlert} >Valor pago <IoMdInformationCircleOutline className="iconInfoModalMovementsInfo"/></div>
                            <div className="textMovementsInfo">Valor acumulado</div>
                            <Modal 
                                show={showAlert} 
                                size="sm"
                                aria-labelledby="contained-modal-title-vcenter"
                                centered
                                backdrop
                                onHide={() => setShowAlert(false)}  
                            >
                                <div className='mainViewAlerSmModalSeeMovementsInfo'>
                                    <div className='txtTitleAlerSmModalSeeMovementsInfo'>Valor pago</div>
                                    <div className='txtAlerSmModalSeeMovementsInfo'>Valor total da compra que fez no parceiro.</div>
                                </div>
                            </Modal>
                        </div>
                        <div className="txtNoMovementColunmViewContainerMovementsInfo ">
                            <div className='textBoldMovementsInfo'> {amountPaid}€</div>
                            <div className='textBoldMovementsInfo'> {amountAcumulated}€</div>
                        </div>
                    </div>
                </div>
                { isPending === 'P' && (
                <div className="infoViewContainerMovementsInfo">
                    <div className="txtNoMovementRowViewContainerMovementsInfo">
                        <div className="textMovementsInfo">Estado</div>
                        <div className='mainModalInfoStatusDataBoxViewMovementsInfo'>
                            <div className='iconModalInfoStatusDataBoxMovementsInfo'><GoDotFill /></div>
                            <div className='textMovementsInfo'>Acumulação pendente</div>
                            <div className='iconInfoViewModalInfoStatusDataBoxMovementsInfo'>
                                <IoMdInformationCircleOutline className='iconInfoModalInfoStatusDataBoxMovementsInfo' />
                            </div>
                        </div>
                    </div>
                </div>
                )}

            </div>
            <div className="btnViewMovementsInfo ">
                <CustomButton 
                    text={'Fechar'} 
                    color={'#00214D'}
                    textColor={'#fff'}
                    onPress={props.onHide}
                />
            </div>
        </Modal>
    );
};

export default CustomModalMovementsInfo;