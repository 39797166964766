import React, { useState, useEffect } from 'react';
import '../../css/earnBalance.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeftLong } from "react-icons/fa6";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import CustomAlert from '../../components/CustomAlert';
import CustomButton from '../../components/CustomButton';
import SeePartners from '../../components/EarnBalanceSwithSelector/SeePartners';
import ActivateOffers from '../../components/EarnBalanceSwithSelector/ActivateOffers';
import InviteFriends from '../../components/EarnBalanceSwithSelector/InviteFriends';

function EarnBalance() {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const [selectedOption, setSelectedOption] = useState(
        localStorage.getItem('selectedOption') || 'option1'
    );

    useEffect(() => {
        localStorage.setItem('selectedOption', selectedOption);
    }, [selectedOption]);

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const handlegoBackClick = () => {
        navigate(-1);
    };

    const handleClickShowConfigModal = () => {
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       setOpenInfoModal(true)
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
    };

  return (  
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? "mainViewContainerEarnBalance" : ( isMobile ? 'mainViewContainerEarnBalanceTablet' : 'mainViewContainerEarnBalanceMobile') }>
        <div className={ isTablet ? 'firstTxtViewContainerEarnBalance' : ( isMobile ? 'firstTxtViewContainerEarnBalanceTablet' : 'firstTxtViewContainerEarnBalanceMobile') }>
        
            <div className={ isTablet ? 'titleSaldoContainerEarnBalanceWithSaldo' : ( isMobile ? 'titleSaldoContainerEarnBalanceWithSaldoTablet' : 'titleSaldoContainerEarnBalanceWithSaldoMobile')}>
                <div className={ isTablet ? 'titleContainerEarnBalanceWithSaldo' : ( isMobile ? 'titleContainerEarnBalanceWithSaldoTablet' : 'titleContainerEarnBalanceWithSaldoMobile')}>
                    <div className={ isTablet ? 'firstTitleViewEarnBalanceWithSaldo' : ( isMobile ? 'firstTitleViewEarnBalanceWithSaldoTablet' : 'firstTitleViewEarnBalanceWithSaldoMobile')}>
                        { isTablet ?(
                            <div className='textTitleBoldEarnBalanceWithSaldo'>Carteira Virtual Médis</div> ) : (
                            <>
                            <div className='textTitleRegularEarnBalanceWithSaldoMobile'>Carteira</div>
                            <div className='textTitleBoldEarnBalanceWithSaldoMobile'>Virtual Médis</div>
                            </>
                        )}      
                    </div>
                    <div className='firstTitleViewConfigOnfoEarnBalanceWithSaldo'>
                        <button className='infoHeaderIonBtnEarnBalanceWithSaldo'  type='button' onClick={handleClickShowConfigModal} >
                            <span className='txtConfigTitleEarnBalanceWithSaldo'><IoSettingsOutline  /></span> <span className='txtConfigTitleEarnBalanceWithSaldo'>Configurações</span>
                        </button>
                        <CustomAlert 
                            title='Configurações'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openConfigModal} 
                            onHide={() => setOpenConfigModal(false)} 
                        /> 
                        <button className='infoHeaderIonBtnEarnBalanceWithSaldo'  type='button' onClick={handleClickShowInfo} >
                            <span className='infoIconEarnBalanceWithSaldo'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitleEarnBalanceWithSaldo'>Como funciona</span>
                        </button>
                        <CustomAlert 
                            title='Como Funciona'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openInfoModal} 
                            onHide={() => setOpenInfoModal(false)} 
                        />     
                    </div>
                </div>
                <div className={ isTablet ? 'saldoContainerEarnBalance' : ( isMobile ? 'saldoContainerEarnBalanceWithSaldoTablet' : 'saldoContainerEarnBalanceWithSaldoMobile')}>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                        <>
                        <div>
                            <span className={ isTablet ? 'txtRegSaldoEarnBalanceWithSaldo' : ( isMobile ? 'txtRegSaldoEarnBalanceWithSaldoTablet' : 'txtRegSaldoEarnBalanceWithSaldoMobile')}>O meu </span>
                            <span className={ isTablet ? 'txtBoldSaldoEarnBalanceWithSaldo' : ( isMobile ? 'txtBoldSaldoEarnBalanceWithSaldoTablet' : 'txtBoldSaldoEarnBalanceWithSaldoMobile')}>Saldo</span>
                        </div>
                        <div className='saldoInfoContainerEarnBalanceWithSaldo'>
                            <div className="saldoEuroIconViewEarnBalanceWithSaldo">
                                <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconEarnBalanceWithSaldo" />
                            </div>
                            <div className='txtRegularSaldoEarnBalanceWithSaldo'>22,3€</div>
                        </div>
                        </>
                    )}
                </div>
            </div>

            <div className={ isTablet ? 'secondMainContainerEarnBalance' : 'secondMainContainerEarnBalanceMobile' }>
                <div className={ isTablet ? 'firstSubMainContainerEarnBalance' : 'firstSubMainContainerEarnBalanceMobile' }>
                    <div className='secondTitleViewEarnBalance'>
                        <span className={ isTablet ? 'textTitleRegular400EarnBalance' : ( isMobile ? 'textTitleRegular400EarnBalanceTablet' : 'textTitleRegular400EarnBalanceMobile') }>Ganhar <span className={ isTablet ? 'textTitleBold700EarnBalance' : ( isMobile ? 'textTitleBold700EarnBalanceTablet' : 'textTitleRegular400EarnBalanceMobile') }>saldo</span></span>
                    </div>
                    <div className='txtSecondTitleViewEarnBalance'>
                            <div className='textSecondTitleViewEarnBalance'>Acumule dinheiro através de compras em parceiros, de códigos promocionais ou de recomendação a amigos.</div>
                    </div>
                </div>

            </div>

            <div className={ isTablet ? 'thirdMainContainerEarnBalance' : 'thirdMainContainerEarnBalanceMobile' }>
                <div className={ isTablet ? 'goBackBtnViewMbWayEarnBalance' : 'goBackBtnViewMbWayEarnBalanceMobile' }>
                    <button className='txtGoBackBtnEarnBalance'  type='button' onClick={handlegoBackClick} >
                        <FaArrowLeftLong className='goBackIconEarnBalance' /> Voltar
                    </button>
                </div>
            </div>
            
            <div className={ isTablet ? 'secondMainContainerEarnBalance' : 'secondMainContainerEarnBalanceMobile' }>
                <div className={ isTablet ? 'swithSelectorContainerEarnBalance' : 'swithSelectorContainerEarnBalanceMobile'}>
                    <CustomButton 
                        text={'Ver parceiros'} 
                        textColor={'#141414'}
                        onPress={() => handleOptionClick("option1")} 
                        hasSubLine={true}
                        borderColor={selectedOption === 'option1' && '#00214D'}
                        fontSize={selectedOption === 'option1' && '18px'}
                    />
                    <CustomButton 
                        text={'Ativar ofertas'} 
                        textColor={'#141414'}
                        onPress={() => handleOptionClick("option2")} 
                        hasSubLine={true}
                        borderColor={selectedOption === 'option2' && '#00214D'}
                        fontSize={selectedOption === 'option2' && '18px'}
                    />
                    <CustomButton 
                        text={'Convidar amigos'} 
                        textColor={'#141414'}
                        onPress={() => handleOptionClick("option3")} 
                        hasSubLine={true}
                        borderColor={selectedOption === 'option3' && '#00214D'}
                        fontSize={selectedOption === 'option3' && '18px'}
                    />
                </div>
                <div className='swithSelectorDeviderLineEarnBalance'></div>
                <div className={ isTablet ? 'firstSubMainContainerEarnBalance' : 'firstSubMainContainerEarnBalanceMobile' }>
                    {selectedOption === "option1" && (
                        <SeePartners/>
                    )}
                    {selectedOption === "option2" && (
                        <ActivateOffers/>
                    )}
                    {selectedOption === "option3" && (
                        <InviteFriends/>
                    )}
                </div>
            </div>   
        </div>
    </div>
    </>
  )
}

export default EarnBalance;