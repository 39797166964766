import React from "react";
import moment from "moment";
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton';
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/system';
import { FaRegCalendar } from "react-icons/fa6";

const DatePresets = (props) => {
    const { startDate, endDate, showDateFormat, handlePresets } = props;
    const today = moment();
    const presets = [
        {
            text: "Ir para hoje",
            start: today,
            end: today,
        },
        {
            text: "Últimos 7 dias",
            start: moment().subtract(1, "week"),
            end: today,
        },
        {
            text: "Últimos 14 dias",
            start: moment().subtract(2, "week"),
            end: today,
        },
        {
            text: "Últimos 30 dias",
            start: moment().subtract(1, "month"),
            end: today,
        },
    ];

    const blue = {
        50: '#F0F7FF',
        100: '#C2E0FF',
        200: '#99CCF3',
        300: '#66B2FF',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E6',
        700: '#0059B3',
        800: '#004C99',
        900: '#003A75',
    };
      
    const grey = {
        50: '#F3F6F9',
        100: '#E5EAF2',
        200: '#DAE2ED',
        300: '#C7D0DD',
        400: '#B0B8C4',
        500: '#9DA8B7',
        600: '#6B7A90',
        700: '#434D5B',
        800: '#303740',
        900: '#1C2025',
    };
      
    const Listbox = styled('ul')(
        ({ theme }) => `
        font-family: 'Ubuntu', sans-serif;
        font-size: 14px;
        box-sizing: border-box;
        padding: 6px;
        margin: 12px 0;
        min-width: 200px;
        border-radius: 12px;
        overflow: auto;
        outline: 0px;
        background: #fff;
        border: 1px solid #00214D;
        color: #00214D;
        box-shadow: 0px 8px 24px rgba(154, 161, 186, 0.20);
        z-index: 1;
        `,
    );
      
    const MenuItem = styled(BaseMenuItem)(
        ({ theme }) => `
        list-style: none;
        padding: 8px;
        border-radius: 8px;
        cursor: default;
        user-select: none;
      
        &:last-of-type {
          border-bottom: none;
        }
      
        &.${menuItemClasses.focusVisible} {
          outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
          background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
          color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        }
      
        &.${menuItemClasses.disabled} {
          color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
        }
      
        &:hover:not(.${menuItemClasses.disabled}) {
          background-color: #00214D10;
          color: #003C8D;
        }
        `,
    );

      
    const MenuButton = styled(BaseMenuButton)(
        ({ theme }) => `
        font-family: 'Ubuntu', sans-serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        padding: 2px;
        color: #00214D;
        transition: all 150ms ease;
        cursor: pointer;
        background: transparent;
        border: none;
        border-bottom: 2px solid transparent;
        border-color: #00214D;
      
        &:hover {
            color: #00214D;
            border-color: #00214D;
        }
      
        &:active {
          background: #00214D10;
        }
      
        &:focus-visible {
          outline: none;
        }
        `,
    );

    const styles = {
        arrowIconCustomButton: {
            verticalAlign: 'middle',
            marginLeft: '8px',
            width: '12px',
            height: '12px',
            color: '#00214D',
            alignSelf: 'center',
        },
      };

    return (
        <Dropdown>
            <MenuButton>Selecionar filtro por dia<FaRegCalendar style={styles.arrowIconCustomButton}/></MenuButton>
            <Menu slots={{ listbox: Listbox }}>
            {presets.map(({ text, start, end }) => {
                const isChosen =
                moment(start).format(showDateFormat) ===
                    moment(startDate).format(showDateFormat) &&
                moment(end).format(showDateFormat) ===
                    moment(endDate).format(showDateFormat);
                return (
                <MenuItem key={text} onClick={() => handlePresets(start, end)}>{text}</MenuItem>
                );
            })}  
            </Menu>
        </Dropdown>
    );
};
export default DatePresets;