import React, { useState, useEffect } from 'react';
import '../../css/onboardingFirstSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import CustomButton from '../../components/CustomButton';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { acceptUserTerms } from '../../features/onboardingData/onboardingUserData';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import Form from 'react-bootstrap/Form';

function OnboardingStepFour({ mainImgSvg }) {

    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const isAcceptUserTerms = useSelector((state) => state.onboardingUserData.isAcceptUserTerms);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isChecked1, setIsChecked1] = useState(true);
    const [isChecked2, setIsChecked2] = useState(false);
    const [isChecked3, setIsChecked3] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const handleStartUsing = () => {
        if (isChecked3) {
            dispatch(acceptUserTerms());
            navigate('/onboardingReferalStepOne');
        }
    };

  return ( 
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? 'mainViewContainerStepsStack' : ( isMobile ? 'mainViewContainerStepsStackTablet' : 'mainViewContainerStepsStackMobile')}>
        { !isTablet && 
            <>
            <div className={ isMobile ? 'mainImgViewStepsStackTablet' : 'mainImgViewStepsStackMobile' }>
                <img src={mainImgSvg} alt="imagem" className={ isMobile ?  "mainImgOneStepsStackTablet" : "mainImgStepsStackMobile" }/>
            </div>
            </>
        }
        <div className={ isTablet ? 'firstTxtViewContainerStepsStack' : ( isMobile ? 'firstTxtViewContainerStepsStackTablet' : 'firstTxtViewContainerStepsStackMobile')}>
            <div className='firstTitleViewStepsStack'>
                    <span className={ isTablet ? 'textTitlePart2StepsStack' : ( isMobile ? 'textTitlePart1StepsStackTablet' : 'textTitlePart1StepsStackMobile')}>Sem mais passos</span>
                    <span className={ isTablet ? 'textTitlePart1StepsStack' : ( isMobile ? 'textTitlePart1StepsStackTablet' : 'textTitlePart1StepsStackMobile')}> nem burocracias</span>
                    <span className={ isTablet ? 'textTitlePart3StepsStack' : ( isMobile ? 'textTitlePart1StepsStackTablet' : 'textTitlePart1StepsStackMobile')}>.</span>
            </div>
            <div className={ isTablet ? 'secondTitleViewStepsStack' : ( isMobile ? 'secondTitleViewStepsStackTablet' : 'secondTitleViewStepsStackMobile') }>É só começar a usar!</div>
            <div className={ isTablet ? 'secondSmallTitleViewStepsStack' : ( isMobile ? 'secondSmallTitleViewStepsStackTablet' : 'secondSmallTitleViewStepsStackMobile')}>Vá acompanhando no seu histórico quanto acumulou por cada compra e como gastou o saldo.</div>
            <div className='checkboxBtnGoViewStepsStack'>
                <Form className='checkboxFormViewStepsStack'>
                    <div className='firstOptionsFormChekedViewStepsStack'>
                        <Form.Check 
                            type="checkbox" 
                            label={'Permito que a Médis use os meus dados pessoais para fins de gestão da minha Carteira Virtual Médis.'}
                            checked={isChecked1}
                            onChange={() =>  setIsChecked1(isChecked1)}
                            disabled
                        />
                        <Form.Check 
                            type="checkbox" 
                            label={'label'}
                            checked={isChecked2}
                            onChange={() =>  setIsChecked2(!isChecked2)}
                        />
                        <Form.Check 
                            type="checkbox" 
                            label={
                                <>
                                <span className='txtFormStepsStack'>Li e aceito os</span>
                                <Link to="/" className="linkStepsStack">
                                    <span className='txtFormForTermsOfConditionsStepsStack' > Termos e Condições </span>
                                </Link>
                                <span className='txtFormStepsStack'> da Carteira Virtual Médis.</span>
                                </>
                            }
                            checked={isChecked3}
                            onChange={() => setIsChecked3(!isChecked3)}
                        />
                    </div>
                </Form>
                <div className={ isTablet ? 'btnsViewStepsStack' : ( isMobile ? 'btnsViewStepsStackTablet' : 'btnsViewStepsStackMobile' )}>
                    <div className={ isTablet ? 'divBtnNextStepsStack' : ( isMobile ? 'divBtnSaltarStepsStackTablet' : 'divBtnNextStepsStackMobile' )}>
                        <CustomButton 
                            text={'Começar a usar'} 
                            textColor={'#00214D'}
                            onPress={handleStartUsing} 
                            color={'#15D4B6'}
                            enabled={isChecked3}
                            hasArrow
                            iconColor={'#00214D'}
                        />
                    </div>    
                </div>
                {
                    !isTablet && (
                    <>
                    <div className='cirlceSelectedViewStepsStackMobile'>
                        <div className='cirlceSelectedStepsStackMobile'>
                            <div className='circleMainvViewStepsStack'>
                                <div className='circleContainerStepsStack'>
                                    <div className='circleViewStepsStack' />
                                </div>
                                <div className='circleContainerStepsStack'>
                                    <div className='circleViewStepsStack' />
                                </div>
                                <div className='circleContainerStepsStack'>
                                    <div className='circleViewStepsStack' />
                                </div>
                                <div className='circleContainerStepsStack'>
                                    <div className='circleSelectedViewStepsStack' />
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                )}
            </div>
                    
        </div>
        { isTablet && 
        <>
        <div className='firstImgViewContainerStepsStack'>
            <div className='mainImgViewStepsStack'>
                <img src={mainImgSvg} alt="imagem" className="mainImgStepsStack" />
            </div>
            <div className='cirlceSelectedStepsStack'>
                <div className='circleMainvViewStepsStack'>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleSelectedViewStepsStack' />
                    </div>
                </div>
            </div>    
        </div>
        </>
        }
    </div>
    </>
  )
}

export default OnboardingStepFour;