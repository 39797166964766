import React from "react";
import '../css/dotsLoading.css';

const DotsLoading = (props) => {
  return (
      <div className="bouncing-loader">
        <div></div>
        <div></div>
        <div></div>
      </div>
  );
};

export default DotsLoading;