import React, { useState, useEffect } from 'react';
import '../../css/onboardingFirstSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import CustomButton from '../../components/CustomButton';

function OnboardingStepThree({ onNext, onSkip, mainImgSvg }) {

    const [isLoading, setIsLoading] = useState(true);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

  return (  
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? 'mainViewContainerStepsStack' : ( isMobile ? 'mainViewContainerStepsStackTablet' : 'mainViewContainerStepsStackMobile')}>
        { !isTablet && 
            <>
            <div className={ isMobile ? 'mainImgViewStepsStackTablet' : 'mainImgViewStepsStackMobile' }>
                <img src={mainImgSvg} alt="imagem" className={ isMobile ?  "mainImgOneStepsStackTablet" : "mainImgThreeStepsStackMobile" }/>
            </div>
            </>
        }
        <div className={ isTablet ? 'firstTxtViewContainerStepsStack' : ( isMobile ? 'firstTxtViewContainerStepsStackTablet' : 'firstTxtViewContainerStepsStackMobile')}>
            <div className='firstTitleViewStepsStack'>
                <span className={ isTablet ? 'textTitlePart1StepsStack' : ( isMobile ? 'textTitlePart1StepsStackTablet' : 'textTitlePart1StepsStackMobile')}>Receba o saldo na </span>
                <span className={ isTablet ? 'textTitlePart1StepsStack' : ( isMobile ? 'textTitlePart1StepsStackTablet' : 'textTitlePart1StepsStackMobile')}>sua </span>
                <span className={ isTablet ? 'textTitlePart2StepsStack' : ( isMobile ? 'textTitlePart1StepsStackTablet' : 'textTitlePart1StepsStackMobile')}>conta bancária.</span>
            </div>
            <div className={ isTablet ? 'secondTitleViewStepsStack' : ( isMobile ? 'secondTitleViewStepsStackTablet' : 'secondTitleViewStepsStackMobile') }>Ou use-o em em serviços de saúde.</div>
            <div className={ isTablet ? 'secondSmallTitleViewStepsStack' : ( isMobile ? 'secondSmallTitleViewStepsStackTablet' : 'secondSmallTitleViewStepsStackMobile')}>Pode receber o valor por transferência ou aproveitá-lo para comprar consultas ou outros serviços de saúde da Médis.</div>
            <div className={ isTablet ? 'btnsViewStepsStack' : ( isMobile ? 'btnsViewStepsStackTablet' : 'btnsViewStepsStackMobile' )}>
                <div className={ isTablet ? 'divBtnSaltarStepsStack' : ( isMobile ? 'divBtnSaltarStepsStackTablet' : 'divBtnSaltarStepsStackMobile' )}>
                    <CustomButton 
                        text={'Saltar'} 
                        textColor={'#00214D'}
                        onPress={() => onSkip()} 
                        borderColor={'#00214D'}
                        hasSubLine
                        fontSize={'14px'}
                    />
                </div> 
                <div className={ isTablet ? 'divBtnNextStepsStack' : ( isMobile ? 'divBtnSaltarStepsStackTablet' : 'divBtnNextStepsStackMobile' )}>
                    <CustomButton 
                        text={'Próximo'} 
                        textColor={'#00214D'}
                        onPress={() => onNext()} 
                        color={ isTablet ? '#fff' : ( isMobile ? '#fff' : 'transparent' )}
                        borderColor={'#00214D'}
                        hasArrow
                        iconColor={'#00214D'}
                    />
                </div>    
            </div>
            {
                !isTablet && (
                <>
                <div className='cirlceSelectedViewStepsStackMobile'>
                    <div className='cirlceSelectedStepsStackMobile'>
                        <div className='circleMainvViewStepsStack'>
                            <div className='circleContainerStepsStack'>
                                <div className='circleViewStepsStack' />
                            </div>
                            <div className='circleContainerStepsStack'>
                                <div className='circleViewStepsStack' />
                            </div>
                            <div className='circleContainerStepsStack'>
                                <div className='circleSelectedViewStepsStack' />
                            </div>
                            <div className='circleContainerStepsStack'>
                                <div className='circleViewStepsStack' />
                            </div>
                        </div>
                    </div>
                </div>
                </>
            )}     
        </div>
        { isTablet && 
        <>
        <div className='firstImgViewContainerStepsStack'>
            <div className='mainImgViewStepsStack'>
                <img src={mainImgSvg} alt="imagem" className="mainImgStepsStack" />
            </div>
            <div className='cirlceSelectedStepsStack'>
                <div className='circleMainvViewStepsStack'>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleSelectedViewStepsStack' />
                    </div>
                    <div className='circleContainerStepsStack'>
                        <div className='circleViewStepsStack' />
                    </div>
                </div>
            </div>   
        </div>
        </>
        }
    </div>
    </>
  )
}

export default OnboardingStepThree;