import React, { useState, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from '../pages/HomeScreens/Home';
import EarnBalance from '../pages/HomeScreens/EarnBalance';
import LinkCardStepOne from '../pages/LinkCardLoged/LinkCardStepOne';
import LinkCardStepMB from '../pages/LinkCardLoged/LinkCardStepMB';
import LinkCardStepMbWay from '../pages/LinkCardLoged/LinkCardStepMbWay';
import SeeMovements from '../pages/HomeScreens/SeeMovements';
import PartnersDetails from '../pages/HomeScreens/PartnersDetails';
import OnboardingReferalStepOne from '../pages/OnboardingReferals/OnboardingReferalStepOne';
import OnboardingReferalStepTwo from '../pages/OnboardingReferals/OnboardingReferalStepTwo';
import OnboardingLinkCardStepOne from '../pages/OnboardingLinkingCard/OnboardingLinkCardStepOne';
import OnboardingLinkCardStepMbWay from '../pages/OnboardingLinkingCard/OnboardingLinkCardStepMbWay';
import OnboardingLinkCardStepMB from '../pages/OnboardingLinkingCard/OnboardingLinkCardStepMB';
import PartnersPage from '../pages/HomeScreens/PartnersPage';
import LinkCardStepMbWaySuccessful from '../pages/LinkCardLoged/LinkCardStepMbWaySuccessful';
import OnboardingSteps from '../pages/OnboardingSteps/OnboardingSteps';
import NoMatch from '../pages/NoMatchPage/NoMatch';

import { useDispatch, useSelector } from 'react-redux';
import { acceptUserTerms } from '../features/onboardingData/onboardingUserData';

import { useMsal } from '@azure/msal-react';
import { callMsGraph } from '../utils/MsGraphApiCall';
import { InteractionRequiredAuthError, InteractionStatus } from "@azure/msal-browser";
import { msalInstance } from '..';

const WorkSpaceStack = () => {
    const isAcceptUserTerms = useSelector((state) => state.onboardingUserData.isAcceptUserTerms);
    let location = useLocation();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});  
    }, [location]);

    const { instance, inProgress, accounts } = useMsal();
    const [apiData, setApiData] = useState(null);
    const [tokenExpiresOn, setTokenExpiresOn] = useState(null);
    const [responseAccessToken, setResponseAccessToken] = useState(null);
    const [responseRefreshAccessToken, setResponseRefreshAccessToken] = useState(null);
  
    useEffect(() => {
      if (!apiData && inProgress === InteractionStatus.None) {
        const accessTokenRequest = {
          scopes: ["user.read"],
          account: accounts[0],
        };
        instance
          .acquireTokenSilent(accessTokenRequest)
          .then((accessTokenResponse) => {
            // Acquire token silent success
            let accessToken = accessTokenResponse.accessToken;
            console.log('instance apiData accessTokenResponse: ', accessTokenResponse)
            console.log('instance apiData accessToken: ', accessToken);
            setResponseAccessToken(accessToken);
            setTokenExpiresOn(accessTokenResponse.expiresOn);
            // Call your API with token
            callMsGraph(accessToken).then((response) => {
              setApiData(response);
            });
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance
                .acquireTokenRedirect(accessTokenRequest)
                .then(function (accessTokenResponse) {
                  // Acquire token interactive success
                  let accessToken = accessTokenResponse.accessToken;
                  console.log('catch apiData accessTokenResponse: ', accessTokenResponse)
                  console.log('catch apiData accessToken: ', accessToken);
                  setResponseAccessToken(accessToken);
                  setTokenExpiresOn(accessTokenResponse.expiresOn);
                  // Call your API with token
                  callMsGraph(accessToken).then((response) => {
                    setApiData(response);
                  });
                })
                .catch(function (error) {
                  // Acquire token interactive failure
                  console.log(error);
                });
            }
            console.log(error);
          });
      }
    }, [instance, accounts, inProgress, apiData]);
  
    const refreshToken = async () => {
      try {
        const account = msalInstance.getActiveAccount();
        if (!account) {
            throw new Error('No active account found');
        }
  
        const refreshTokenRequest = {
            scopes: ['openid', 'profile', 'offline_access'], 
            account: account,
            forceRefresh: true,
            refreshTokenExpirationOffsetSeconds: 7200
        };
  
        const tokenResponse = await msalInstance.acquireTokenSilent(refreshTokenRequest);
        console.log('New access token - tokenResponse:', tokenResponse);
        console.log('New access token:', tokenResponse.accessToken);
        setResponseRefreshAccessToken(tokenResponse.accessToken);
        setTokenExpiresOn(tokenResponse.expiresOn);
        return responseRefreshAccessToken;
  
      } catch (error) {
          console.error('Error refreshing token:', error);
          throw error;
      }
    };
  
    const activeAccount = msalInstance.getActiveAccount();
    useEffect(() => {
      const calculateRefreshTime = () => {
          const expirationTime = new Date(tokenExpiresOn);
          const refreshTime = expirationTime.getTime() - Date.now() - 10 * 60 * 1000;
          console.log('expirationTime: ', expirationTime);
          console.log('refreshTime: ', refreshTime);
          if (refreshTime > 0) {
              const timeout = setTimeout(() => {
                  if (activeAccount) {
                      refreshToken();
                  } else {
                      console.error('No active account found');
                  }
              }, refreshTime);
    
              return () => clearTimeout(timeout);
          }
      };
    
      return calculateRefreshTime();
    }, [tokenExpiresOn, activeAccount]);
    
    
    console.log('apiData: ', apiData);
    console.log('tokenExpiresOn: ', tokenExpiresOn);
    console.log("responseAccessToken: ", responseAccessToken);
    console.log("responseRefreshAccessToken: ", responseRefreshAccessToken);
  
    // const { result, error } = useMsalAuthentication(InteractionType.Popup, {
    //   scopes: ["user.read"],
    // });
  
    // useEffect(() => {
    //     if (!displayData) {
    //       return;
    //     }
    //     if (error) {
    //       console.log(error);
    //       return;
    //     }
    //     if (result) {
    //       let accessToken = result.accessToken;
    //       console.log('Access Token:', accessToken);
    //       let idToken = result.idToken;
    //       console.log('ID Token:', idToken);
    //       callMsGraph(accessToken).then((response) => {
    //         setDisplayData(response);
    //       }).catch((error) => console.log('error callMsGraph: ', error));
    //     }
    // }, [displayData, error, result]);
    
    // console.log('result: ', result);
    // console.log('accessToken: ', result?.accessToken);
    // console.log('accessTokenId: ', result?.idToken);
    // console.log('displayData: ', displayData);
  
    
    // useEffect(() => {
    //   const timeout = setTimeout(() => {
    //       if (activeAccount) {
    //           refreshToken();
    //       } else {
    //           console.error('No active account found');
    //       }
    //   }, 30000); 
  
    //   return () => clearTimeout(timeout);
    // }, []);

    return (
        <Routes>
            {isAcceptUserTerms ? (
                <>
                <Route path="/" element={<Home />} />
                <Route path="/earnBalance" element={<EarnBalance />} />
                <Route path="/seeMovements" element={<SeeMovements />} />
                <Route path="/partnersPage" element={<PartnersPage />} />
                <Route path="/linkCardStepOne" element={<LinkCardStepOne />} />
                <Route path="/linkCardStepMbWay" element={<LinkCardStepMbWay />} />
                <Route path="/linkCardStepMB" element={<LinkCardStepMB />} />
                <Route path="/partnersDetails" element={<PartnersDetails />} />
                <Route path="/linkCardStepMbWaySuccessful" element={<LinkCardStepMbWaySuccessful />} />
                <Route path="/onboardingReferalStepOne" element={<OnboardingReferalStepOne />} />
                <Route path="/onboardingReferalStepTwo" element={<OnboardingReferalStepTwo />} />
                <Route path="/onboardingLinkCardStepOne" element={<OnboardingLinkCardStepOne />} />
                <Route path="/onboardingLinkCardStepMbWay" element={<OnboardingLinkCardStepMbWay />} />
                <Route path="/onboardingLinkCardStepMB" element={<OnboardingLinkCardStepMB />} />
            </>
            ) : (
                <Route path="/" element={<OnboardingSteps />}/>

            )}
            <Route path="*" element={<NoMatch />} />
        </Routes>
    );
};

export default WorkSpaceStack;
