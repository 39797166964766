import React, { useState, useEffect, useRef } from 'react';
import '../../css/inviteFriends.css';
import { FaShareNodes } from "react-icons/fa6";
import { FaRegCopy } from "react-icons/fa6";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import moment from 'moment';
import 'moment/locale/pt';
import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import { RWebShare } from "react-web-share";
import imgDataRespData from '../../mock/imgDataResp.json'
import ImgDecatlonSvg from '../../assets/svg/iconLogoDecatlhon.svg';
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import adsOfferData from '../../mock/adsOffer.json'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function InviteFriends() {

    const [isLoading, setIsLoading] = useState(true);
    const [copyOfferCode, setCopyOfferCode] = useState('IDCODEUNICO');
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const copyTextRef = useRef();
    const [date, setDate] = useState('');
    const currentDate = moment().format('DD/MM/YYYY');
    const [swiperIndex, setSwiperIndex] = useState(0);
    const [swiperInstance, setSwiperInstance] = useState(null);
    const imgInviteFriendsSvg = imgDataRespData.imgData.onboardingReferalStepOneSvg;

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        setDate(currentDate);
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const hadleBtnClickCopy = () => {
        const isCopy = copy(copyOfferCode);
        if (isCopy) {
            toast.success("Código copiado com sucesso!");
        } else {
            toast.error("Falha ao copiar o código, tenta mais tarde");
        }
    };
    
    const handleSwiperSlideChange = (swiper) => {
        setSwiperIndex(swiper.activeIndex);
    };
    
    const nextImage = () => {
        if (swiperInstance !== null) {
        swiperInstance.slideNext();
        }
    };

    const prevImage = () => {
        if (swiperInstance !== null) {
        swiperInstance.slidePrev();
        }
    };

    return (
        <>
        { !isTablet && (
            <>
            <div className={ isMobile ? 'mainImgViewInviteFriendsTablet' : 'mainImgViewInviteFriendsMobile'}>
                <img src={imgInviteFriendsSvg} alt="imagem" className={ isMobile ? "mainImgInviteFriendsTablet" : "mainImgInviteFriendsMobile"} />
            </div>
            </>
        )}
        <div className={ isTablet ? 'secondMainContainerInviteFriends' : ( isMobile ? 'secondMainContainerInviteFriendsTablet' : 'secondMainContainerInviteFriendsMobile') }>
            <div className={ isTablet ? 'firstSubMainContainerInviteFriends'  : ( isMobile ? 'firstSubMainContainerInviteFriendsTablet' : 'firstSubMainContainerInviteFriendsMobile') }>
                <div className='secondTitleViewInviteFriends'>
                    <div className={ isTablet ? 'textTitleRegular400InviteFriends' : 'textTitleRegular400InviteFriendsMobile' }>Ganha quem convida e quem ativa a Carteira Virtual</div>
                </div>
                <div className='txtSecondTitleViewInviteFriends'>
                    <div className='textSecondTitleViewInviteFriends'>Partilhe este código e ganhe XX€ em saldo. Quem ativar a Carteira Virtual com</div>
                    <div className='textSecondTitleViewInviteFriends'>este código também recebe XX€ de saldo como oferta.</div>
                </div>
                <div className={ isTablet ? 'textFormViewInviteFriends' : 'textFormViewInviteFriendsMobile' }>
                    <div className='textWrapperInviteFriends'>
                        <div className={ isTablet ? 'secondTextWrapperInviteFriends' : 'secondTextWrapperInviteFriendsMobile' }>
                            <input 
                                ref={copyTextRef}
                                className={isTablet ? 'inputTextInviteFriends' : 'inputTextInviteFriendsMobile'}
                                type="text" 
                                placeholder='IDCODEUNICO'
                                value={copyOfferCode}
                                onChange={(event) => setCopyOfferCode(event.target.value)}
                                maxLength={30}   
                                disabled
                            />
                            <button 
                                onClick={hadleBtnClickCopy}
                                className='inputTextCopyIconInviteFriends'
                            ><FaRegCopy className='copyIconInviteFriends'/>
                            </button>
                        </div>
                        <div>
                            <span className='textSecondTitleViewInviteFriends'>O código é específico da sua Carteira Virtual e é válido até </span>
                            <span className='textSecondTitleBoldViewInviteFriends'>{date}.</span>
                        </div>
                    </div>
                </div>
                <div className={ isTablet ? 'btnViewInviteFriends' : 'btnViewInviteFriendsMobile' }>
                    <RWebShare
                        data={{
                            text: copyOfferCode,
                            url: copyOfferCode,
                            title: "Partilhar código",
                        }}
                        onClick={() =>
                            toast.success("Código partilhado com sucesso!")
                        }
                    >
                        <button className='btnNextInviteFriends'>Partilhar código <FaShareNodes className='checkedIconInviteFriends'/></button>
                    </RWebShare>
                </div> 
            </div>
            { isTablet && (
                <>
                <div className='mainFirstAlingViewInviteFriends'>
                    <div className='mainImgViewInviteFriends'>
                        <img src={imgInviteFriendsSvg} alt="imagem" className="mainImgInviteFriends" />
                    </div>
                </div>
                </>
            )}
        </div>

        {/* Start slider */}
        <div className={isTablet ? 'mainSecondViewContainerInviteFriendsSlider' : ( isMobile ? 'mainSecondViewContainerInviteFriendsSliderTablet' : 'mainSecondViewContainerInviteFriendsSliderMobile')}>
            <div className='firstSubMainContainerInviteFriendsSlider'>
                <div className='secondTitleViewInviteFriendsSlider'>
                    <span className={isTablet ? 'textTitleRegular400InviteFriendsSlider' : 'textTitleRegular400InviteFriendsSliderMobile'}> <b className={isTablet ? 'textTitleBold700InviteFriendsSlider' : 'textTitleBold700InviteFriendsSliderMobile'}>Bebé e Gravidez:</b> nova categoria</span>
                </div>
                <div className='txtSecondTitleViewInviteFriendsSlider'>
                    <span className={isTablet ? 'textSecondTitleViewInviteFriendsSlider' : 'textSecondTitleViewInviteFriendsSliderMobile'}>Conheça as novidades do catálogo de parceiros Carteira Virtual Médis.</span>
                </div>
            </div>

            <Swiper
                onSlideChange={(swiper) => handleSwiperSlideChange(swiper)}
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                slidesPerView={1}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                // pagination={{
                // clickable: true,
                // }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className={isTablet ? "mySwiperInviteFriendsSlider" : "mySwiperInviteFriendsSliderMobile"}
            >
                {adsOfferData.offerAds.map((ad, index) => (
                    <SwiperSlide key={index}>
                        {isTablet ? (
                            <div className='secondSubMainContainerInviteFriendsSlider'>
                                <div className='imgAdsMainViewInviteFriendsSlider' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    {/* <img src={ad.imageUrl} alt="imagem" className='imgAdsInviteFriendsSlider' /> */}
                                    <div className='adsProductStatusViewInviteFriendsSlider'>
                                        <div className='txtAdsProductStatusInviteFriendsSlider'>{ad.productStatus}</div>
                                    </div>
                                </div>
                                <div className='txtAdsMainViewInviteFriendsSlider'>
                                    <div className='txtAdsMainTitleViewInviteFriendsSlider'>
                                        <div className='txtAdsDateInviteFriendsSlider'>{ad.dateOfOffer}</div>
                                    </div>
                                    <div className='txtAdsMainTitleViewInviteFriendsSlider'>
                                        <div className='txtAdsBoldInviteFriendsSlider'>{ad.title}</div>
                                    </div>
                                    <div className='txtAdsMainSubTitleViewInviteFriendsSlider'>
                                        <div className='txtAdsRegularInviteFriendsSlider'>{ad.description}</div>
                                    </div>
                                    <div className='imgAdsCompanyLogoMainViewInviteFriendsSlider'>
                                        <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoInviteFriendsSlider' />
                                        <div className='txtAdsCompanyLogoViewInviteFriendsSlider'>
                                            <div className='txtAdsCompanyTitleInviteFriendsSlider'>{ad.storeName}</div>
                                            <div className='txtAdsProductCategoryTitleInviteFriendsSlider'>{ad.productCategory}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : ( isMobile ? (
                            <div className='secondSubMainContainerInviteFriendsSliderTablet'>
                                <div className='imgMainViewInviteFriendsSliderTablet'>
                                    <div className='imgAdsMainViewInviteFriendsSliderTablet' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                        {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsInviteFriendsSlider' /> */}
                                        <div className='adsProductStatusViewInviteFriendsSliderTablet'>
                                            <div className='txtAdsProductStatusInviteFriendsSlider'>{ad.productStatus}</div>
                                        </div>
                                    </div>

                                    <div className='txtAdsMainViewInviteFriendsSliderTablet'>
                                        <div className='txtAdsMainTitleViewInviteFriendsSlider'>
                                            <div className='txtAdsDateInviteFriendsSliderTablet'>{ad.dateOfOffer}</div>
                                        </div>
                                        <div className='txtAdsMainTitleViewInviteFriendsSlider'>
                                            <div className='txtAdsBoldInviteFriendsSliderTablet'>{ad.title}</div>
                                        </div>
                                        <div className='txtAdsMainSubTitleViewInviteFriendsSlider'>
                                            <div className='txtAdsRegularInviteFriendsSliderTablet'>{ad.description}</div>
                                        </div>
                                        <div className='imgAdsCompanyLogoMainViewInviteFriendsSliderTablet'>
                                            <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoInviteFriendsSlider' />
                                            <div className='txtAdsCompanyLogoViewInviteFriendsSlider'>
                                                <div className='txtAdsCompanyTitleInviteFriendsSlider'>{ad.storeName}</div>
                                                <div className='txtAdsProductCategoryTitleInviteFriendsSlider'>{ad.productCategory}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='secondSubMainContainerInviteFriendsSliderMobile'>
                                <div className='imgAdsMainViewInviteFriendsSliderMobile' style={{ backgroundImage: `url(${ad.imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    {/* <img src={ad.imageUrl} alt="imagem"  className='imgAdsInviteFriendsSlider' /> */}
                                    <div className='adsProductStatusViewInviteFriendsSliderMobile'>
                                        <div className='txtAdsProductStatusInviteFriendsSlider'>{ad.productStatus}</div>
                                    </div>

                                    <div className='txtAdsMainViewInviteFriendsSliderMobile'>
                                        <div className='txtAdsMainTitleViewInviteFriendsSlider'>
                                            <div className='txtAdsDateInviteFriendsSliderMobile'>{ad.dateOfOffer}</div>
                                        </div>
                                        <div className='txtAdsMainTitleViewInviteFriendsSlider'>
                                            <div className='txtAdsBoldInviteFriendsSliderMobile'>{ad.title}</div>
                                        </div>
                                        <div className='txtAdsMainSubTitleViewInviteFriendsSlider'>
                                            <div className='txtAdsRegularInviteFriendsSliderMobile'>{ad.description}</div>
                                        </div>
                                        <div className='imgAdsCompanyLogoMainViewInviteFriendsSliderMobile'>
                                            <img src={ImgDecatlonSvg} alt="imagem" className='imgAdsCompanyLogoInviteFriendsSlider' />
                                            <div className='txtAdsCompanyLogoViewInviteFriendsSlider'>
                                                <div className='txtAdsCompanyTitleInviteFriendsSlider'>{ad.storeName}</div>
                                                <div className='txtAdsProductCategoryTitleInviteFriendsSlider'>{ad.productCategory}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className={isTablet ? 'thirdSubMainContainerCircleNavInviteFriendsSlider' : 'thirdSubMainContainerInviteFriendsSliderMobile'}>
                    <button className='iconArrowLeftViewContainerCircleNavInviteFriendsSlider' onClick={prevImage} disabled={swiperIndex == 0}>
                        <FaArrowLeftLong className='iconArrowCircleNavInviteFriendsSlider' />
                    </button>

                    <div className='cirlceSelectedNavInviteFriendsSlider'>
                        <div className='circleMainvViewNavInviteFriendsSlider'>
                            <div className='circleContainerNavInviteFriendsSlider'>
                                <div className={swiperIndex == 0 ? 'circleSelectedViewNavInviteFriendsSlider' : 'circleViewNavInviteFriendsSlider'} />
                            </div>
                            <div className='circleContainerNavInviteFriendsSlider'>
                                <div className={swiperIndex == 1 ? 'circleSelectedViewNavInviteFriendsSlider' : 'circleViewNavInviteFriendsSlider'} />
                            </div>
                            <div className='circleContainerNavInviteFriendsSlider'>
                                <div className={swiperIndex >= 2 ? 'circleSelectedViewNavInviteFriendsSlider' : 'circleViewNavInviteFriendsSlider'} />
                            </div>
                        </div>
                    </div>

                    <button className='iconArrowRightViewContainerCircleNavInviteFriendsSlider' onClick={nextImage} disabled={swiperIndex >= adsOfferData.offerAds.length - 1}>
                        <FaArrowRightLong className='iconArrowCircleNavInviteFriendsSlider' />
                    </button>
            </div>

        </div>
        {/* End slider */}
        </>       
    )
}

export default InviteFriends