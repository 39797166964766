import React from 'react';
import OnboardingStepsStack from './OnboardingStepsStack'
import WorkSpaceStack from './WorkSpaceStack';
import { useIsAuthenticated } from '@azure/msal-react';

const RoutesStack = () => {

  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      { !isAuthenticated ? (
        <WorkSpaceStack />
      ) : (
        <OnboardingStepsStack />
      )}
    </>
  );
};

export default RoutesStack;
