import React, { useState, useRef, useEffect } from 'react';
import googleMapsRespData from '../mock/googleMapsResp.json';
import '../css/partnersDetails.css';
import {APIProvider, Map, AdvancedMarker, Pin, InfoWindow, MapControl, ControlPosition } from '@vis.gl/react-google-maps';
import { useTabletMediaQuery } from '../utils';
import CustomButton from './CustomButton';
import { IoSearch } from "react-icons/io5";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import PlaceholderLoading from 'react-placeholder-loading';
import { RiMapPinLine } from "react-icons/ri";
import Badge from '@mui/material/Badge';
import CustomAlert from './CustomAlert';

function GoogleMap() {

  const [isLoading, setIsLoading] = useState(true);
  const isTablet = useTabletMediaQuery();
  const initialPosition = googleMapsRespData.googleMapsData.length > 0
  ? { lat: parseFloat(googleMapsRespData.googleMapsData[0].lat), lng: parseFloat(googleMapsRespData.googleMapsData[0].lng) }
  : { lat: 0, lng: 0 }; 
  const [ selectedMarker, setSelectedMarker] = useState(null);
  const markersRefs = useRef(googleMapsRespData.googleMapsData.map(() => React.createRef()));
  const [seacrhPartner, setSeacrhPartner] = useState('');
  const [myLocation, setMyLocation] = useState('');
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [showPartnersList, setShowPartnersList] = useState(false);
  const [toggleSected, setToggleSelected] = useState(false);


  const handleSelectedMarker = (index) => {
      setSelectedMarker(index);
  };

  useEffect(() => {
    const delay = setTimeout(() => {
    setIsLoading(false); 
    }, 1000); 

    return () => clearTimeout(delay); 
  }, []);

  const handleSelectToggle = () => {
    setToggleSelected((prevState) => !prevState);
  };

  const hadleBtnClickSearchPartners = () => {
    setSeacrhPartner('')
    alert('btn search')
  };

  const handleClickShowInfo = () => {
    setOpenInfoModal(true)
  };

  const handleClickShowPartnersList = () => {
    setShowPartnersList(!showPartnersList)
  };
  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setMyLocation({ latitude, longitude });
        },
        error => {
          console.error('Erro ao obter localização:', error);
        }
      );
    } else {
      console.error('A geolocalização não é suportada pelo seu navegador!');
    }
  }, []);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Радиус Земли в километрах
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = R * c; // Расстояние в километрах
    return distance;
  }
  

  let closestPoint = null;
  let minDistance = Number.MAX_VALUE;

  googleMapsRespData.googleMapsData.forEach((item, index) => {
    const distance = calculateDistance(myLocation.latitude, myLocation.longitude, parseFloat(item.lat), parseFloat(item.lng));

    if (distance < minDistance) {
      minDistance = distance;
      closestPoint = item;
    }
  });

  const sortedGoogleMapsData = [...googleMapsRespData.googleMapsData].sort((a, b) => {
    const distanceA = calculateDistance(myLocation.latitude, myLocation.longitude, parseFloat(a.lat), parseFloat(a.lng));
    const distanceB = calculateDistance(myLocation.latitude, myLocation.longitude, parseFloat(b.lat), parseFloat(b.lng));
    return distanceA - distanceB;
  });

  const [filteredData, setFilteredData] = useState(sortedGoogleMapsData);

  const handleSearch = (searchText) => {
    const filteredList = sortedGoogleMapsData.filter((item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filteredList);
  };

  const renderContentInfo = ()  => {
    return (
      <div className={ isTablet ? ( showPartnersList ? 'mainGoogleMapGridWithOutInfoViewContainerPartnersDetails' : 'mainGoogleMapGridViewContainerPartnersDetails' ) : 'mainGoogleMapGridViewContainerPartnersDetailsMobile'}>
        { isLoading ? <PlaceholderLoading shape="rect" width={150} height={20}/> : (
        <>
          <Badge badgeContent={googleMapsRespData.googleMapsData.length} style={{color: '#fff'}}>
            <div className='txtTitleListItemsGoogleMapViewContainerPartnersDetails'>Lojas aderentes</div>
          </Badge>
        </>
        )}
        <div className='columnViewGoogleMapViewContainerPartnersDetails'>
          { isLoading ? <PlaceholderLoading shape="rect" width={250} height={25}/> : (
              <>
              <div className='textWrapperPartnersDetails'>
                  <input 
                      className='inputTextPartnersDetails'
                      type="text" 
                      placeholder='Pesquise uma loja aderente'
                      value={seacrhPartner}
                      onChange={(event) => {
                        setSeacrhPartner(event.target.value);
                        handleSearch(event.target.value);
                      }}
                      maxLength={50}
                  />
                  <div className='findIconViewPartnersDetails' onClick={handleClickShowPartnersList}><IoIosArrowDown className='findIconPartnersDetails' /></div>
                  <div className='findIconViewPartnersDetails' onClick={handleClickShowInfo}><AiOutlineInfoCircle className='findIconPartnersDetails' /></div>
                  <CustomAlert 
                    title='Como Funciona'
                    text='Contacta nos para saber mais informação'
                    type='info'
                    show={openInfoModal} 
                    onHide={() => setOpenInfoModal(false)} 
                />  
              </div> 
              </>
          )}
        </div>
        { !showPartnersList &&
        <>
        { filteredData.map((item, index) =>(
          <div key={index} className='columnGridViewGoogleMapViewContainerPartnersDetails'>
            { isLoading ? <PlaceholderLoading shape="rect" width={300} height={125}/> : (
              <>
              <div className='rowGridViewGoogleMapViewContainerPartnersDetails'>
                <div className='txtTitleStoreNameGoogleMapViewContainerPartnersDetails'>{item.title} <br/> {item.titleSpace}</div>
                <img src={item.shopeIcon} alt="imagem" className="iconPartnerLogoSvgPartnersDetails" />
              </div>
              <div className='columnGridSubLineViewGoogleMapViewContainerPartnersDetails'>
                  <div className='txtTitleStoreNameGoogleMapViewContainerPartnersDetails'>Endereço</div>
                  <div className='txtStoreNameGoogleMapViewContainerPartnersDetails'><RiMapPinLine /> {item.address}</div>
                  <div className='txtStoreNameGoogleMapViewContainerPartnersDetails'>{item.city}</div>
              </div>
              <div className='columnGridSubViewGoogleMapViewContainerPartnersDetails'>
                <CustomButton 
                    text={'Ver no mapa'} 
                    textColor={'#00214D'}
                    onPress={() => handleSelectedMarker(index)} 
                    hasArrow
                    iconType={'right'}
                    iconColor={'#00214D'}
                    hasSubLine={true}
                />
              </div>
              </>
            )}
          </div>
        ))}
        </>
        }
      </div>
    );
  };

  const renderContentInfoMobile = () => {
    return (
      <div className='secondSliderAdsContainerPartnersDetailsMobile'>
        <div className='selectViewTypeMainContainerPartnersDetailsMobile'>
          <button className={ !toggleSected ? 'btnSelectedViewTypeContainerPartnersDetailsMobile' : 'btnNoSelectedViewTypeContainerPartnersDetailsMobile'} onClick={handleSelectToggle}>Mapa</button>
          <button className={ toggleSected ? 'btnSelectedViewTypeContainerPartnersDetailsMobile' : 'btnNoSelectedViewTypeContainerPartnersDetailsMobile'} onClick={handleSelectToggle}>Lista</button>
        </div>
      </div>
    )
  }

  const renderContentInfoToggleSelectedMobile = () => {
    return (
      <div className='mainGoogleMapScrollViewContainerPartnersDetailsMobile'>
        
        { filteredData.map((item, index) =>(
          <div key={index} className='columnGridViewGoogleMapViewContainerPartnersDetailsMobile'>
            { isLoading ? <PlaceholderLoading shape="rect" width={300} height={125}/> : (
              <>
              <div className='rowGridViewGoogleMapViewContainerPartnersDetails'>
                <div className='txtTitleStoreNameGoogleMapViewContainerPartnersDetails'>{item.title} <br/> {item.titleSpace}</div>
                <img src={item.shopeIcon} alt="imagem" className="iconPartnerLogoSvgPartnersDetails" />
              </div>
              <div className='columnGridSubLineViewGoogleMapViewContainerPartnersDetails'>
                  <div className='txtTitleStoreNameGoogleMapViewContainerPartnersDetails'>Endereço</div>
                  <div className='txtStoreNameGoogleMapViewContainerPartnersDetails'><RiMapPinLine /> {item.address}</div>
                  <div className='txtStoreNameGoogleMapViewContainerPartnersDetails'>{item.city}</div>
              </div>
              <div className='columnGridSubViewGoogleMapViewContainerPartnersDetails'>
                <CustomButton 
                    text={'Ver no mapa'} 
                    textColor={'#00214D'}
                    onPress={() => handleSelectedMarker(index)} 
                    hasArrow
                    iconType={'right'}
                    iconColor={'#00214D'}
                    hasSubLine={true}
                />
              </div>
              </>
            )}
          </div>
        ))}
      </div>
    )
  }
    
  return (
    <>
      { !isTablet &&
        <div className= 'mainGoogleMapGridViewContainerPartnersDetailsMobile'>
          
          <div className='columnViewGoogleMapViewContainerPartnersDetailsMobile'>
            { isLoading ? <PlaceholderLoading shape="rect" width={250} height={25}/> : (
                <>
                <div className='textWrapperPartnersDetails'>
                    <input 
                        className='inputTextPartnersDetails'
                        type="text" 
                        placeholder='Pesquise uma loja parceira'
                        value={seacrhPartner}
                        onChange={(event) => {
                          setSeacrhPartner(event.target.value);
                          handleSearch(event.target.value);
                        }}
                        maxLength={50}
                    />
                    <div className='findIconViewPartnersDetails' onClick={hadleBtnClickSearchPartners}><IoSearch className='findIconPartnersDetails' /></div>
                </div>
                </>
            )}
          </div>
          { toggleSected &&
            renderContentInfoToggleSelectedMobile()     
          }
          { toggleSected &&
            <div className='selectViewTypeMainContainerPartnersDetailsMobile' style={{marginTop: '16px'}}>
              <button className={ !toggleSected ? 'btnSelectedViewTypeContainerPartnersDetailsMobile' : 'btnNoSelectedViewTypeContainerPartnersDetailsMobile'} onClick={handleSelectToggle}>Mapa</button>
              <button className={ toggleSected ? 'btnSelectedViewTypeContainerPartnersDetailsMobile' : 'btnNoSelectedViewTypeContainerPartnersDetailsMobile'} onClick={handleSelectToggle}>Lista</button>
            </div>
          }
      </div>
      }
      { !toggleSected &&
      <>
        <div style={{ height: '100%', width: '100%'}}>
          { isLoading ? <PlaceholderLoading shape="rect" width={350} height={350}/> : (
            <>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY} libraries={['places']}>
                
                <Map 
                  center={closestPoint ? { lat: parseFloat(closestPoint.lat), lng: parseFloat(closestPoint.lng) } : initialPosition}
                  zoom={15}
                  mapId={process.env.REACT_APP_GOOGLE_MAP_ID}>
                    
                  { sortedGoogleMapsData.map((item, index) =>(
                    <React.Fragment  key={index}>
                      <AdvancedMarker ref={markersRefs.current[index]}  position={{  lat: parseFloat(item.lat), lng: parseFloat(item.lng) }} onClick={ () => handleSelectedMarker(index) } >
                          <Pin
                              background={"#00214D"}
                              borderColor={"#00214D"}
                              glyphColor={"#0A806C"}
                          />
                      </AdvancedMarker>
                      { selectedMarker === index  && (
                        <InfoWindow
                          anchor={markersRefs.current[index].current}
                          maxWidth={300}
                          onCloseClick={() => setSelectedMarker(null)}>
                              <div className='columnViewGoogleMapViewContainerPartnersDetails'>
                                  <div className='rowViewGoogleMapViewContainerPartnersDetails'>
                                      <div className='columnViewGoogleMapViewContainerPartnersDetails'>
                                          <div className='txtTitleStoreNameGoogleMapViewContainerPartnersDetails'>{item.title}</div>
                                          <div className='txtTitleStoreNameGoogleMapViewContainerPartnersDetails'>{item.titleSpace}</div>
                                      </div>
                                      <img src={item.shopeIcon} alt="imagem" className="iconPartnerLogoSvgPartnersDetails" />
                                  </div>
                                  <div className='columnViewGoogleMapViewContainerPartnersDetails'>
                                        <div className='txtStoreNameGoogleMapViewContainerPartnersDetails'>{item.address}</div>
                                        <div className='txtStoreNameGoogleMapViewContainerPartnersDetails'>{item.city}</div>
                                    </div>
                              </div>
                        </InfoWindow>
                      )}
                    </React.Fragment>
                  ))}
                  { isTablet ? (
                  <MapControl position={ControlPosition.LEFT}>
                  { 
                    renderContentInfo()
                  }
                </MapControl>
                  ) : (
                  <MapControl position={ControlPosition.BOTTOM_CENTER}>
                    { 
                      renderContentInfoMobile()
                    }
                  </MapControl>
                  )}
                </Map>
                
            </APIProvider>
            </>
          )}
        </div>
      </>
      }
    </>
  )
}

export default GoogleMap
