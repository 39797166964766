// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bouncing-loader {
    display: flex;
    justify-content: center;
  }
  
  .bouncing-loader > div {
    width: 7px;
    height: 7px;
    margin: 1px 2px;
    border-radius: 50%;
    background-color: #00214D;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }

  @keyframes bouncing-loader {
    to {
      opacity: 0.1;
      transform: translateY(-7px);
    }
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.4s;
  }`, "",{"version":3,"sources":["webpack://./src/css/dotsLoading.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,UAAU;IACV,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,UAAU;IACV,kDAAkD;EACpD;;EAEA;IACE;MACE,YAAY;MACZ,2BAA2B;IAC7B;EACF;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".bouncing-loader {\n    display: flex;\n    justify-content: center;\n  }\n  \n  .bouncing-loader > div {\n    width: 7px;\n    height: 7px;\n    margin: 1px 2px;\n    border-radius: 50%;\n    background-color: #00214D;\n    opacity: 1;\n    animation: bouncing-loader 0.6s infinite alternate;\n  }\n\n  @keyframes bouncing-loader {\n    to {\n      opacity: 0.1;\n      transform: translateY(-7px);\n    }\n  }\n  \n  .bouncing-loader > div:nth-child(2) {\n    animation-delay: 0.2s;\n  }\n  \n  .bouncing-loader > div:nth-child(3) {\n    animation-delay: 0.4s;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
