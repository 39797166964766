import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../pages/securityPage/Login';

const OnboardingStepsStack = () => {

  return (
    <Routes>
        <Route exact path="/" element={<Login />} />
    </Routes>
  );
};

export default OnboardingStepsStack;
