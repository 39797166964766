import React, { useState, useEffect } from 'react';
import '../../css/onboardingLinkCardSteps.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { FaArrowLeftLong } from "react-icons/fa6";
import { isValidPhoneNumber, useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLinkCard } from '../../features/onboardingData/onboardingUserData';
import CustomAlert from '../../components/CustomAlert';
import imgDataRespData from '../../mock/imgDataResp.json'
import { getDatabase, ref, get, set } from "firebase/database";
import { AiOutlineStop } from "react-icons/ai";
import CustomButton from '../../components/CustomButton';

function LinkCardStepMbWay() {

    const [isLoading, setIsLoading] = useState(true);
    const [phone, setPhone] = useState('');
    const [isStartingLoadingDots, setIsStartingLoadingDots] = useState(false)
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const isLinkCard = useSelector((state) => state.onboardingUserData.isLinkCard);
    const dispatch = useDispatch();
    const imgAddMbWay = imgDataRespData.imgData.iconAddMbWay;
    const [isValidPhone, setIsValidPhone] = useState(true); 

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    const handleClickShowConfigModal = () => {
        //alert("Configurações");
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       //alert("Como funciona");
       setOpenInfoModal(true)
    };

    const handlegoBackClick = () => {
        navigate(-1);
    };

    const fetchData = async () => {
        try {
            setIsStartingLoadingDots(true);
            const database = getDatabase();
            //await set(ref(database, '/confirmOnboardingCode/success'), true);
            const dbRef = ref(database, '/confirmOnboardingCode');
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const data = await snapshot.val();
                if (data.success) {
                    setIsValidPhone(true);
                    hadleNav();
                    setIsStartingLoadingDots(false);
                } else {
                    setIsValidPhone(false);
                    setIsStartingLoadingDots(false);
                }
            } else {
                console.log('No data available');
                setIsStartingLoadingDots(false);
            }
        } catch (error) {
            console.error(error);
            setIsStartingLoadingDots(false);
        }
    };

    const hadleBtnClickCheck = async () => {
        dispatch(setLinkCard(true));
        await fetchData();
    };

    const hadleNav = () => {
        navigate("/linkCardStepMbWaySuccessful", { state: {phone} });
    };

  return (  
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? "mainViewContainerLinckCardSteps" : ( isMobile ? "mainViewContainerLinckCardStepsTablet" : "mainViewContainerLinckCardStepsMobile")}>
        <div className={ isTablet ? 'firstTxtViewContainerLinckCardSteps' : ( isMobile ? 'firstTxtViewContainerLinckCardStepsTablet' : 'firstTxtViewContainerLinckCardStepsMobile') }>
            
            <div className={ isTablet ? 'titleSaldoContainerLinckCardStepsWithSaldo' : ( isMobile ? 'titleSaldoContainerLinckCardStepsWithSaldo' : 'titleSaldoContainerLinckCardStepsWithSaldoMobile')}>
                <div className={ isTablet ? 'titleContainerLinckCardStepsWithSaldo' : ( isMobile ? 'titleContainerLinckCardStepsWithSaldo' : 'titleContainerLinckCardStepsWithSaldoMobile')}>
                    <div className={ isTablet ? 'firstTitleViewLinckCardStepsWithSaldo' : ( isMobile ? 'firstTitleViewLinckCardStepsWithSaldo' : 'firstTitleViewLinckCardStepsWithSaldoMobile')}>
                        { isTablet ? (
                            <div className='textTitleBoldLinckCardStepsWithSaldo'>Carteira Virtual Médis</div> ) : (
                            <>
                            <div className='textTitleRegularLinckCardStepsWithSaldoMobile'>Carteira</div>
                            <div className='textTitleBoldLinckCardStepsWithSaldoMobile'>Virtual Médis</div>
                        </>
                        )}      
                    </div>
                    <div className='firstTitleViewConfigOnfoLinckCardStepsWithSaldo'>
                        <button className='infoHeaderIonBtnLinckCardStepsWithSaldo'  type='button' onClick={handleClickShowConfigModal} >
                            <span className='txtConfigTitleLinckCardStepsWithSaldo'><IoSettingsOutline  /></span> <span className='txtConfigTitleLinckCardStepsWithSaldo'>Configurações</span>
                        </button>
                        <CustomAlert 
                            title='Configurações'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openConfigModal} 
                            onHide={() => setOpenConfigModal(false)} 
                        /> 
                        <button className='infoHeaderIonBtnLinckCardStepsWithSaldo'  type='button' onClick={handleClickShowInfo} >
                            <span className='infoIconLinckCardStepsWithSaldo'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitleLinckCardStepsWithSaldo'>Como funciona</span>
                        </button>
                        <CustomAlert 
                            title='Como Funciona'
                            text='Contacta nos para saber mais informação'
                            type='info'
                            show={openInfoModal} 
                            onHide={() => setOpenInfoModal(false)} 
                        />      
                    </div>
                </div>
                <div className={ isTablet ? 'saldoContainerLinckCardStepsWithSaldo' : ( isMobile ? 'saldoContainerLinckCardStepsWithSaldo' : 'saldoContainerLinckCardStepsWithSaldoMobile')}>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                        <>
                        <div>
                            <span className={ isTablet ? 'txtRegSaldoLinckCardStepsWithSaldo' : 'txtRegSaldoLinckCardStepsWithSaldoMobile'}>O meu </span>
                            <span className={ isTablet ? 'txtBoldSaldoLinckCardStepsWithSaldo' : 'txtBoldSaldoLinckCardStepsWithSaldoMobile'}>Saldo</span>
                        </div>
                        <div className='saldoInfoContainerLinckCardStepsWithSaldo'>
                            <div className="saldoEuroIconViewLinckCardStepsWithSaldo">
                                <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconLinckCardStepsWithSaldo" />
                            </div>
                            <div className='txtRegularSaldoLinckCardStepsWithSaldo'>22,3€</div>
                        </div>
                        </>
                    )}
                </div>
            </div>
            <div className={ isTablet ? 'goBackBtnViewLinckCardSteps' : 'goBackBtnViewLinckCardStepsMobile' }>
                <button className='txtGoBackBtnLinckCardSteps'  type='button' onClick={handlegoBackClick} >
                    <FaArrowLeftLong className='goBackIconLinckCardSteps' /> Voltar
                </button>
            </div>
            { !isTablet && (
                    <>
                    <div className='mainImgViewLinckCardStepsMobile'>
                        <img src={imgAddMbWay} alt="imagem" className={ isMobile ? "mainImgAddMbWayLinckCardStepsTablet" : "mainImgAddMbWayLinckCardStepsMobile"} />
                    </div>
                    </>
                )}
            <div className={ isTablet ? 'secondMainContainerLinckCardSteps' : 'secondMainContainerLinckCardStepsMobile' }>
                <div className={ isTablet ? 'firstSubMainContainerLinckCardSteps'  : 'firstSubMainContainerLinckCardStepsMobile' }>
                    <div className='secondTitleViewLinckCardSteps'>
                        <span className={ isTablet ? 'textTitleRegular400LinckCardSteps' : 'textTitleRegular400LinckCardStepsMobile' }>Associar cartão por MB WAY</span>      
                    </div>
                    <div className='txtSecondTitleViewLinckCardSteps'>
                        <div className='textSecondTitleViewLinckCardSteps'>Para associar o cartão à sua carteira virtual, tem de o ter registado na app MB WAY.</div>
                    </div>
                    <div className={ isTablet ? 'textFormViewMbWayLinckCardSteps' : 'textFormViewMbWayLinckCardStepsMobile' }>
                        <div className='textWrapperMbWayLinckCardSteps'>
                            { phone.length > 0 &&
                            <label className='label-focus'>Insira aqui o seu número de telemóvel</label>
                            }
                            <input 
                                className={ isValidPhone ? 'inputTextMbWayLinckCardSteps' : 'inputTextMbWayLinckCardStepsInvalid' }
                                type="text" 
                                placeholder='EX.: 9XX XXX XXX *'
                                value={phone}
                                onChange={(event) => {
                                    const input = event.target.value.replace(/\D/g, '');
                                    setPhone(input);
                                }}
                                maxLength={9}                                 
                            />
                            { !isValidPhone &&
                                <div className='txtViewErrorInfoMbWayLinckCardSteps'>
                                    <div className='txtErrorInfoMbWayLinckCardSteps'><AiOutlineStop className='iconErrorInfoMbWayLinckCardSteps'/> Lamentamos, mas neste momento, não conseguimos validar o seu telemóvel. Por favor, tente mais tarde.</div>
                                </div>
                            } 
                        </div>
                    </div>
                    <div className={ isTablet ? 'btnsViewMbWayLinckCardSteps' : 'btnsViewMbWayLinckCardStepsMobile' }>
                        <CustomButton 
                            text={'Validar'} 
                            textColor={'#00214D'}
                            onPress={hadleBtnClickCheck} 
                            color={'#15D4B6'}
                            enabled={isValidPhoneNumber(phone)}
                            hasArrow
                            iconColor={'#00214D'}
                            iconType={'check'}
                            loading={isStartingLoadingDots}
                        />
                    </div> 
                </div>
                { isTablet && (
                    <>
                    <div className='mainImgViewLinckCardSteps'>
                        <img src={imgAddMbWay} alt="imagem" className="mainImgAddMbWayLinckCardSteps" />
                    </div>
                    </>
                )}
            </div>          
        </div>
    </div>
    </>
  )
}

export default LinkCardStepMbWay;