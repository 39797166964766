import React, { useState } from 'react';
import '../../css/onboardingFirstSteps.css';
import OnboardingStepOne from './OnboardingStepOne';
import OnboardingStepTwo from './OnboardingStepTwo';
import OnboardingStepThree from './OnboardingStepThree';
import OnboardingStepFour from './OnboardingStepFour';
import imgDataRespData from '../../mock/imgDataResp.json'

function OnboardingSteps() {

  const [currentPage, setCurrentPage] = useState(1);
  const imgStepOne = imgDataRespData.imgData.onboardingStepOneSvg;
  const imgStepTwo = imgDataRespData.imgData.onboardingStepTwoSvg;
  const imgStepThree = imgDataRespData.imgData.onboardingStepThreeSvg;
  const imgStepFour = imgDataRespData.imgData.onboardingStepFourSvg;

  const handleOnClickSaltarNavigate = () => {
      const skipPages = 4 - currentPage;
      setCurrentPage((prevPage) => prevPage < 4 ? prevPage + skipPages : prevPage);
  };
  const handleOnClickNavigate = () => {
      setCurrentPage((prevPage) => prevPage < 4 ? prevPage + 1 : prevPage);
  };

  const renderCurrentStep = () => {
      switch (currentPage) {
        case 1:
          return <OnboardingStepOne onNext={handleOnClickNavigate} onSkip={handleOnClickSaltarNavigate} mainImgSvg={imgStepOne} />;
        case 2:
          return <OnboardingStepTwo onNext={handleOnClickNavigate} onSkip={handleOnClickSaltarNavigate} mainImgSvg={imgStepTwo} />;
        case 3:
          return <OnboardingStepThree onNext={handleOnClickNavigate} onSkip={handleOnClickSaltarNavigate} mainImgSvg={imgStepThree} />;
        case 4:
          return <OnboardingStepFour mainImgSvg={imgStepFour} />;
        default:
          return <OnboardingStepOne onNext={handleOnClickNavigate} onSkip={handleOnClickSaltarNavigate} mainImgSvg={imgStepOne} />;
      }
  };

    
  return (
      <>
      {renderCurrentStep()}
      </>
  )
}

export default OnboardingSteps;