import React, { useState, useEffect } from 'react';
import '../../css/linkCardStepMbWaySuccessful.css';
import PlaceholderLoading from 'react-placeholder-loading';
import Header from '../../components/Header';
import { useNavigate } from 'react-router-dom';
import { IoIosHelpCircleOutline } from "react-icons/io";
import { IoSettingsOutline } from "react-icons/io5";
import { useMobileMediaQuery, useTabletMediaQuery } from '../../utils';
import ImgIconEuroCoin from '../../assets/svg/iconEuroCoin.svg';
import imgDataRespData from '../../mock/imgDataResp.json'
import { getDatabase, ref, get, set } from "firebase/database";
import CustomAlert from '../../components/CustomAlert';
import ImgIconSuccess from '../../assets/svg/iconSuccess.svg';
import CustomButton from '../../components/CustomButton';
import GifIconLoading from '../../assets/gif/mainLoading.gif';
import ProgressBar from 'react-bootstrap/ProgressBar';

function LinkCardStepMbWaySuccessful() {

    const [isLoading, setIsLoading] = useState(true);
    const [isStartingLoadingAnim, setIsStartingLoadingAnim] = useState(true)
    const navigate = useNavigate();
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openConfigModal, setOpenConfigModal] = useState(false);
    const isMobile = useMobileMediaQuery();
    const isTablet = useTabletMediaQuery();
    const mainImgSuccess = imgDataRespData.imgData.onboardingReferalStepTwoSvg;
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const delay = setTimeout(() => {
        setIsLoading(false); 
        }, 1000); 

        return () => clearTimeout(delay); 
    }, []);

    useEffect( () => {
        const fetchDataWithDelay = () => {
            setTimeout(() => {
                fetchData();
            }, 5000);
        };
    
        fetchDataWithDelay();

        const intervalId = setInterval(() => {
            setProgress(preProgress => {
                const newProgress = preProgress + 1;
                if (newProgress >= 100) {
                    clearInterval(intervalId);
                }
                return newProgress;
            });
        }, 50);
        return () => clearInterval(intervalId);
    }, []);

    const handleClickShowConfigModal = () => {
        setOpenConfigModal(true)
    };

    const handleClickShowInfo = () => {
       setOpenInfoModal(true)
    };

    const hadleBtnClickGoHome = () => {
        navigate('/');
    };

    const hadleBtnClickSeePartners = () => {
        navigate('/partnersPage');
    };

    const hadleNavNoMatch = () => {
        navigate('/error');
    };


    const fetchData = async () => {
        try {
            setIsStartingLoadingAnim(true);
            const database = getDatabase();
            await set(ref(database, '/confirmOnboardingCode/success'), true);
            const dbRef = ref(database, '/confirmOnboardingCode');
            const snapshot = await get(dbRef);
            if (snapshot.exists()) {
                const data = await snapshot.val();
                if (data.success) {
                    setIsStartingLoadingAnim(false);
                } else {
                    hadleNavNoMatch();
                    setIsStartingLoadingAnim(false);
                }
            } else {
                hadleNavNoMatch();
                setIsStartingLoadingAnim(false);
            }
        } catch (error) {
            hadleNavNoMatch();
            console.error(error);
            setIsStartingLoadingAnim(false);
        }
    };

  return (  
    <>
    { isTablet &&  
    <Header/> 
     }
    <div className={ isTablet ? "mainViewContainerLinkCardStepMbWaySuccessful" : ( isMobile ? "mainViewContainerLinkCardStepMbWaySuccessfulTablet" : "mainViewContainerLinkCardStepMbWaySuccessfulMobile")}>
        <div className={ isTablet ? 'firstTxtViewContainerLinkCardStepMbWaySuccessful' : ( isMobile ? 'firstTxtViewContainerLinkCardStepMbWaySuccessfulTablet' : 'firstTxtViewContainerLinkCardStepMbWaySuccessfulMobile') }>
            <div className={ isTablet ? 'titleSaldoContainerLinkCardStepMbWaySuccessful' : ( isMobile ? 'titleSaldoContainerLinkCardStepMbWaySuccessfulTablet' : 'titleSaldoContainerLinkCardStepMbWaySuccessfulMobile')}>
                <div className={ isTablet ? 'titleContainerLinkCardStepMbWaySuccessful' : ( isMobile ? 'titleContainerLinkCardStepMbWaySuccessfulTablet' : 'titleContainerLinkCardStepMbWaySuccessfulMobile')}>
                    <div className={ isTablet ? 'firstTitleViewLinkCardStepMbWaySuccessful' : ( isMobile ? 'firstTitleViewLinkCardStepMbWaySuccessfulTablet' : 'firstTitleViewLinkCardStepMbWaySuccessfulMobile')}>
                        { isTablet ? (
                            <div className='textTitleBoldLinkCardStepMbWaySuccessful'>Carteira Virtual Médis</div> ) : (
                            <>
                            <div className='textTitleRegularLinkCardStepMbWaySuccessfulMobile'>Carteira</div>
                            <div className='textTitleBoldLinkCardStepMbWaySuccessfulMobile'>Virtual Médis</div>
                            </>
                        )}      
                    </div>
                    <div className='firstTitleViewConfigOnfoLinkCardStepMbWaySuccessful'>
                            <button className='infoHeaderIonBtnLinkCardStepMbWaySuccessful'  type='button' onClick={handleClickShowConfigModal} >
                                <span className='txtConfigTitleLinkCardStepMbWaySuccessful'><IoSettingsOutline  /></span> <span className='txtConfigTitleLinkCardStepMbWaySuccessful'>Configurações</span>
                            </button>
                            <CustomAlert 
                                title='Configurações'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openConfigModal} 
                                onHide={() => setOpenConfigModal(false)} 
                            /> 
                            <button className='infoHeaderIonBtnLinkCardStepMbWaySuccessful'  type='button' onClick={handleClickShowInfo} >
                                <span className='infoIconLinkCardStepMbWaySuccessful'><IoIosHelpCircleOutline  /></span> <span className='txtInfoTitleLinkCardStepMbWaySuccessful'>Como funciona</span>
                            </button>
                            <CustomAlert 
                                title='Como Funciona'
                                text='Contacta nos para saber mais informação'
                                type='info'
                                show={openInfoModal} 
                                onHide={() => setOpenInfoModal(false)} 
                            />
                    </div>
                </div>
                <div className={ isTablet ? 'saldoContainerLinkCardStepMbWaySuccessful' : ( isMobile ? 'saldoContainerLinkCardStepMbWaySuccessfulTablet' : 'saldoContainerLinkCardStepMbWaySuccessfulMobile')}>
                    { isLoading ? <PlaceholderLoading shape="rect" width={350} height={50}/> : (
                        <>
                        <div>
                            <span className={ isTablet ? 'txtRegSaldoLinkCardStepMbWaySuccessful' : 'txtRegSaldoLinkCardStepMbWaySuccessfulMobilr'}>O meu </span>
                            <span className={ isTablet ? 'txtBoldSaldoLinkCardStepMbWaySuccessful' : 'txtBoldSaldoLinkCardStepMbWaySuccessfulMobile'}>Saldo</span>
                        </div>
                        <div className='saldoInfoContainerLinkCardStepMbWaySuccessful'>
                            <div className="saldoEuroIconViewLinkCardStepMbWaySuccessful">
                                <img src={ImgIconEuroCoin} alt="€" className="saldoEuroIconLinkCardStepMbWaySuccessful" />
                            </div>
                            <div className='txtRegularSaldoLinkCardStepMbWaySuccessful'>22,3€</div>
                        </div>
                        </>
                    )}
                </div>
            </div>
            { !isStartingLoadingAnim ? (
                <>
                <div className={ isTablet ? 'mainContentViewContainerLinkCardStepMbWaySuccessful' : 'mainContentViewContainerLinkCardStepMbWaySuccessfulMobile' }>
                    <div className='titleViewContentViewContainerLinkCardStepMbWaySuccessful'>
                        { isTablet &&
                        <div className="imgViewContainerLinkCardStepMbWaySuccessful">
                            <img src={ImgIconSuccess} alt="icon" className="imgContainerLinkCardStepMbWaySuccessful" />
                        </div>
                        }
                        <div className={ isTablet ? 'txtTitleViewContainerLinkCardStepMbWaySuccessful' : 'txtTitleViewContainerLinkCardStepMbWaySuccessfulMobile' }>Cartão associado com sucesso!</div>
                        <div className={ isTablet ? 'txtRegularViewContainerLinkCardStepMbWaySuccessful' : 'txtRegularViewContainerLinkCardStepMbWaySuccessfulMobile' }>{`Agora já pode usufruir de coisas da carteira (...)`}</div>
                    </div>
                    <div className='mainImgViewContentViewContainerLinkCardStepMbWaySuccessful'>
                        <div className={ isTablet ? 'manImgViewContainerLinkCardStepMbWaySuccessful' : 'manImgViewContainerLinkCardStepMbWaySuccessfulMobile'}>
                            <img src={mainImgSuccess} alt="icon" className={ isTablet ? 'mainImgContainerLinkCardStepMbWaySuccessful' : 'mainImgContainerLinkCardStepMbWaySuccessfulMobile' } />
                        </div>
                    </div>
                    <div className={ isTablet ? 'rowBtnViewContentViewContainerLinkCardStepMbWaySuccessful' : 'columnBtnViewContentViewContainerLinkCardStepMbWaySuccessfulMobile'}>
                        <CustomButton 
                            text={'Página principal'} 
                            textColor={'#00214D'}
                            onPress={hadleBtnClickGoHome} 
                            borderColor={'#00214D'}
                        />
                        <CustomButton 
                        text={'Ver parceiros'} 
                        textColor={'#00214D'}
                        onPress={hadleBtnClickSeePartners} 
                        color={'#15D4B6'}
                        hasArrow
                        iconColor={'#00214D'}
                    />
                    </div>
                </div>
                </>
                ) : (
                <>
                <div className={ isTablet ? 'mainContentViewContainerLinkCardStepMbWaySuccessful' : 'mainContentViewContainerLinkCardStepMbWaySuccessfulMobile' }>
                    <div className='mainLoadingViewContainerLinkCardStepMbWaySuccessful'>
                        <div style={{ alignSelf: 'center', textAlign: 'center' }}>
                            <span className={ isTablet ? 'mainLoadingTxtTitleBoldLinkCardStepMbWaySuccessful' : 'txtTitleViewContainerLinkCardStepMbWaySuccessfulMobile' }>Aguarde </span>
                            <span className={ isTablet ? 'mainLoadingTxtTitleRegularLinkCardStepMbWaySuccessful' : 'txtTitleViewContainerLinkCardStepMbWaySuccessfulMobile' }>um momento</span>
                        </div>
                        <div className={ isTablet ? 'mainLoadingTxtRegularLinkCardStepMbWaySuccessful' : 'txtRegularViewContainerLinkCardStepMbWaySuccessfulMobile' }>{`Aguarde um momento, estamos a validar a sua cena (...)`}</div>
                    </div>
                    <div className='mainLoadingViewContainerLinkCardStepMbWaySuccessful'>
                        <ProgressBar animated now={progress} className="mainProgressBarViewLinkCardStepMbWaySuccessful"  />
                    </div>
                    <div className={ isTablet ? 'manImgViewContainerLinkCardStepMbWaySuccessful' : 'manImgViewContainerLinkCardStepMbWaySuccessfulMobile'}>
                        <img src={GifIconLoading} alt="gif" className={ isTablet ? 'mainGifContainerLinkCardStepMbWaySuccessful' : 'mainGifContainerLinkCardStepMbWaySuccessfulMobile' } />
                    </div>
                </div>
                </>
            )}
        </div>
    </div>
    </>
  )
}

export default LinkCardStepMbWaySuccessful;