import React, { useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { FaRegCalendar } from "react-icons/fa6";
import { CiFilter } from "react-icons/ci";
import { FaAngleRight } from "react-icons/fa6";
import { useTabletMediaQuery } from '../utils';
import '../css/customButton.css';
import DotsLoading from '../components/DotsLoading'


const CustomButton = (props) => {
    const {
        text = '',
        onPress = () => { },
        enabled = true,
        loading = false,
        hasArrow = false,
        color = 'transparent',
        fontSize= '13px',
        hoverColor = 'none',
        textColor = '#000',
        hoverTextColor = 'none',
        iconColor = '#000',
        borderColor = 'transparent',
        hoverBorderColor = 'none',
        hasSubLine = false,
        hasSubLineSmall = false,
        hasSubLineSmallColor = 'transparent',
        iconType = 'arrow'
    } = props;

    const isTablet = useTabletMediaQuery();
    const [isHovered, setIsHovered] =  useState(false);
    const [isFocused, setIsFocused] =  useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };
    
    const handleBlur = () => {
        setIsFocused(false);
    };

    const buttonClass = isTablet
        ? "btnCustomButton"
        : "btnCustomButtonMobile";
    const arrowIconClass = isTablet
        ? "arrowIconCustomButton"
        : "arrowIconCustomButtonMobile";

    const buttonSubLineClass = isTablet
        ? "btnSubLineMainContainerCustomButton"
        : "btnSubLineMainContainerCustomButtonMobile";

    const buttonStyle = {
        backgroundColor: isHovered ? hoverColor : color,
        color: isHovered ? hoverTextColor : textColor,
        borderColor:  isHovered ? hoverBorderColor : borderColor,
        fontSize: fontSize,
        transition: 'background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease, font-size 0.3s ease, border-width  0.3s ease',
    };

    const iconStyle = {
        color: iconColor,
    };

    return (
        <>
        <button
            className={ hasSubLine ? buttonSubLineClass : buttonClass}
            type='button'
            onClick={onPress}
            style={buttonStyle}
            disabled={!enabled || loading}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            { loading ? <DotsLoading /> : (
            <>
                {text}
                {hasArrow && iconType === 'arrow' && (
                    <FaArrowRightLong className={arrowIconClass} style={iconStyle} />
                )}

                {hasArrow && iconType === 'check' && (
                    <FaCheck className={arrowIconClass} style={iconStyle} />
                )}

                {hasArrow && iconType === 'calendar' && (
                    <FaRegCalendar className={arrowIconClass} style={iconStyle} />
                )}

                {hasArrow && iconType === 'filter' && (
                    <CiFilter className={arrowIconClass} style={iconStyle} />
                )}

                {hasArrow && iconType === 'right' && (
                    <FaAngleRight className={arrowIconClass} style={iconStyle} />
                )}
            </>
            )} 
        </button>
        { hasSubLineSmall && 
            <div className="hasSubLineSmallCustomButton" style={{ backgroundColor: hasSubLineSmallColor }}></div>
        }
        </>
    );
};

export default CustomButton;